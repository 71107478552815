import { primaryColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({

  title: {
    color: theme.palette.text.primary,
    textAlign: "left",
    fontSize: "1.2rem",
    paddingTop: 15,
  },

  styledCardActions: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  styledCardContentInner: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

});

export default styles;
