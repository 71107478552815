export const strategiesData = [
  {
    name: 'yearn',
    strategyIcon: 'yearn.png',
    tokenId: 'dai',
    asset1: 'DAI',
    // apy: "4.52%",
    asset2: 'waUSD',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/dai/yearn',
    minterTo: '/app/minters/dai/wausd/yearn',
  },
  {
    name: 'idle',
    strategyIcon: 'idle.png',
    tokenId: 'dai',
    asset1: 'DAI',
    // apy: "9.6%",
    asset2: 'waUSD',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/dai/idle',
    minterTo: '/app/minters/dai/wausd/idle',
  },
  {
    name: 'pickle',
    strategyIcon: 'pickle.png',
    tokenId: 'dai',
    asset1: 'DAI',
    // apy: "12.32%",
    asset2: 'waUSD',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/dai/pickle',
    minterTo: '/app/minters/dai/wausd/pickle',
    hidden: true,
  },
  {
    name: 'vesper',
    strategyIcon: 'vesper.png',
    tokenId: 'wbtc',
    asset1: 'WBTC',
    // apy: "6.72%",
    icon1: 'wbtc.png',
    asset2: 'waBTC',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/wbtc/vesper',
    minterTo: '/app/minters/wbtc/wabtc/vesper',
  },
  {
    name: 'liquity',
    strategyIcon: 'liquity.png',
    tokenId: 'lusd',
    asset1: 'LUSD',
    // apy: "11.10%",
    asset2: 'waLUSD',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/lusd/liquity/1',
    minterTo: '/app/minters/lusd/waLUSD/liquity',
  },
  {
    name: 'vesper',
    strategyIcon: 'vesper.png',
    tokenId: 'weth',
    asset1: 'ETH',
    // apy: "7.14%",
    asset2: 'waETH',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/weth/vesper',
    minterTo: '/app/minters/weth/waETH/vesper',
  },
  {
    name: 'vesper',
    strategyIcon: 'vesper.png',
    tokenId: 'link',
    asset1: 'LINK',
    // apy: "7.14%",
    asset2: 'waLINK',
    effeciency: '1.5x',
    strategyTo: '/app/strategies/link/vesper',
    minterTo: '/app/minters/link/waLINK/vesper',
  },
];
