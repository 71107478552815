import React, { useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import { Grid, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import BigNumber from 'bignumber.js';
import { contracts, pools } from 'features/configure';
import { useFetchVotingEscrowDetails, useFetchPools } from 'features/pools/redux/hooks';
import _ from 'lodash';
import { useParams, Link } from 'react-router-dom';
import { useConnectWallet } from '../home/redux/hooks';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 800,
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  textTitle: {
    fontWeight: 700,
    padding: '20px 0',
    fontSize: '0.95rem',
    color: theme.palette.text.primary,
  },
  text: {
    fontWeight: 600,
    padding: '20px 0',
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
  },
  container: {
    padding: 20,
  },
  borderRight: {
    // borderRight: "1px solid grey"
  },
  miniText: {
    fontSize: '0.85rem',
  },
  output: {
    padding: 10,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    lineHeight: 2,
  },
}));
export default function Calculator(props) {
  const classes = useStyles();
  const { address } = useConnectWallet();
  const [currency, setCurrency] = useState('WASABI');
  const [depositBalance, setDepositBalance] = useState('');
  const [result, setResult] = useState({ boost: '-', minAmount: '-', minLock: '-' });
  const [useExistDeposit, setUseExistDeposit] = useState(false);
  const [useExistLock, setUseExistLock] = useState(false);
  const [lockBalance, setLockBalance] = useState('');
  const [veWasabiBalance, setVeWASABIBalance] = useState('');
  const [selectedFarm, setSelectedFarm] = useState(-1);
  const [lockPeriod, setLockPeriod] = useState(1440);
  const [outputLockPeriod, setOutputLockPeriod] = useState(1440);
  const { votingEscrowDetails, fetchVotingEscrowDetails, fetchVotingEscrowDetailsPending } =
    useFetchVotingEscrowDetails();
  const { context, fetchPools } = useFetchPools();

  let filteredPools = pools;
  const farmList = _.map(filteredPools, o => {
    return { label: o.stakedTokenSymbol, value: o.pid };
  });
  let currencyList = [
    { label: 'WASABI', value: 'WASABI' },
    { label: 'veWASABI', value: 'veWASABI' },
  ];
  let lockList = [
    { label: '1 week', value: 7 },
    { label: '1 month', value: 30 },
    { label: '3 months', value: 90 },
    { label: '6 months', value: 180 },
    { label: '1 year', value: 360 },
    { label: '4 years', value: 1440 },
  ];
  const getContextValue = (key, defaultValue = '') => {
    return selectedFarm >= 0 && context && context[selectedFarm]
      ? context[selectedFarm][key]
      : defaultValue;
  };
  const poolStaked = getContextValue('totalStaked');
  useEffect(() => {
    if (address) {
      fetchPools();
      fetchVotingEscrowDetails();
      const id = setInterval(() => {
        fetchPools();
        fetchVotingEscrowDetails();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  useEffect(() => {
    onCalculate();
  }, [
    selectedFarm,
    poolStaked,
    depositBalance,
    veWasabiBalance,
    votingEscrowDetails,
    lockBalance,
    lockPeriod,
    useExistLock,
    useExistDeposit,
  ]);

  const handleExistDepositChange = event => {
    const checked = event.target.checked;
    setUseExistDeposit(checked);
    if (
      checked &&
      context &&
      selectedFarm >= 0 &&
      context[selectedFarm] &&
      context[selectedFarm].yourStaked > 0
    ) {
      setDepositBalance(context[selectedFarm].yourStaked);
    } else if (!checked) {
      setDepositBalance('');
    }
  };

  const handleExistLockChange = event => {
    const checked = event.target.checked;
    setUseExistLock(checked);
  };

  const getVeWasabiAmount = (balance, period) => {
    return balance && period
      ? parseFloat((balance * period) / contracts.votingEscrow.MAX_LOCK_TIME).toFixed(3)
      : 0;
  };
  const getMinLockAmount = (veWasabi, period) => {
    return veWasabi && period
      ? parseFloat((veWasabi * contracts.votingEscrow.MAX_LOCK_TIME) / period).toFixed(3)
      : 0;
  };
  const handleCurrencyChange = event => {
    setCurrency(event.target.value);
  };
  const handleFarmChange = event => {
    setSelectedFarm(event.target.value);
  };
  const handleLockPeriodChange = event => {
    setLockPeriod(event.target.value);
    setVeWASABIBalance(getVeWasabiAmount(lockBalance, event.target.value));
  };

  const handleOutputLockPeriodChange = event => {
    setOutputLockPeriod(event.target.value);
  };

  const onCalculate = () => {
    if (
      selectedFarm < 0 ||
      !poolStaked ||
      !depositBalance ||
      !veWasabiBalance ||
      veWasabiBalance == 0 ||
      !votingEscrowDetails
    )
      return;
    const workingAmount =
      0.4 * parseFloat(depositBalance) +
      0.6 *
        (((parseFloat(poolStaked) + (useExistDeposit ? 0 : parseFloat(depositBalance))) *
          parseFloat(veWasabiBalance)) /
          (parseFloat(votingEscrowDetails.totalSupply) +
            parseFloat(useExistLock ? 0 : veWasabiBalance)));
    const minAmount = Math.abs(
      (depositBalance * votingEscrowDetails.totalSupply) /
        (poolStaked - (useExistLock ? 0 : parseFloat(depositBalance)))
    );
    let boost = parseFloat(workingAmount / (depositBalance * 0.4)).toFixed(2);
    if (boost > 2.5) {
      boost = 2.5;
    }
    let minLock = {};
    for (let lock of lockList) {
      let amount = getMinLockAmount(minAmount, lock.value);
      minLock[lock.value] = amount;
    }

    setResult({ boost, minAmount, minLock });
  };
  const changeDepositBalance = event => {
    const { value } = event.target;
    setDepositBalance(value);
  };
  const changeLockBalance = event => {
    const { value } = event.target;
    setLockBalance(value);
    setVeWASABIBalance(getVeWasabiAmount(value, lockPeriod));
  };
  return (
    <>
      <div className={'vaultHeader'}>Farm Boost Calculator</div>
      <div className="vaultSubHeader">
        Boost your farming rewards by locking WASABI to get veWASABI.
      </div>
      <div className="card" style={{ padding: 0 }}>
        <Grid container className={classes.container} alignItems="center" spacing={1}>
          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.textTitle}>Data: Total veWASABI</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedInput
              value={
                votingEscrowDetails && !isNaN(votingEscrowDetails.totalSupply)
                  ? votingEscrowDetails.totalSupply
                  : ''
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.textTitle}>Step1: Please select a farm to start</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              style={{ width: '100%' }}
              type={'outlined'}
              value={selectedFarm}
              data={farmList}
              onSelect={handleFarmChange}
            />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.text}>You will get: The farm's total liquidity</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedInput value={poolStaked} disabled={true} />
          </Grid>
          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.textTitle}>Step2: Please Enter deposit</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedInput
              value={depositBalance}
              disabled={useExistDeposit}
              onChange={changeDepositBalance}
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.miniText}>
            <Checkbox
              checked={useExistDeposit}
              style={{ padding: 0 }}
              onChange={handleExistDepositChange}
            />
            Existing deposit
          </Grid>
          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.textTitle}>Step3: Please Enter WASABI</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedInput value={''} value={lockBalance} onChange={changeLockBalance} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Checkbox
              checked={useExistLock}
              style={{ padding: 0 }}
              onChange={handleExistLockChange}
            />
            Existing lock
          </Grid>
          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.text}>Lock for:</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              style={{ width: '100%' }}
              type={'outlined'}
              value={lockPeriod}
              data={lockList}
              onSelect={handleLockPeriodChange}
            />
          </Grid>
          <Grid item xs={12} md={5} className={classes.borderRight}>
            <div className={classes.text}>You will get: The amount of veWASABI</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomOutlinedInput value={veWasabiBalance} disabled={true} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button color="green" fullWidth onClick={onCalculate}>
              Calculate
            </Button>
          </Grid>
        </Grid>
        <div className={classes.container} style={{ borderTop: '1px solid white' }}>
          <h1 className={classes.title} style={{ textDecoration: 'underline' }}>
            Outputs
          </h1>
          <div className={classes.output}>
            Initial Boost = 1.0x
            <br />
            Max Boost possible in this farm = 2.5x
            <br />
            <br />
            Your calculated Boost = <span className={classes.textTitle}> {result.boost}x</span>
            <br />
            Min veWASABI required for Max Boost:{' '}
            <span className={classes.textTitle}>{result.minAmount} veWASABI</span>
            <br />
            This is equal to{' '}
            <span className={classes.textTitle}>
              {_.get(result, `minLock[${outputLockPeriod}]`, '-')} {currency} lock for{' '}
              <CustomDropdown
                type={'outlined'}
                value={outputLockPeriod}
                data={lockList}
                onSelect={handleOutputLockPeriodChange}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
