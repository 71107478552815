import React, { useState, useEffect } from 'react';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { formatCountdown } from '../../helpers/format';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useFetchDeprecatedPools } from '../redux/fetchDeprecatedPools';
import { useFetchUnstake } from '../redux/fetchUnstake';
import { useConnectWallet } from 'features/home/redux/hooks';
import _ from 'lodash';
import { deprecatedPools } from 'features/configure';
const DeprecatedTable = ({ context }) => {
  const theme = useTheme();
  const { deprecatedPoolContext, fetchDeprecatedPools } = useFetchDeprecatedPools();
  const { web3, address } = useConnectWallet();
  const { fetchUnstake, fetchUnstakePending } = useFetchUnstake();
  useEffect(() => {
    if (address && deprecatedPools && deprecatedPools.length > 0) {
      fetchDeprecatedPools();

      const id = setInterval(() => {
        fetchDeprecatedPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const onUnstake = pool => {
    if (!deprecatedPoolContext[pool.pid]) return;
    let amountValue = deprecatedPoolContext[pool.pid].yourStaked;
    if (amountValue < 1e-8) return;
    const formated = new BigNumber(amountValue)
      .multipliedBy(new BigNumber(10).exponentiatedBy(pool.stakedTokenDecimals))
      .toString(10);
    fetchUnstake({
      address,
      web3,
      poolId: pool.pid,
      poolAddress: pool.poolAddress,
      amount: formated,
    }).then(() => {});
  };

  if (!deprecatedPools || deprecatedPools.length == 0) return <div />;
  return (
    <div className="card" style={{ border: '1px solid red', marginTop: 40 }}>
      <div style={{ backgroundColor: 'red', padding: 20, borderRadius: 5, lineHeight: 1.5 }}>
        Important Update Note: <br />
        Wasabix ETH has been updated to version 2. The deprecated farms will stop minting rewards.
        Please move your fund from deprecated farms(v1) to the updated farms(v2). Thank you so much
        for your cooperation.
      </div>
      <div className="header" style={{ marginTop: 30 }}>
        {' '}
        Deprecated Farms(v1)
      </div>
      <CustomTable
        headers={['Asset', 'APR', 'Deposited', 'Countdown']}
        contents={deprecatedPools.map(pool => {
          const staked = deprecatedPoolContext[pool.pid]
            ? parseFloat(deprecatedPoolContext[pool.pid].yourStaked).toFixed(2)
            : 0;
          return [
            <>
              <img
                src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + '.png')}
                className={'icon'}
                alt=""
              />
              {pool.stakedTokenSymbol}
            </>,
            <>
            <p style={{ color: 'red' }}>0%</p>
            </>,
            staked,
            formatCountdown(pool.endTimestamp * 1000),
            <Button
              color="text"
              disabled={staked == 0}
              onClick={() => {
                onUnstake(pool);
              }}
            >
              Withdraw <ArrowRightIcon />
            </Button>,
          ];
        })}
      />
      <div style={{ color: 'red', marginTop: 20 }}>
         Your pending WASABI rewards will automatically claimed to your wallet
      </div>
    </div>
  );
};

export default DeprecatedTable;
