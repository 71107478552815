import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_REPAY_BEGIN,
  VAULT_FETCH_REPAY_SUCCESS,
  VAULT_FETCH_REPAY_FAILURE,
} from './constants';
import { repay } from '../../web3';
import { fetchVaultDetail } from './action';

export function fetchRepay({ address, web3, daiAmount, alusdAmount, ethAmount, tokenId, strategyId, isETH }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_REPAY_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      repay({ web3, address, daiAmount, alusdAmount, ethAmount, tokenId, strategyId, isETH, dispatch })
        .then(data => {
          dispatch({
            type: VAULT_FETCH_REPAY_SUCCESS,
            data
          });
          dispatch(fetchVaultDetail(tokenId, strategyId))
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_REPAY_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}


export function useFetchRepay() {
  const dispatch = useDispatch();

  const { fetchRepayPending } = useSelector(
    state => ({
      fetchRepayPending: state.vault.fetchRepayPending,
    })
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchRepay(data));
    },
    [dispatch]
  );

  return {
    fetchRepay: boundAction,
    fetchRepayPending
  };
}

export function reducer(state, action) {
  let { fetchRepayPending } = state;
  switch (action.type) {
    case VAULT_FETCH_REPAY_BEGIN:
      // Just after a request is sent
      fetchRepayPending = true;
      return {
        ...state,
        fetchRepayPending
      };

    case VAULT_FETCH_REPAY_SUCCESS:
    case VAULT_FETCH_REPAY_FAILURE:
      // The request is success
      fetchRepayPending = false;
      return {
        ...state,
        fetchRepayPending
      };

    default:
      return state;
  }
}
