import React from 'react';
import CustomTable from 'components/CustomTable/CustomTable.js';
import moment from 'moment';
import _ from 'lodash';
const VestedTable = ({ data }) => {
  // if (!data || data.length == 0) return <div />
  return (
    <CustomTable headers={["Start Date", "End Date", "Days", "Vesting Amount"]} contents={
      data.map(d => {
        return [
          moment(d.startDate).format('MMM D YYYY HH:mm'),
          moment(d.endDate).format('MMM D YYYY HH:mm'),
          d.remainingDays,
          parseFloat(d.amount || d.reward).toFixed(2)
        ];
      })
    } />
  );
};

export default VestedTable;
