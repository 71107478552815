import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from './styles';

const useStyles = makeStyles(styles);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const IconToolTip = (props) => {
  const { children } = props;
  return (
        <HtmlTooltip
            placement="right"
            interactive
            title={
              <React.Fragment>
                <Typography color="inherit">{children}</Typography>
              </React.Fragment>
            }
          >
          <IconButton style={{float: 'right'}}>
            <HelpIcon />
          </IconButton>
        </HtmlTooltip>

  );
};

export default memo(IconToolTip);
