import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  POOLS_FETCH_STAKE_BEGIN,
  POOLS_FETCH_STAKE_SUCCESS,
  POOLS_FETCH_STAKE_FAILURE,
} from './constants';
import { poolStake } from '../../web3';
import { fetchPools } from './action';

export function fetchStake({ address, web3, poolId, amount }) {
  return dispatch => {
    dispatch({
      type: POOLS_FETCH_STAKE_BEGIN,
      index: poolId
    });

    const promise = new Promise((resolve, reject) => {
      poolStake({ web3, address, poolId, amount, dispatch })
        .then(data => {
          dispatch({
            type: POOLS_FETCH_STAKE_SUCCESS,
            data,
            index: poolId
          });
          dispatch(fetchPools());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_STAKE_FAILURE,
            index: poolId
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchStake(poolId) {
  const dispatch = useDispatch();

  const { fetchStakePending } = useSelector(state => ({
    fetchStakePending: state.pools.fetchStakePending[poolId],
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchStake(data));
    },
    [dispatch]
  );

  return {
    fetchStake: boundAction,
    fetchStakePending,
  };
}

export function reducer(state, action) {
  let { fetchStakePending } = state;
  switch (action.type) {
    case POOLS_FETCH_STAKE_BEGIN:
      fetchStakePending[action.index] = true;
      return {
        ...state,
        fetchStakePending
      };

    case POOLS_FETCH_STAKE_SUCCESS:
      fetchStakePending[action.index] = false;
      return {
        ...state,
        fetchStakePending
      };

    case POOLS_FETCH_STAKE_FAILURE:
      fetchStakePending[action.index] = false;
      return {
        ...state,
        fetchStakePending
      };

    default:
      return state;
  }
}
