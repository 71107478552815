import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { strategiesData } from 'features/configure';
import { useFetchApys } from '../../vault/redux/fetchApys';
import {
  converAprStringToApyString,
  convertApyFloatToString,
  convertAprFloatToString,
} from 'features/helpers/utils';
import _ from 'lodash';
const AllStrategiesTable = ({ details, tvls, earnData }) => {
  const { apys, fetchApys } = useFetchApys();
  useEffect(() => {
    fetchApys();
  }, []);

  return (
    <CustomTable
      headers={[
        'Asset\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0',
        'Platform',
        'TVL',
        'Total earnings',
        'APR → APY',
      ]}
      contents={strategiesData.map(strategie => {
        const tlv = tvls[strategie.tokenId][strategie.name];
        const query = { tokenId: strategie.tokenId, strategyId: strategie.name };
        const apy = apys && _.find(apys, query) ? _.find(apys, query).apy : null;
        const targetEarnData = earnData ? _.find(earnData, query) : null;
        const totalHarvestedValue = targetEarnData
          ? new BigNumber(targetEarnData.totalHarvestedValue).toFormat(1)
          : 'Loading...';

        if (!strategie.hidden) {
          return [
            <>
              <img
                src={require('assets/img/' + _.toLower(strategie.asset1) + '.png')}
                className={'icon'}
                alt=""
              />
              {strategie.asset1}
            </>,
            _.upperFirst(strategie.name),
            isNaN(tlv) ? 'Loading...' : `$${new BigNumber(tlv).toFormat(2)}`,
            totalHarvestedValue,
            apy
              ? convertApyFloatToString(apy) + ' → ' + convertAprFloatToString(apy)
              : 'Loading...',
            <Button color="text" component={Link} to={strategie.strategyTo}>
              Enter <ArrowRightIcon />
            </Button>,
          ];
        }
      })}
    />
  );
};

export default AllStrategiesTable;
