import _ from 'lodash';

// waUSD pool : 2.75
// WASABI pool : 2.75
// WASABI-ETH Pool : 33
// waUSD3CRV : 4.125( from 16.5 to 4.125)
// WIT: 30
// WVVT: 15
// Innovation zone: 12.375 -> ( controlled by a new token called WIZT )

export const pools = [
  {
    pid: 0,
    multiplier: '1X',
    stakedTokenName: 'Wasabi USD',
    stakedTokenSymbol: 'waUSD',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0xc2db4c131adaf01c15a1db654c040c8578929d55',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 1,
    multiplier: '58X',
    stakedTokenName: 'Wasabi-ETH SLP',
    stakedTokenSymbol: 'WASABI-ETH SLP',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x8f9ef75cd6e610dd8acf8611c344573032fb9c3d',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 2,
    multiplier: '1X',
    stakedTokenName: 'WaUSD-3CRV',
    stakedTokenSymbol: 'waUSD3CRV-f',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x9f6664205988c3bf4b12b851c075102714869535',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 3,
    multiplier: '1X',
    stakedTokenName: 'Wasabi BTC',
    stakedTokenSymbol: 'waBTC',
    stakedTokenDecimals: 8,
    stakedTokenAddress: '0xfd8e70e83e399307db3978d3f34b060a06792c36',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 4,
    multiplier: '2X',
    stakedTokenName: 'Wasabi LUSD',
    stakedTokenSymbol: 'waLUSD',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0xcbf335Bb8eE86A5A88bEbCda4506a665aA8d7022',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 5,
    multiplier: '1X',
    stakedTokenName: 'Wasabi ETH',
    stakedTokenSymbol: 'waETH',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x6a1fbefdF67445C7F531b4F3e04Ffb37b7b13794',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 6,
    multiplier: '1X',
    stakedTokenName: 'Wasabi LINK',
    stakedTokenSymbol: 'waLINK',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x29DDF2c4F1E80DC9E95CC57a9cA03a28E4aB6D6c',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
  {
    pid: 7,
    multiplier: '1X',
    stakedTokenName: 'LINK waLINK SLP',
    stakedTokenSymbol: 'LINK-waLINK SLP',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0xfA7CE57A0C69367B4bB64c74bd464497e39cbE4e',
    rewardTokenName: 'Wasabi',
    rewardTokenSymbol: 'WASABI',
    rewardTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
  },
];

export const deprecatedPools = [
  {
    pid: 0,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi',
    stakedTokenSymbol: 'WASABI',
    stakedTokenDecimals: 18,
    apy: '0%',
    stakedTokenAddress: '0x896e145568624a498c5a909187363AE947631503',
    endTimestamp: 1628942400,
  },
  {
    pid: 1,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi USD',
    stakedTokenSymbol: 'waUSD',
    stakedTokenDecimals: 18,
    apy: '24.95%',
    stakedTokenAddress: '0xc2db4c131ADaF01c15a1DB654c040c8578929D55',
    endTimestamp: 1628942400,
  },
  {
    pid: 4,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi-ETH SLP',
    stakedTokenSymbol: 'Wasabi-ETH SLP',
    apy: '142.24%',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x8f9ef75cd6e610dd8acf8611c344573032fb9c3d',
    endTimestamp: 1628942400,
  },
  {
    pid: 5,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'WaUSD-3CRV',
    stakedTokenSymbol: 'waUSD3CRV-f',
    stakedTokenDecimals: 18,
    apy: '27.37%',
    stakedTokenAddress: '0x9f6664205988c3bf4b12b851c075102714869535',
    endTimestamp: 1628942400,
  },
  {
    pid: 7,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi BTC',
    stakedTokenSymbol: 'waBTC',
    apy: '14.63%',
    stakedTokenDecimals: 8,
    stakedTokenAddress: '0xfd8e70e83e399307db3978d3f34b060a06792c36',
    endTimestamp: 1628942400,
  },
  {
    pid: 8,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi LUSD',
    stakedTokenSymbol: 'waLUSD',
    stakedTokenDecimals: 18,
    apy: '55.79%',
    stakedTokenAddress: '0xcbf335Bb8eE86A5A88bEbCda4506a665aA8d7022',
    endTimestamp: 1628942400,
  },
  {
    pid: 9,
    poolAddress: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
    stakedTokenName: 'Wasabi ETH',
    stakedTokenSymbol: 'waETH',
    apy: '16.16%',
    stakedTokenDecimals: 18,
    stakedTokenAddress: '0x6a1fbefdF67445C7F531b4F3e04Ffb37b7b13794',
    endTimestamp: 1628942400,
  },
];
