import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from '../styles';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchTransmuterDetail } from '../redux/hooks';
import { strategiesData } from 'features/configure';
import { useFetchApys } from 'features/vault/redux/fetchApys';
import _ from 'lodash';
import { convertAprFloatToString, converAprToApy } from 'features/helpers/utils';

const useStyles = makeStyles(styles);
export default function CollectorStatus() {
  const classes = useStyles();
  const { tokenId } = useParams();
  const { address } = useConnectWallet();
  const { details, fetchTransmuterDetail } = useFetchTransmuterDetail();
  const { apys, fetchApys } = useFetchApys();

  let yearnDaiDailyYield;
  let idleDaiDailyYield;
  let pickleDaiDailyYield;
  let vesperWbtcDailyYield;
  let liquityLusdDailyYield;
  let vesperWethDailyYield;
  let vesperLinkDailyYield;

  let yearnDaiApy;
  let idleDaiApy;
  let vesperWbtcApy;
  let liquityLusdApy;
  let vesperWethApy;
  let vesperLinkApy;
  useEffect(() => {
    fetchApys();
  }, []);

  if (tokenId === 'wausd') {
    const yearnDaiTotalDeposited = details['wausd']
      ? Number(details['wausd'].daiDepositedInYvDaiVault)
      : 0;
    const idleDaiTotalDeposited = details['wausd']
      ? Number(details['wausd'].daiDepositedInIdleDaiVault)
      : 0;

    yearnDaiApy = apys ? _.find(apys, { tokenId: 'dai', strategyId: 'yearn' }).apy : null;
    idleDaiApy = apys ? _.find(apys, { tokenId: 'dai', strategyId: 'idle' }).apy : null;
    // const yearnDaiApyHardcoded = Number(0.0452);
    // const idleDaiApyHardcoded = Number(0.096);

    yearnDaiDailyYield = (yearnDaiTotalDeposited * yearnDaiApy) / 365;
    idleDaiDailyYield = (idleDaiTotalDeposited * idleDaiApy) / 365;
  } else if (tokenId === 'wabtc') {
    const vesperWbtcTotalDeposited = details['wabtc']
      ? Number(details['wabtc'].wbtcDepositedInVesperDaiVault)
      : 0;
    vesperWbtcApy = apys ? _.find(apys, { tokenId: 'wbtc', strategyId: 'vesper' }).apy : null;
    // const vesperWbtcApyHardcoded = Number(0.0672);
    vesperWbtcDailyYield = (vesperWbtcTotalDeposited * vesperWbtcApy) / 365;
  } else if (tokenId === 'walusd') {
    const liquityLusdTotalDeposited = details['walusd']
      ? Number(details['walusd'].lusdDepositedInLiquityLusdVault)
      : 0;
    liquityLusdApy = apys ? _.find(apys, { tokenId: 'lusd', strategyId: 'liquity' }).apy : null;
    // const liquityLusdApyHardcoded = Number(0.111);
    liquityLusdDailyYield = (liquityLusdTotalDeposited * liquityLusdApy) / 365;
  } else if (tokenId === 'waeth') {
    const vesperWethTotalDeposited = details['waeth']
      ? Number(details['waeth'].wethDepositedInVesperDaiVault)
      : 0;
    vesperWethApy = apys ? _.find(apys, { tokenId: 'weth', strategyId: 'vesper' }).apy : null;
    // const vesperWethApyHardcoded = Number(0.0714);
    vesperWethDailyYield = (vesperWethTotalDeposited * vesperWethApy) / 365;
  } else if (tokenId === 'walink') {
    let query = { tokenId: 'link', strategyId: 'vesper' };
    const vesperLinkTotalDeposited = details['walink']
      ? Number(details['walink'].linkDepositedInVesperDaiVault)
      : 0;
    vesperLinkApy = apys && _.find(apys, query) ? _.find(apys, query).apy : null;
    // const vesperWethApyHardcoded = Number(0.0714);
    vesperLinkDailyYield = (vesperLinkTotalDeposited * vesperLinkApy) / 365;
  }
  useEffect(() => {
    if (address) {
      fetchTransmuterDetail(tokenId);

      const id = setInterval(() => {
        fetchTransmuterDetail(tokenId);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenId]);

  return (
    <>
      <div className={classes.title}>Global Collector Status</div>
      <Grid container item justify="center" className={classes.detailContent}>
        {tokenId === 'wausd' ? (
          <>
            <Grid item xs={6}>
              Total Staked waUSD:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wausd'].transmuterAlUsdBalance)
                ? 'Loading...'
                : parseFloat(details['wausd'].transmuterAlUsdBalance).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Total DAI Available for Converting:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wausd'].transmuterDaiBalance)
                ? 'Loading...'
                : parseFloat(details['wausd'].transmuterDaiBalance).toFixed(2)}
            </Grid>
            <Grid item xs={12} className={classes.detailHeader}>
              Yearn
            </Grid>
            <Grid item xs={6}>
              Yearn DAI Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(yearnDaiApy)}
            </Grid>
            <Grid item xs={6}>
              Total DAI Deposited in Yearn Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wausd'].daiDepositedInYvDaiVault)
                ? 'Loading...'
                : parseFloat(details['wausd'].daiDepositedInYvDaiVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated Yearn DAI Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(yearnDaiDailyYield) ? 'Loading...' : parseFloat(yearnDaiDailyYield).toFixed(2)}
            </Grid>
            <Grid item xs={12} className={classes.detailHeader}>
              IDLE
            </Grid>
            <Grid item xs={6}>
              IDLE DAI Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(idleDaiApy)}
            </Grid>
            <Grid item xs={6}>
              Total DAI Deposited in IDLE Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wausd'].daiDepositedInIdleDaiVault)
                ? 'Loading...'
                : parseFloat(details['wausd'].daiDepositedInIdleDaiVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated IDLE DAI Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(idleDaiDailyYield) ? 'Loading...' : parseFloat(idleDaiDailyYield).toFixed(2)}
            </Grid>
          </>
        ) : tokenId === 'walusd' ? (
          <>
            <Grid item xs={6}>
              Total Staked waLUSD:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walusd'].transmuterWaLusdBalance)
                ? 'Loading...'
                : parseFloat(details['walusd'].transmuterWaLusdBalance).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Total LUSD Available for Converting:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walusd'].transmuterLusdBalance)
                ? 'Loading...'
                : parseFloat(details['walusd'].transmuterLusdBalance).toFixed(2)}
            </Grid>

            <Grid item xs={12} className={classes.detailHeader}>
              Liquity
            </Grid>
            <Grid item xs={6}>
              Liquity LUSD Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(liquityLusdApy)}
            </Grid>
            <Grid item xs={6}>
              Total LUSD Deposited in Liquity Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walusd'].lusdDepositedInLiquityLusdVault)
                ? 'Loading...'
                : parseFloat(details['walusd'].lusdDepositedInLiquityLusdVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated Liquity LUSD Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(liquityLusdDailyYield)
                ? 'Loading...'
                : parseFloat(liquityLusdDailyYield).toFixed(2)}
            </Grid>
          </>
        ) : tokenId === 'waeth' ? (
          <>
            <Grid item xs={6}>
              Total Staked waETH:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['waeth'].transmuterWaEthBalance)
                ? 'Loading...'
                : parseFloat(details['waeth'].transmuterWaEthBalance).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Total WETH Available for Converting:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['waeth'].transmuterWethBalance)
                ? 'Loading...'
                : parseFloat(details['waeth'].transmuterWethBalance).toFixed(2)}
            </Grid>

            <Grid item xs={12} className={classes.detailHeader}>
              Vesper
            </Grid>
            <Grid item xs={6}>
              Vesper WETH Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(vesperWethApy)}
            </Grid>
            <Grid item xs={6}>
              Total WETH Deposited in Vesper Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['waeth'].wethDepositedInVesperDaiVault)
                ? 'Loading...'
                : parseFloat(details['waeth'].wethDepositedInVesperDaiVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated Vesper WETH Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(vesperWethDailyYield)
                ? 'Loading...'
                : parseFloat(vesperWethDailyYield).toFixed(2)}
            </Grid>
          </>
        ) : tokenId === 'walink' ? (
          <>
            <Grid item xs={6}>
              Total Staked waLINK:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walink'].transmuterWaLinkBalance)
                ? 'Loading...'
                : parseFloat(details['walink'].transmuterWaLinkBalance).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Total LINK Available for Converting:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walink'].transmuterLinkBalance)
                ? 'Loading...'
                : parseFloat(details['walink'].transmuterLinkBalance).toFixed(2)}
            </Grid>

            <Grid item xs={12} className={classes.detailHeader}>
              Vesper
            </Grid>
            <Grid item xs={6}>
              Vesper LINK Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(vesperLinkApy)}
            </Grid>
            <Grid item xs={6}>
              Total LINK Deposited in Vesper Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['walink'].linkDepositedInVesperDaiVault)
                ? 'Loading...'
                : parseFloat(details['walink'].linkDepositedInVesperDaiVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated Vesper LINK Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(vesperLinkDailyYield)
                ? 'Loading...'
                : parseFloat(vesperLinkDailyYield).toFixed(2)}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              Total Staked waBTC:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wabtc'].transmuterWaBtcBalance)
                ? 'Loading...'
                : parseFloat(details['wabtc'].transmuterWaBtcBalance).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Total wBTC Available for Converting:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wabtc'].transmuterWbtcBalance)
                ? 'Loading...'
                : parseFloat(details['wabtc'].transmuterWbtcBalance).toFixed(2)}
            </Grid>
            <Grid item xs={12} className={classes.detailHeader}>
              Vesper
            </Grid>
            <Grid item xs={6}>
              Vesper wBTC Vault APY:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {convertAprFloatToString(vesperWbtcApy)}
            </Grid>
            <Grid item xs={6}>
              Total wBTC Deposited in Vesper Vault:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(details['wabtc'].wbtcDepositedInVesperDaiVault)
                ? 'Loading...'
                : parseFloat(details['wabtc'].wbtcDepositedInVesperDaiVault).toFixed(2)}
            </Grid>
            <Grid item xs={6}>
              Estimated Vesper wBTC Daily Yield:
            </Grid>
            <Grid item xs={6} className={classes.tableContent}>
              {isNaN(vesperWbtcDailyYield)
                ? 'Loading...'
                : parseFloat(vesperWbtcDailyYield).toFixed(2)}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
