import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_USER_STATS_BEGIN,
  FETCH_USER_STATS_SUCCESS,
  FETCH_USER_STATS_FAILURE,
} from './constants';
import { pools } from '../../configure/pools';
import { apiUrl } from "features/configure"
export function fetchUserStats(address) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_STATS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      axios.get(`${apiUrl}/stats?address=${address}`).then(
        response => {
          dispatch({
            type: FETCH_USER_STATS_SUCCESS,
            data: response.data,
          });
          resolve(response);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_USER_STATS_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useFetchUserStats() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { userStatsData, userStatsPending } = useSelector(
    state => ({
      userStatsData: state.dashboard.userStatsData,
      userStatsPending: state.dashboard.userStatsPending,
    })
  );

  const boundAction = useCallback(
    (address) => dispatch(fetchUserStats(address)),
    [dispatch],
  );

  return {
    userStatsData,
    fetchUserStats: boundAction,
    userStatsPending
  };
}

export function reducer(state, action) {
  let { userStatsPending, userStatsData } = state;
  switch (action.type) {
    case FETCH_USER_STATS_BEGIN:
      userStatsPending = true;
      return {
        ...state,
        userStatsPending,
      };

    case FETCH_USER_STATS_SUCCESS:

      return {
        ...state,
        userStatsData: action.data,
        userStatsPending: false
      };

    case FETCH_USER_STATS_FAILURE:
      // The request is failed
      userStatsPending = false;

      return {
        ...state,
        userStatsData: undefined,
        userStatsPending
      };

    default:
      return state;
  }
}
