import { alchmistABI, yumEthVaultABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const deposit = async ({ web3, address, amount, tokenId, strategyId, isETH, dispatch }) => {
  const alchemistAddress = contracts.vault[tokenId][strategyId].address;
  let contract = new web3.eth.Contract(alchmistABI, alchemistAddress);
  if (tokenId === 'weth') {
    contract = new web3.eth.Contract(yumEthVaultABI, alchemistAddress);
  }
  const data = await _deposit({ web3, contract, amount, address, isETH, dispatch });
  return data;
};

const _deposit = ({ web3, contract, amount, address, isETH, dispatch }) => {
  return new Promise((resolve, reject) => {
    if (isETH) {
      contract.methods
      .deposit()
      .send({ from: address, value: amount })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    } else {
      contract.methods
      .deposit(amount)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
    }
  });
};
