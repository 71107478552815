export const VAULT_FETCH_BALANCES_BEGIN = 'VAULT_FETCH_BALANCES_BEGIN';
export const VAULT_FETCH_BALANCES_SUCCESS = 'VAULT_FETCH_BALANCES_SUCCESS';
export const VAULT_FETCH_BALANCES_FAILURE = 'VAULT_FETCH_BALANCES_FAILURE';

export const VAULT_FETCH_VAULTS_DATA_BEGIN = 'VAULT_FETCH_VAULTS_DATA_BEGIN';
export const VAULT_FETCH_VAULTS_DATA_SUCCESS = 'VAULT_FETCH_VAULTS_DATA_SUCCESS';
export const VAULT_FETCH_VAULTS_DATA_FAILURE = 'VAULT_FETCH_VAULTS_DATA_FAILURE';

export const VAULT_FETCH_APPROVAL_BEGIN = 'VAULT_FETCH_APPROVAL_BEGIN';
export const VAULT_FETCH_APPROVAL_SUCCESS = 'VAULT_FETCH_APPROVAL_SUCCESS';
export const VAULT_FETCH_APPROVAL_FAILURE = 'VAULT_FETCH_APPROVAL_FAILURE';

export const VAULT_FETCH_DEPOSIT_BEGIN = 'VAULT_FETCH_DEPOSIT_BEGIN';
export const VAULT_FETCH_DEPOSIT_SUCCESS = 'VAULT_FETCH_DEPOSIT_SUCCESS';
export const VAULT_FETCH_DEPOSIT_FAILURE = 'VAULT_FETCH_DEPOSIT_FAILURE';

export const VAULT_FETCH_WITHDRAW_BEGIN = 'VAULT_FETCH_WITHDRAW_BEGIN';
export const VAULT_FETCH_WITHDRAW_SUCCESS = 'VAULT_FETCH_WITHDRAW_SUCCESS';
export const VAULT_FETCH_WITHDRAW_FAILURE = 'VAULT_FETCH_WITHDRAW_FAILURE';

export const VAULT_FETCH_BORROW_BEGIN = 'VAULT_FETCH_BORROW_BEGIN';
export const VAULT_FETCH_BORROW_SUCCESS = 'VAULT_FETCH_BORROW_SUCCESS';
export const VAULT_FETCH_BORROW_FAILURE = 'VAULT_FETCH_BORROW_FAILURE';

export const VAULT_FETCH_TROVE_BEGIN = 'VAULT_FETCH_TROVE_BEGIN';
export const VAULT_FETCH_TROVE_SUCCESS = 'VAULT_FETCH_TROVE_SUCCESS';
export const VAULT_FETCH_TROVE_FAILURE = 'VAULT_FETCH_TROVE_FAILURE';

export const VAULT_FETCH_CURRENT_TROVE_BEGIN = 'VAULT_FETCH_CURRENT_TROVE_BEGIN';
export const VAULT_FETCH_CURRENT_TROVE_SUCCESS = 'VAULT_FETCH_CURRENT_TROVE_SUCCESS';
export const VAULT_FETCH_CURRENT_TROVE_FAILURE = 'VAULT_FETCH_CURRENT_TROVE_FAILURE';

export const VAULT_FETCH_LIQUIDATE_BEGIN = 'VAULT_FETCH_LIQUIDATE_BEGIN';
export const VAULT_FETCH_LIQUIDATE_SUCCESS = 'VAULT_FETCH_LIQUIDATE_SUCCESS';
export const VAULT_FETCH_LIQUIDATE_FAILURE = 'VAULT_FETCH_LIQUIDATE_FAILURE';

export const VAULT_FETCH_REPAY_BEGIN = 'VAULT_FETCH_REPAY_BEGIN';
export const VAULT_FETCH_REPAY_SUCCESS = 'VAULT_FETCH_REPAY_SUCCESS';
export const VAULT_FETCH_REPAY_FAILURE = 'VAULT_FETCH_REPAY_FAILURE';

export const VAULT_FETCH_APYS_BEGIN = 'VAULT_FETCH_APYS_BEGIN';
export const VAULT_FETCH_APYS_SUCCESS = 'VAULT_FETCH_APYS_SUCCESS';
export const VAULT_FETCH_APYS_FAILURE = 'VAULT_FETCH_APYS_FAILURE';

export const VAULT_FETCH_ETH_BALANCE_BEGIN = 'VAULT_FETCH_ETH_BALANCE_BEGIN';
export const VAULT_FETCH_ETH_BALANCE_SUCCESS = 'VAULT_FETCH_ETH_BALANCE_SUCCESS';
export const VAULT_FETCH_ETH_BALANCE_FAILURE = 'VAULT_FETCH_ETH_BALANCE_FAILURE';

export const VAULT_FETCH_STRATEGY_HARVEST_BEGIN = 'VAULT_FETCH_STRATEGY_HARVEST_BEGIN';
export const VAULT_FETCH_STRATEGY_HARVEST_SUCCESS = 'VAULT_FETCH_STRATEGY_HARVEST_SUCCESS';
export const VAULT_FETCH_STRATEGY_HARVEST_FAILURE = 'VAULT_FETCH_STRATEGY_HARVEST_FAILURE';

export const VAULT_DETAIL_FETCH_BEGIN = 'VAULT_DETAIL_FETCH_BEGIN';
export const VAULT_DETAIL_FETCH_SUCCESS = 'VAULT_DETAIL_FETCH_SUCCESS';
export const VAULT_DETAIL_FETCH_FAILURE = 'VAULT_DETAIL_FETCH_FAILURE';

export const LQTY_DETAIL_FETCH_BEGIN = 'LQTY_DETAIL_FETCH_BEGIN';
export const LQTY_DETAIL_FETCH_SUCCESS = 'LQTY_DETAIL_FETCH_SUCCESS';
export const LQTY_DETAIL_FETCH_FAILURE = 'LQTY_DETAIL_FETCH_FAILURE';

export const CHECK_DAI_APPROVAL_BEGIN = 'CHECK_DAI_APPROVAL_BEGIN';
export const CHECK_DAI_APPROVAL_SUCCESS = 'CHECK_DAI_APPROVAL_SUCCESS';
export const CHECK_DAI_APPROVAL_FAILURE = 'CHECK_DAI_APPROVAL_FAILURE';

export const FETCH_DAI_APPROVAL_BEGIN = 'FETCH_DAI_APPROVAL_BEGIN';
export const FETCH_DAI_APPROVAL_SUCCESS = 'FETCH_DAI_APPROVAL_SUCCESS';
export const FETCH_DAI_APPROVAL_FAILURE = 'FETCH_DAI_APPROVAL_FAILURE';

export const CHECK_ALUSD_APPROVAL_BEGIN = 'CHECK_ALUSD_APPROVAL_BEGIN';
export const CHECK_ALUSD_APPROVAL_SUCCESS = 'CHECK_ALUSD_APPROVAL_SUCCESS';
export const CHECK_ALUSD_APPROVAL_FAILURE = 'CHECK_ALUSD_APPROVAL_FAILURE';

export const FETCH_ALUSD_APPROVAL_BEGIN = 'FETCH_ALUSD_APPROVAL_BEGIN';
export const FETCH_ALUSD_APPROVAL_SUCCESS = 'FETCH_ALUSD_APPROVAL_SUCCESS';
export const FETCH_ALUSD_APPROVAL_FAILURE = 'FETCH_ALUSD_APPROVAL_FAILURE';