import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  POOL_USERS_FETCH_BEGIN,
  POOL_USERS_FETCH_SUCCESS,
  POOL_USERS_FETCH_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { stakingPoolsABI, contracts } from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import _ from 'lodash';

export function fetchPoolUserDetails(poolId) {
  return (dispatch, getState) => {
    dispatch({
      type: POOL_USERS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const stakingPoolsContract = new web3.eth.Contract(
        stakingPoolsABI,
        contracts.oldStakingPools.address
      );

      let output = [];

      stakingPoolsContract.methods
        .nextUser(1)
        .call()
        .then(nextUser => {
          nextUser = Number(nextUser);

          let calls = [];
          for (var i = 0; i < nextUser; i++) {
            calls.push({ result: stakingPoolsContract.methods.getPoolUser(poolId, i) });
          }

          multicall
            .all([calls])
            .then(([results]) => {
              let secondCalls = [];
              for (var i = 0; i < nextUser; i++) {
                const userAddress = results[i].result;
                secondCalls.push({
                  result: stakingPoolsContract.methods.getAccumulatedPower(userAddress, poolId),
                });
                secondCalls.push({
                  result: stakingPoolsContract.methods.getStakeTotalDeposited(userAddress, poolId),
                });
                output.push({ address: userAddress });
              }

              multicall
                .all([secondCalls])
                .then(([results]) => {
                  for (var i = 0; i < nextUser; i++) {
                    const userAccumulatedStakingPoint = Number(results[i * 2].result);
                    const userCurrentStaking = new BigNumber(results[i * 2 + 1].result);
                    output[i]['as'] = Number(
                      convertAmountFromRawNumber(userAccumulatedStakingPoint)
                    );
                    output[i]['cs'] = convertAmountFromRawNumber(userCurrentStaking);
                  }

                  dispatch({
                    type: POOL_USERS_FETCH_SUCCESS,
                    data: output,
                  });
                  resolve();
                })
                .catch(error => {
                  dispatch({
                    type: POOL_USERS_FETCH_FAILURE,
                  });
                  return reject(error.message || error);
                });
            })
            .catch(error => {
              dispatch({
                type: POOL_USERS_FETCH_FAILURE,
              });
              return reject(error.message || error);
            });
        })
        .catch(error => {
          dispatch({
            type: POOL_USERS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPoolUserDetails() {
  const dispatch = useDispatch();

  const { userDetails, fetchPoolUserDetailsPending, fetchPoolUserDetailsDone } = useSelector(
    state => ({
      userDetails: state.pools.userDetails,
      fetchPoolUserDetailsPending: state.pools.fetchPoolUserDetailsPending,
      fetchPoolUserDetailsDone: state.pools.fetchPoolUserDetailsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPoolUserDetails(data));
    },
    [dispatch]
  );

  return {
    userDetails,
    fetchPoolUserDetails: boundAction,
    fetchPoolUserDetailsDone,
    fetchPoolUserDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOL_USERS_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolUserDetailsPending: true,
      };

    case POOL_USERS_FETCH_SUCCESS:
      return {
        ...state,
        userDetails: action.data,
        fetchPoolUserDetailsDone: true,
        fetchPoolUserDetailsPending: false,
      };

    case POOL_USERS_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolUserDetailsPending: false,
      };

    default:
      return state;
  }
}
