export const contracts = {
  vault: {
    //alchemist
    dai: {
      yearn: {
        address: '0x5cefb9f7c53a1b0c78e239b2445ddd2d362b7076',
      },
      idle: {
        address: '0x894CcdBED28E294482fECf10eAC5962148bf4E15',
      },
      pickle: {
        address: '0x2de9441c3e22725474146450fc3467a2c778040f',
      },
    },
    wbtc: {
      vesper: {
        address: '0x26a70759222b1842A7c72215F64C7FdE8Db24856',
      },
    },
    lusd: {
      liquity: {
        address: '0x55c75414F525Ef9ccbb8105Ce083EDbDA0075FB5',
      },
    },
    weth: {
      vesper: {
        address: '0xB642eb5Faf7e731Ff62823515b3fF82B45d385bC',
      },
    },
    link: {
      vesper: {
        address: '0xc4f2030dEd976F45fBb23b702ba725d6f88eF142',
      },
    },
  },
  multicall: {
    address: '0x5Eb3fa2DFECdDe21C950813C665E9364fa609bD2',
  },
  transmuter: {
    wausd: {
      address: '0x219de705e6c22d6fbc27446161efcc7d5d055ecb',
    },
    wabtc: {
      address: '0x68e91DF501ab66A0796d0fd164B907Acf5f89AD0',
    },
    walusd: {
      address: '0xbE8043ad7b54045619022a4f7c3A596023425540',
    },
    waeth: {
      address: '0x7Ee64F74792c307446CD92D23E551EfAE3172A28',
    },
    walink: {
      address: '0x60b4B6e42b5F3cab771313D2169A00Fb5378Fb15',
    },
  },
  stakingPools: {
    address: '0x47e3492439528fEF29bc5Da55Aa49ED0EFA15c6E',
  },
  oldStakingPools: {
    address: '0x0EdA8090E9A86668484915e5E1856E83480FA010',
  },
  yvDai: {
    address: '0xda816459f1ab5631232fe5e97a05bbbb94970c95',
  },
  adapter: {
    address: '0x42cA31F3bC29cB3Ad21eBCfbbd168BA568aF13B6',
  },
  wasabiWETHLp: {
    address: '0x8f9ef75cd6e610dd8acf8611c344573032fb9c3d',
  },
  linkwalinkLp: {
    address: '0xfA7CE57A0C69367B4bB64c74bd464497e39cbE4e',
  },
  waUSD3CRV: {
    address: '0x9f6664205988c3bf4b12b851c075102714869535',
  },
  rewardVestings: [
    {
      address: '0x7E8Cb02F5Cf6F777a628F53c25B49c60A57642ee',
      token: 'WASABI',
    },
  ],
  votingEscrow: {
    address: '0xb938D8cBF7fc6455d1f2ebeDE5FF9A8e887493B2',
    MAX_LOCK_TIME: 1440,
    rewardTokens: [
      {
        address: '0x896e145568624a498c5a909187363AE947631503',
        token: 'WASABI',
        needVesting: false,
      },
    ],
  },
};
