import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useConnectWallet } from '../home/redux/hooks';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import FeeField from 'features/fee/FeeField';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { formatCountdown } from '../helpers/format';
import HarvestCard from './components/HarvestCard';
import FarmInfo from './components/FarmInfo';
import FarmExtra from './components/FarmExtra';
import {
  useCheckApproval,
  useFetchApproval,
  useFetchStake,
  useFetchPools,
  useFetchTeamPools,
  useFetchClaim,
  useFetchUnstake,
} from './redux/hooks';
import { contracts } from '../configure';
import { pools } from '../configure/pools';
import { useParams, Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  textValue: {
    fontWeight: 900,
    padding: '10px 0',
    fontSize: '0.95rem',
    color: theme.palette.text.primary,
  },
  contentText: {
    fontWeight: 700,
    paddingBottom: 20,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: 'hidden',
    borderRadius: 5,
    marginTop: 30,
    padding: 25,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'underline',
  },

  rightText: {
    float: 'right',
  },
}));
export default function PoolDetail() {
  const unlockDuration = 86400;
  const classes = useStyles();
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();

  const { poolId } = useParams();
  let filteredPool = pools.filter(pool => pool.pid == poolId);
  const pool = filteredPool[0];
  const { allowance, checkApproval } = useCheckApproval(poolId);
  const { fetchApproval } = useFetchApproval(poolId);
  const { fetchStake, fetchStakePending } = useFetchStake(poolId);
  const { fetchClaim, fetchClaimPending } = useFetchClaim(poolId);
  const { fetchUnstake, fetchUnstakePending } = useFetchUnstake(poolId);

  const { fetchPools, context } = useFetchPools();
  const { teamPoolsData, useFetchTeamPools } = useFetchPools();

  const [isNeedApproval, setIsNeedApproval] = useState(true);
  const [stakeAble, setStakeAble] = useState(true);
  const [claimAble, setClaimAble] = useState(true);
  const [unstakeAble, setUnstakeAble] = useState(true);

  const [depositBalance, setDepositBalance] = useState('');
  const [withdrawBalance, setWithdrawBalance] = useState('');

  const useContext = context[pool.pid];

  useEffect(() => {
    setIsNeedApproval(Boolean(allowance === 0));
  }, [allowance]);

  useEffect(() => {
    const walletBalance = useContext ? useContext['balance'] : 0;
    const hasZeroBalance = walletBalance == 0;
    setStakeAble(!Boolean(fetchStakePending || hasZeroBalance));
  }, [fetchStakePending, context]);

  useEffect(() => {
    setClaimAble(!Boolean(fetchClaimPending));
  }, [fetchClaimPending]);

  useEffect(() => {
    const stakedBalance = useContext ? useContext['yourStaked'] : 0;
    const hasZeroStaked = stakedBalance == 0;
    setUnstakeAble(!Boolean(fetchUnstakePending || hasZeroStaked));
  }, [fetchUnstakePending, context]);

  useEffect(() => {
    if (address) {
      checkApproval(poolId);
      fetchPools();
      const id = setInterval(() => {
        checkApproval(poolId);
        fetchPools();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const setMaximumInput = (maxBalance, setMax) => {
    if (!inputLimitPass(maxBalance, 18)) {
      return;
    }
    setMax(inputFinalVal(maxBalance, maxBalance, 18));
  };

  const onApproval = () => {
    fetchApproval(poolId);
  };

  const onStake = amount => {
    let amountValue = amount ? amount.replaceAll(',', '') : amount;
    const formated = new BigNumber(amountValue)
      .multipliedBy(new BigNumber(10).exponentiatedBy(pool.stakedTokenDecimals))
      .toString(10);
    fetchStake({
      address,
      web3,
      poolId,
      amount: formated,
    }).then(() => {
      setDepositBalance(false);
    });
  };
  const changeStakeBalanceInputValue = event => {
    let value = event.target.value;
    const total = availableDeposit;

    if (!inputLimitPass(value, 18)) {
      return;
    }
    setDepositBalance(inputFinalVal(value, total, 18));
  };
  const changeUnstakeBalanceInputValue = event => {
    let value = event.target.value;
    const total = staked;

    if (!inputLimitPass(value, 18)) {
      return;
    }
    setWithdrawBalance(inputFinalVal(value, total, 18));
  };

  const onHarvest = () => {
    fetchClaim({
      address,
      web3,
      poolId,
    });
  };

  const onUnstake = amount => {
    let amountValue = amount ? amount.replaceAll(',', '') : amount;
    const formated = new BigNumber(amountValue)
      .multipliedBy(new BigNumber(10).exponentiatedBy(pool.stakedTokenDecimals))
      .toString(10);

    fetchUnstake({
      address,
      web3,
      poolId,
      amount: formated,
    }).then(() => {
      setWithdrawBalance('');
    });
  };

  const staked = useContext ? useContext.yourStaked : '0';
  const availableDeposit = useContext ? useContext.balance : '0';

  const unlockTimestamp = useContext ? useContext.earlyWithdrawUnlockDate : '0';

  const renderAction = poolId => {
    if (poolId == 1) {
      return (
        <a
          href={'https://app.sushi.com/#/add/0x896e145568624a498c5a909187363ae947631503/ETH'}
          target="_blank"
        >
          <Button fullWidth color="green" style={{ marginTop: 10 }}>
            Get WASABI-ETH SLP here
          </Button>
        </a>
      );
    } else if (poolId == 2) {
      return (
        <a href={'https://crv.to/pool'} target="_blank">
          <Button fullWidth color="green" style={{ marginTop: 10 }}>
            Get waUSD3CRV-f here
          </Button>
        </a>
      );
    }
  };

  return (
    <>
      <Link to={'/app/farms'} className={classes.labelLink}>
        {'<< back to farms'}
      </Link>
      <div className={classes.card}>
        <div style={{ maxWidth: 800, margin: '0 auto' }}>
          <div className="vaultHeader" style={{ position: 'relative' }}>
            <img
              src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + '.png')}
              className={'lgIcon'}
              style={{ marginRight: 20 }}
            />
            {'Farm: ' + pool.stakedTokenSymbol}
          </div>
          <div className="vaultSubHeader">
            Stake your {pool.stakedTokenSymbol} tokens to earn {pool.rewardTokenSymbol}
          </div>
          <FarmInfo useContext={useContext} />
          {renderAction(poolId)}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className={classes.card}>
                <div className={classes.contentText}>
                  Available:
                  <span className={classes.rightText}>
                    {parseFloat(availableDeposit).toFixed(2)}
                  </span>
                </div>
                <div style={{ paddingBottom: 20 }}>
                  <CustomOutlinedInput
                    value={depositBalance}
                    onChange={changeStakeBalanceInputValue}
                    setMax={() => setMaximumInput(availableDeposit, setDepositBalance)}
                  />
                </div>
                {isNeedApproval ? (
                  <Button fullWidth color="green" onClick={onApproval} disabled={!Boolean(address)}>
                    {t('Approve')}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    color="green"
                    disabled={!Boolean(depositBalance) || depositBalance === '0'}
                    onClick={() => {
                      onStake(depositBalance);
                    }}
                  >
                    Deposit
                  </Button>
                )}
              </div>
              <div className={classes.card}>
                <div className={classes.contentText}>
                  Deposited:
                  <span className={classes.rightText}>{parseFloat(staked).toFixed(2)}</span>
                </div>
                <div style={{ paddingBottom: 20 }}>
                  <CustomOutlinedInput
                    value={withdrawBalance}
                    onChange={changeUnstakeBalanceInputValue}
                    setMax={() => setMaximumInput(staked, setWithdrawBalance)}
                  />
                </div>
                <Button
                  fullWidth
                  color="green"
                  disabled={!Boolean(withdrawBalance) || withdrawBalance === '0'}
                  onClick={() => {
                    onUnstake(withdrawBalance);
                  }}
                >
                  Withdraw
                </Button>
                {/* <div>{unlockTimestamp > unlockDuration && staked > 0 ? `Early withdraw ends in: ${formatCountdown(unlockTimestamp * 1000)}` : ''}</div> */}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ marginTop: 30 }}>
                <HarvestCard useContext={useContext} pool={pool} onClick={onHarvest} />
              </div>
            </Grid>
          </Grid>
          <div className={classes.textValue} style={{ padding: '10px 20px', marginTop: 30 }}>
            Farm Information
          </div>
          <div style={{ padding: 20 }}>
            <FarmExtra pool={pool} useContext={useContext} pool={pool} />
          </div>
          <FeeField type="farm" />
        </div>
      </div>
    </>
  );
}
