import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PRICE_BEGIN, FETCH_PRICE_SUCCESS, FETCH_PRICE_FAILURE } from './constants';

import sushiData from '@sushiswap/sushi-data';
import { erc20ABI, tokens } from 'features/configure';

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const contract = new web3.eth.Contract(erc20ABI, tokens.wasabi.address);

      Promise.all([
        sushiData.exchange.ethPrice({}),
        sushiData.exchange.token({ token_address: '0x896e145568624a498c5a909187363AE947631503' }),
        sushiData.exchange.pair({ pair_address: '0x8f9ef75cd6e610dd8acf8611c344573032fb9c3d' }),
        contract.methods.totalSupply().call(),
        sushiData.exchange.token({ token_address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599' }),
        sushiData.exchange.token({ token_address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' }),
        sushiData.exchange.token({ token_address: '0x514910771af9ca656af840dff83e8264ecf986ca' }),
      ])
        .then(data => {
          dispatch({
            type: FETCH_PRICE_SUCCESS,
            data,
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData,
  };
}

export function reducer(state = { fetchPricePending: false, priceData: [] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_SUCCESS:
      const wasabiPrice = action.data[0] * action.data[1].derivedETH;
      const wasabiCirculatingSupply = action.data[3];
      const wasabiCMC = wasabiCirculatingSupply * wasabiPrice;
      const wbtcPrice = action.data[0] * action.data[4].derivedETH;
      const wethPrice = action.data[0] * action.data[5].derivedETH;
      const linkPrice = action.data[0] * action.data[6].derivedETH;
      const lpData = action.data[2];
      const lpTotalSupply = lpData && lpData.totalSupply ? lpData.totalSupply : 1;
      const reserve0 = lpData && lpData.reserve0 ? lpData.reserve0 : 0;
      return {
        ...state,
        priceData: {
          wasabiPrice,
          wasabiCirculatingSupply,
          wasabiCMC,
          wbtcPrice,
          wethPrice,
          linkPrice,
          lpData,
          lpTotalSupply,
          reserve0,
        },
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending,
        },
      };

    default:
      return state;
  }
}
