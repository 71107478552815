import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  useFetchOpenTrove,
  useFetchAdjustTrove,
  useFetchCurrentTrove,
  useFetchCloseTrove
} from 'features/vault/redux/hooks';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useSnackbar } from 'notistack';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import LiquityStatistics from './LiquityStatistics';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconToolTip from 'components/ToolTip/IconToolTip';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { tokens } from 'features/configure';
import { LUSD_MINIMUM_DEBT, LUSD_LIQUIDATION_RESERVE, Decimal } from "@liquity/lib-base";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`trove-tabpanel-${index}`}
      aria-labelledby={`trove-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `trove-tab-${index}`,
    'aria-controls': `trove-tabpanel-${index}`
  };
}

function DepositTrove(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { web3, address, networkId } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const decimals = tokens[tokenId].decimals;

  const { fetchCurrentTrove, fetchCurrentTrovePending, currentTrove } = useFetchCurrentTrove();

  const { fetchOpenTrove, fetchOpenTrovePending, } = useFetchOpenTrove();
  const [depositBalance, setDepositBalance] = useState(0);
  const [borrowBalance, setBorrowBalance] = useState(0);
  const [invalidDebt, setInvalidDebt] = useState(false);
  const [invalidRatio, setInvalidRatio] = useState(false);
  const [dangerousRatio, setDangerousRatio] = useState(false);
  const [currentCollateral, setCurrentCollateral] = useState(Decimal.from(0));
  const [currentDebt, setCurrentDebt] = useState(Decimal.from(0));
  const [ethPrice, setEthPrice] = useState(Decimal.from(0));
  const [ethBalance, setEthBalance] = useState(Decimal.from(0));
  const [borrowFee, setBorrowFee] = useState(Decimal.from(0));
  const [troveStatus, setTroveStatus] = useState('nonExistent');
  const [dRatio, setDRatio] = useState(Decimal.from(0));

  useEffect(() => {
    setCurrentCollateral(currentTrove.collateral);
    setCurrentDebt(currentTrove.debt);
    setEthPrice(currentTrove.ethPrice);
    setTroveStatus(currentTrove.status);
    setBorrowFee(currentTrove.borrowingFee ? currentTrove.borrowingFee : Decimal.from(0));
    setEthBalance(currentTrove.ethBalance ? currentTrove.ethBalance : Decimal.from(0));
  }, [currentTrove]);

  useEffect(() => {
    if (!ethPrice) {
      setDRatio(Decimal.from(0))
    } else {

      let depositBalanceNum = Decimal.from(depositBalance ? depositBalance : 0);
      let borrowBalanceNum = Decimal.from(borrowBalance ? borrowBalance : 0);

      if (borrowBalanceNum.add(currentDebt).eq(0)) {
        setDRatio(Decimal.from(0))
      } else {
        setDRatio(ethPrice.mul(depositBalanceNum.add(currentCollateral)).div(borrowBalanceNum.add(currentDebt)));
      }
    }
  }, [currentTrove, depositBalance, borrowBalance]);

  useEffect(() => {
    let depositBalanceNum = Decimal.from(depositBalance ? depositBalance : 0);
    let borrowBalanceNum = Decimal.from(borrowBalance ? borrowBalance : 0);
    if (depositBalanceNum == 0 && borrowBalanceNum == 0) {
      setInvalidRatio(false);
      setDangerousRatio(false);
    } else {
      setInvalidRatio(dRatio.lt(1.1));
      setDangerousRatio(dRatio.lt(2.5));
    }
  }, [dRatio]);

  useEffect(() => {
    if (address) {
      fetchCurrentTrove({ web3, address });

      const id = setInterval(() => {
        fetchCurrentTrove({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const onBorrow = () => {
    fetchOpenTrove({
      web3,
      address,
      depositETH: depositBalance,
      borrowLUSD: calculateWillGetAmount(),
      tokenId, strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Trove-DepositBorrowSuccess'), { variant: 'success' });
        setBorrowBalance(0);
        setDepositBalance(0);
        setInvalidDebt(false);
      })
      .catch(error => enqueueSnackbar(t('Trove-DepositBorrowError', { error }), { variant: 'error' }));
  };

  const setDepositMaximumInput = event => {
    let value = parseFloat(Decimal.fromBigNumberString(ethBalance.toString()).toString());
    if (!value) {
      return;
    }
    setDepositBalance(value);
  };

  const changeDepositInputValue = event => {
    let value = event.target.value;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let valueNum = parseFloat(value);
    let total = parseFloat(Decimal.fromBigNumberString(ethBalance.toString()).toString());

    let inputVal = 0;
    if (value) {
      value = Number(value.replaceAll(',', ''));
      inputVal = total > valueNum ? valueNum : total;
    }

    setDepositBalance(inputVal);

    if (troveStatus != 'open' && borrowBalance < Number(LUSD_MINIMUM_DEBT)) {
      setBorrowBalance(Number(LUSD_MINIMUM_DEBT));
    }
  };

  const changeBorrowInputValue = event => {

    let value = event.target.value;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
    }

    setBorrowBalance(inputVal);

    if (depositBalance != 0 && inputVal < LUSD_MINIMUM_DEBT && troveStatus != 'open') {
      setInvalidDebt(true);
    } else {
      setInvalidDebt(false);
    }

  };

  const renderRatioCircular = (currentCollateral, currentDebt, ethPrice) => {

    if (currentCollateral > 0 && currentDebt > 0 && ethPrice) {
      let ratioInverse = parseFloat(Decimal.from(110).div(currentCollateral.mul(ethPrice).div(currentDebt).mul(100)));
      let normalizedValue = ratioInverse * 100;

      if (normalizedValue > 100) {
        normalizedValue = 100
      }

      let color = '#00CFB4';

      if (normalizedValue > 75) {
        color = '#F2A900'
      }

      if (normalizedValue > 90) {
        color = '#F93822'
      }

      return (

        <Box position="relative" display="inline-flex">
          <div style={{ position: 'relative' }}>
            <CircularProgress variant="determinate" size={120} value={100} style={{ color: 'white' }} />
            <CircularProgress variant="determinate" size={120} value={normalizedValue} style={{
              position: 'absolute',
              left: 0, color: color
            }} />

          </div>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <div style={{ color: 'white' }}>C-Ratio:{parseFloat(currentCollateral.mul(ethPrice).div(currentDebt).mul(100).toString(2))}%</div>
          </Box>
        </Box>
      )
    } else {
      return (
        <Box position="relative" display="inline-flex">
          <div style={{ position: 'relative' }}>
            <CircularProgress variant="determinate" size={120} value={100} style={{ color: 'gray' }} />
            <CircularProgress variant="determinate" size={120} value={0} style={{
              position: 'absolute',
              left: 0, color: 'gray'
            }} />

          </div>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">C-Ratio:-</Typography>
          </Box>
        </Box>
      )
    }
  }

  const renderTroveStatus = (currentCollateral, currentDebt, ethPrice) => {
    if (troveStatus == 'open') {
      return (
        <Grid container item justify="center" style={{ textAlign: 'center' }}>
          <Grid item xs={4} className={classes.showDetailLeft}>
            <h3 className={classes.detailHeader}>Collateral:</h3>
            <div className={classes.detailContent}>{currentCollateral ? `${currentCollateral.toString()} ETH (${currentCollateral.mul(ethPrice).toString(2)} USD)` : '-'}</div>
          </Grid>
          <Grid item xs={4} className={classes.showDetailLeft}>
            {renderRatioCircular(currentCollateral, currentDebt, ethPrice)}
          </Grid>
          <Grid item xs={4} className={classes.showDetailLeft}>
            <h3>Debt:</h3><div>{currentDebt ? `${currentDebt.toString(2)} LUSD` : '-'}</div>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container item justify="center" style={{ textAlign: 'center' }}>
          <Grid item xs={4} className={classes.showDetailLeft}>
            <h3 className={classes.detailHeader}>Collateral:</h3>
            <div className={classes.detailContent}>-</div>
          </Grid>
          <Grid item xs={4} className={classes.showDetailLeft}>
            {renderRatioCircular(currentCollateral, currentDebt, ethPrice)}
          </Grid>
          <Grid item xs={4} className={classes.showDetailLeft}>
            <h3 className={classes.detailHeader}>Debt:</h3>
            <div className={classes.detailContent}>-</div>
          </Grid>
        </Grid>
      )
    }
  }

  const actionButtonEnable = () => {
    if (troveStatus === 'open') {
      if (!currentCollateral || !currentDebt) {
        return false;
      }

      let inputCheck = Boolean(depositBalance) || Boolean(borrowBalance);
      let ratioValid = dRatio.gte(1.1);
      return ratioValid && inputCheck;
    } else {

      let inputCheck = Boolean(depositBalance) || Boolean(borrowBalance);
      let ratioValid = dRatio.gte(1.1);
      let debtCheck = parseFloat(borrowBalance) >= 2000;

      return inputCheck && ratioValid && debtCheck;
    }
  }

  const calculateWillGetAmount = () => {
    if (!depositBalance && !borrowBalance) {
      return 0;
    }

    let outputAmount = Decimal.from(0);

    if (troveStatus == 'open') {
      outputAmount = Decimal.from(borrowBalance).div(Decimal.ONE.add(borrowFee));
    } else {
      if (borrowBalance < 2000) {
        outputAmount = Decimal.from(0);
      } else if (borrowBalance == 2000) {
        outputAmount = (Decimal.from(borrowBalance)).sub(LUSD_LIQUIDATION_RESERVE).div(Decimal.ONE.add(borrowFee)).add(Decimal.ONE);
      } else {
        outputAmount = (Decimal.from(borrowBalance)).sub(LUSD_LIQUIDATION_RESERVE).div(Decimal.ONE.add(borrowFee));
      }
    }
    return outputAmount.toString();
  }

  const calculateFeeAmount = () => {
    let outputAmount = calculateWillGetAmount();
    return Decimal.from(outputAmount).mul(borrowFee).toString();
  }

  return (
    <div>
      <IconToolTip>
        Wasabix's Liquity frontend is 100% kickback rate. This is the place where you can deposit ETH to borrow LUSD, you may borrow up to c-ratio 110%. You are interacting with Liquity's smart contracts directly. For more details please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a>
      </IconToolTip>
      <Container maxWidth="sm" disableGutters={true}>

        {renderTroveStatus(currentCollateral, currentDebt, ethPrice)}

        <div className={classes.showDetailLeft}>
          <h3 className={classes.detailHeader}>Deposit ETH in order to borrow LUSD</h3>
          <div className={classes.detailContent}>Deposit ETH (Balance: {ethBalance ? Decimal.fromBigNumberString(ethBalance.toString()).toString(4) : 0})</div>
        </div>
        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={depositBalance} onChange={changeDepositInputValue} setMax={setDepositMaximumInput} />
        </FormControl>
        <div className={classes.detailContent} style={{ marginTop: 20 }}>
          Borrow LUSD (Minimum debt: 2000 LUSD, Minimum C-Ratio: 110%)
        </div>
        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={borrowBalance} onChange={changeBorrowInputValue} />
        </FormControl>
        <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item xs={6} className={classes.detailContent}>
            Collateral Ratio
            <Tooltip
              placement="right"
              interactive
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    The ratio between the dollar value of the collateral and the debt (in LUSD) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon style={{ fontSize: "0.95rem" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6} className={classes.detailContent}>{dRatio ? dRatio.mul(100).toString(2) : '-'} %</Grid>
          {troveStatus == 'open' ? <></> : <>
            <Grid item xs={6} className={classes.detailContent}>
              Liquidation Reserve
              <Tooltip
                placement="right"
                interactive
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      An amount set aside to cover the liquidator’s gas costs if your Trove needs to be liquidated. The amount increases your debt and is refunded if you close your Trove by fully paying off its net debt.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton>
                  <HelpIcon style={{ fontSize: "0.95rem" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} className={classes.detailContent}>{parseInt(LUSD_LIQUIDATION_RESERVE)} {tokens[tokenId].token}</Grid>
          </>}
          <Grid item xs={6} className={classes.detailContent}>
            Borrowing Fee
            <Tooltip
              placement="right"
              interactive
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    This amount is deducted from the borrowed amount as a one-time fee. There are no recurring fees for borrowing, which is thus interest-free.
                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon style={{ fontSize: "0.95rem" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6} className={classes.detailContent}>{parseFloat(calculateFeeAmount()).toFixed(2)} ({borrowFee ? borrowFee.mul(100).toString(2) + '%' : '-'})</Grid>
          <Grid item xs={6} className={classes.detailContent}>
            You will get
            <Tooltip
              placement="right"
              interactive
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    {troveStatus == 'open' ? 'Borrow Amount - Borrowing Fee' : 'Borrow Amount - (Liquidation Reserve + Borrowing Fee)'}
                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon style={{ fontSize: "0.95rem" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6} className={classes.detailContent}>{parseFloat(calculateWillGetAmount()).toFixed(2)}</Grid>
        </Grid>
        {invalidDebt ?
          <>
            <Alert severity="error">Debt must be at least {Number(LUSD_MINIMUM_DEBT)} LUSD.</Alert><br />
          </>
          : ''}
        {invalidRatio ?
          <>
            <Alert severity="error">Collateral ratio must be at least 110%.</Alert><br />
          </>
          : ''}
        {dangerousRatio ?
          <Alert severity="info" style={{ marginBottom: 20 }}>Important - You may lose all your collateral when facing flash crash/low Collateral ratio. A Collateral Ratio above 200% or 250% is recommended for additional safety.</Alert>
          : ''}
        <div className={classes.detailContent}>
          Please make sure you've read Liquity's <a href="https://docs.liquity.org/faq/borrowing#what-happens-if-my-trove-is-liquidated" target="_blank">guideline</a> before minting LUSD.
        </div>
        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={!Boolean(actionButtonEnable())}
            onClick={() => onBorrow()}
          >
            {t('Confirm')}
          </Button>
        </div>
        <LiquityStatistics />
      </Container>
    </div>
  )
}

function WithdrawTrove(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const decimals = tokens[tokenId].decimals;

  const { fetchCurrentTrove, fetchCurrentTrovePending, currentTrove } = useFetchCurrentTrove();
  const { fetchAdjustTrove, fetchAdjustTrovePending } = useFetchAdjustTrove();
  const [invalidDebt, setInvalidDebt] = useState(false);
  const [invalidRatio, setInvalidRatio] = useState(false);
  const [dangerousRatio, setDangerousRatio] = useState(false);
  const [currentCollateral, setCurrentCollateral] = useState(Decimal.from(0));
  const [currentDebt, setCurrentDebt] = useState(Decimal.from(0));
  const [currentNetDebt, setCurrentNetDebt] = useState(Decimal.from(0));
  const [ethPrice, setEthPrice] = useState(Decimal.from(0));
  const [lUSDBalance, setLUSDBalance] = useState(Decimal.from(0));
  const [dRatio, setDRatio] = useState(Decimal.from(0));
  const [withdrawBalance, setWithdrawBalance] = useState(0);
  const [repayBalance, setRepayBalance] = useState(0);
  const [troveStatus, setTroveStatus] = useState('nonExistent');

  const onWithdrawOrRepay = () => {
    fetchAdjustTrove({
      web3,
      address,
      withdrawETH: withdrawBalance,
      repayLUSD: repayBalance,
      tokenId, strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Trove-WithdrawRepaySuccess'), { variant: 'success' });
        setWithdrawBalance(0);
        setRepayBalance(0);
      })
      .catch(error => enqueueSnackbar(t('Trove-WithdrawRepayError', { error }), { variant: 'error' }));
  };

  useEffect(() => {
    setCurrentCollateral(currentTrove.collateral);
    setCurrentDebt(currentTrove.debt);
    setCurrentNetDebt(currentTrove.netDebt);
    setEthPrice(currentTrove.ethPrice);
    setTroveStatus(currentTrove.status);
    setLUSDBalance(currentTrove.lUSDBalance ? currentTrove.lUSDBalance : Decimal.from(0));
  }, [currentTrove]);

  useEffect(() => {
    if (!ethPrice) {
      setDRatio(Decimal.from(0))
    } else {
      let withdrawBalanceNum = Decimal.from(withdrawBalance ? withdrawBalance : 0);
      let repayBalanceNum = Decimal.from(repayBalance ? repayBalance : 0);

      if (currentDebt.sub(repayBalanceNum).eq(0)) {
        setDRatio(Decimal.from(0))
      } else {
        setDRatio(ethPrice.mul(currentCollateral.sub(withdrawBalanceNum)).div(currentDebt.sub(repayBalanceNum)));
      }
    }
  }, [currentTrove, withdrawBalance, repayBalance]);

  useEffect(() => {
    let withdrawBalanceNum = Decimal.from(withdrawBalance ? withdrawBalance : 0);
    let repayBalanceNum = Decimal.from(repayBalance ? repayBalance : 0);
    if (withdrawBalanceNum == 0 && repayBalanceNum == 0) {
      setInvalidRatio(false);
      setDangerousRatio(false);
    } else {
      setInvalidRatio(dRatio.lt(1.1));
      setDangerousRatio(dRatio.lt(2.5));
    }
  }, [dRatio]);

  useEffect(() => {
    if (address) {
      fetchCurrentTrove({ web3, address });

      const id = setInterval(() => {
        fetchCurrentTrove({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const actionButtonEnable = () => {
    if (troveStatus === 'open') {
      if (!currentCollateral || !currentDebt) {
        return false;
      }

      let inputCheck = Boolean(withdrawBalance) || Boolean(repayBalance);
      let ratioValid = dRatio.gte(1.1);
      return ratioValid && inputCheck;
    } else {
      return false;
    }
  }

  const changeWithdrawInputValue = event => {
    let value = event.target.value;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let valueNum = parseFloat(value);
    let total = parseFloat(currentCollateral.toString());

    let inputVal = 0;
    if (value) {
      value = Number(value.replaceAll(',', ''));
      inputVal = total > valueNum ? valueNum : total;
    }

    setWithdrawBalance(inputVal);
  }

  const setWithdrawMaximumInput = event => {
    let value = parseFloat(currentCollateral.toString());
    if (!value) {
      return;
    }
    setWithdrawBalance(value);
  }

  const changeRepayInputValue = event => {
    let value = event.target.value;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let valueNum = parseFloat(value);
    let total = parseFloat(lUSDBalance.toString());
    let repayableAmt = getRepayableAmount();

    if (valueNum > repayableAmt) {
      setInvalidDebt(true);
    } else {
      setInvalidDebt(false);
    }

    let inputVal = 0;
    if (value) {
      value = Number(value.replaceAll(',', ''));
      inputVal = total > valueNum ? valueNum : total;
    }

    setRepayBalance(inputVal);
  }

  const getRepayableAmount = () => {
    // Trove's net debt must be greater than minimum
    const currentDebtNum = parseFloat(currentNetDebt.toString());
    const debtMinNum = parseFloat(LUSD_MINIMUM_DEBT.toString());
    return currentDebtNum > debtMinNum ? currentDebtNum - debtMinNum : 0;
  }

  const setRepayMaximumInput = event => {
    let value = getRepayableAmount();
    setRepayBalance(value);
  }

  return (
    <div>
      <IconToolTip>
        Wasabix's Liquity frontend is 100% kickback rate. This is the place where you can deposit ETH to borrow LUSD, you may borrow up to c-ratio 110%. You are interacting with Liquity's smart contracts directly. For more details please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a>
      </IconToolTip>
      <Container maxWidth="sm" disableGutters={true}>
        <div className={classes.showDetailLeft}>
          <h3 className={classes.detailHeader}>Withdraw ETH and/or repay LUSD</h3>
          <div className={classes.detailContent}>Withdraw ETH (Available: {currentCollateral ? currentCollateral.toString() : 0})</div>
        </div>
        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={withdrawBalance} onChange={changeWithdrawInputValue} setMax={setWithdrawMaximumInput} />
        </FormControl>
        <div style={{ marginTop: 20 }}>
          <div className={classes.detailContent}>Repay LUSD (Balance: {lUSDBalance ? parseFloat(lUSDBalance.toString()).toFixed(2) : 0})</div>
          <div className={classes.detailContent}>Current Debt: {currentDebt ? currentDebt.toString(2) : 0}</div>
        </div>
        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={repayBalance} onChange={changeRepayInputValue} setMax={setRepayMaximumInput} />
        </FormControl>
        <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item xs={6} className={classes.detailContent}>
            Collateral Ratio
            <Tooltip
              placement="right"
              interactive
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    The ratio between the dollar value of the collateral and the debt (in LUSD) you are depositing. While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.                  </Typography>
                </React.Fragment>
              }
            >
              <IconButton>
                <HelpIcon style={{ fontSize: "0.95rem" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6} className={classes.detailContent}>{dRatio ? dRatio.mul(100).toString(2) : '-'} %</Grid>
        </Grid>
        {invalidDebt ?
          <>
            <Alert severity="error" style={{ marginTop: 20 }}>Debt must be at least {Number(LUSD_MINIMUM_DEBT)} LUSD.</Alert><br />
          </>
          : ''}
        {invalidRatio ?
          <>
            <Alert severity="error" style={{ marginTop: 20 }}>Collateral ratio must be at least 110%.</Alert><br />
          </>
          : ''}
        {dangerousRatio ?
          <Alert severity="info" style={{ marginTop: 20 }}>
            You might lose all your collateral when facing a flash crash with low Collateral ratio. A Collateral Ratio above 200% or 250% is recommended for additional safety.
          </Alert>
          : ''}
        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={
              ((!Boolean(withdrawBalance) || withdrawBalance === '0') && (!Boolean(repayBalance) || repayBalance === '0'))
              || !Boolean(actionButtonEnable())
            }
            onClick={() => onWithdrawOrRepay()}
          >
            {t('Confirm')}
          </Button>
        </div>
        <LiquityStatistics />
      </Container>
    </div>
  )
}

function CloseTrove(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();

  const { fetchCurrentTrove, currentTrove } = useFetchCurrentTrove();
  const { fetchCloseTrove } = useFetchCloseTrove();

  const [currentCollateral, setCurrentCollateral] = useState(Decimal.from(0));
  const [currentDebt, setCurrentDebt] = useState(Decimal.from(0));
  const [closable, setClosable] = useState(true);
  const [lUSDBalance, setLUSDBalance] = useState(Decimal.from(0));

  const onClose = () => {
    fetchCloseTrove({
      web3,
      tokenId, strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Trove-CloseSuccess'), { variant: 'success' });
      })
      .catch(error => enqueueSnackbar(t('Trove-CloseError', { error }), { variant: 'error' }));
  };

  useEffect(() => {
    setCurrentCollateral(currentTrove.collateral);
    setCurrentDebt(currentTrove.debt);
    setLUSDBalance(currentTrove.lUSDBalance ? currentTrove.lUSDBalance : Decimal.from(0));
  }, [currentTrove]);

  useEffect(() => {
    if (address) {
      fetchCurrentTrove({ web3, address });

      const id = setInterval(() => {
        fetchCurrentTrove({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  useEffect(() => {
    if (lUSDBalance.lt(currentDebt)) {
      setClosable(false);
    }
  }, [currentTrove, currentDebt, lUSDBalance]);

  return (
    <div>
      <IconToolTip>
        Wasabix's Liquity frontend is 100% kickback rate. This is the place where you can deposit ETH to borrow LUSD, you may borrow up to c-ratio 110%. You are interacting with Liquity's smart contracts directly. For more details please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a>
      </IconToolTip>
      <Container maxWidth="sm" disableGutters={true}>

        <div className={classes.showDetailLeft}>
          <h3>Repay all remaining debt and withdraw all ETH collateral</h3>
        </div>
        {closable ?
          <>
            <Grid container item justify="center">
              <Grid item xs={6} className={classes.showDetailLeft}>
                Repay
              </Grid>
              <Grid item xs={6} className={classes.showDetailLeft}>{currentDebt ? parseFloat(currentDebt.toString()).toFixed(2) : 0.00} LUSD</Grid>
            </Grid>
            <Grid container item justify="center">
              <Grid item xs={6} className={classes.showDetailLeft}>
                Withdraw
              </Grid>
              <Grid item xs={6} className={classes.showDetailLeft}>{currentCollateral ? parseFloat(currentCollateral.toString()).toFixed(2) : 0.00} ETH</Grid>
            </Grid>
          </>
          :
          <>
            <Grid container item justify="center">
              <Grid item xs={6} className={classes.showDetailLeft}>
                Current Debt:
              </Grid>
              <Grid item xs={6} className={classes.showDetailLeft}>
                {currentDebt ? parseFloat(currentDebt.toString()).toFixed(2) : 0.00} LUSD
              </Grid>
            </Grid>
            <Grid container item justify="center">
              <Grid item xs={6} className={classes.showDetailLeft}>
                LUSD Balance:
              </Grid>
              <Grid item xs={6} className={classes.showDetailLeft}>
                {lUSDBalance ? parseFloat(lUSDBalance.toString()).toFixed(2) : 0.00} LUSD
              </Grid>
            </Grid>
            <Alert severity="warning">Make sure enough LUSD before closing trove!</Alert>
          </>
        }

        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={(!Boolean(closable))}
            onClick={() => onClose()}
          >
            {t('Confirm')}
          </Button>
        </div>
        <LiquityStatistics />
      </Container>
    </div>
  )
}

export default function Trove(props) {

  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    tabs: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    tab: {
      minWidth: '20%',
      '& .MuiTab-wrapper': {
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
    }
  }));
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const [value, setValue] = React.useState(0);
  const { fetchCurrentTrove, currentTrove } = useFetchCurrentTrove();
  const [troveStatus, setTroveStatus] = useState('nonExistent');

  useEffect(() => {
    setTroveStatus(currentTrove.status);
  }, [currentTrove]);

  useEffect(() => {
    if (address) {
      fetchCurrentTrove({ web3, address });

      const id = setInterval(() => {
        fetchCurrentTrove({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.sliderDetailContainer} >
      <Tabs value={value} onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="on">
        <Tab className={classes.tab} label={t('Deposit(ETH)')} {...a11yProps(0)} />
        <Tab className={classes.tab} label={t('Withdraw')} {...a11yProps(1)} />
        {troveStatus == 'open' ?
          <Tab className={classes.tab} label={t('Close')} {...a11yProps(2)} />
          : ''}
      </Tabs>
      <TabPanel value={value} index={0}>
        <DepositTrove {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WithdrawTrove {...props} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CloseTrove {...props} />
      </TabPanel>
    </div>
  );
}
