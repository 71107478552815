import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_LOCKER_WITHDRAW_BEGIN,
  FETCH_LOCKER_WITHDRAW_SUCCESS,
  FETCH_LOCKER_WITHDRAW_FAILURE,
} from './constants';
import { lockerWithdraw } from '../../web3';
import { fetchVotingEscrowDetails } from './action';

export function fetchLockerWithdraw({ address, web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_LOCKER_WITHDRAW_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      lockerWithdraw({ web3, address, dispatch })
        .then(data => {
          dispatch({
            type: FETCH_LOCKER_WITHDRAW_SUCCESS,
            data,
          });
          dispatch(fetchVotingEscrowDetails());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_LOCKER_WITHDRAW_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchLockerWithdraw() {
  const dispatch = useDispatch();

  const { fetchLockerWithdrawPending } = useSelector(state => ({
    fetchLockerWithdrawPending: state.pools.fetchLockerWithdrawPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchLockerWithdraw(data));
    },
    [dispatch]
  );

  return {
    fetchLockerWithdraw: boundAction,
    fetchLockerWithdrawPending,
  };
}

export function reducer(state, action) {
  let { fetchLockerWithdrawPending } = state;
  switch (action.type) {
    case FETCH_LOCKER_WITHDRAW_BEGIN:
      fetchLockerWithdrawPending = true;
      return {
        ...state,
        fetchLockerWithdrawPending
      };

    case FETCH_LOCKER_WITHDRAW_SUCCESS:
      fetchLockerWithdrawPending = false;
      return {
        ...state,
        fetchLockerWithdrawPending
      };

    case FETCH_LOCKER_WITHDRAW_FAILURE:
      fetchLockerWithdrawPending = false;
      return {
        ...state,
        fetchLockerWithdrawPending
      };

    default:
      return state;
  }
}
