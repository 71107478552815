import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_DEPOSIT_BEGIN,
  VAULT_FETCH_DEPOSIT_SUCCESS,
  VAULT_FETCH_DEPOSIT_FAILURE,
} from './constants';
import { deposit } from '../../web3';
import { fetchVaultDetail } from './action';

export function fetchDeposit({ address, web3, amount, tokenId, strategyId, isETH }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_DEPOSIT_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      deposit({ web3, address, amount, tokenId, strategyId, isETH, dispatch })
        .then(data => {
          dispatch({
            type: VAULT_FETCH_DEPOSIT_SUCCESS,
            data
          });
          dispatch(fetchVaultDetail(tokenId, strategyId))
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_DEPOSIT_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  }
}

export function useFetchDeposit() {
  const dispatch = useDispatch();

  const { fetchDepositPending } = useSelector(
    state => ({
      fetchDepositPending: state.vault.fetchDepositPending,
    })
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchDeposit(data));
    },
    [dispatch]
  );

  return {
    fetchDeposit: boundAction,
    fetchDepositPending
  };
}

export function reducer(state, action) {
  let { fetchDepositPending } = state;
  switch (action.type) {
    case VAULT_FETCH_DEPOSIT_BEGIN:
      // Just after a request is sent
      fetchDepositPending = true;
      return {
        ...state,
        fetchDepositPending
      };

    case VAULT_FETCH_DEPOSIT_SUCCESS:
      // The request is success
      fetchDepositPending = false;
      return {
        ...state,
        fetchDepositPending
      };

    case VAULT_FETCH_DEPOSIT_FAILURE:
      // The request is failed
      fetchDepositPending = false;
      return {
        ...state,
        fetchDepositPending
      };

    default:
      return state;
  }
}
