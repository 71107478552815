import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DEPRECATED_POOLS_FETCH_BEGIN,
  DEPRECATED_POOLS_FETCH_SUCCESS,
  DEPRECATED_POOLS_FETCH_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { erc20ABI, stakingPoolsABI, tokens, contracts, deprecatedPools } from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

export function fetchDeprecatedPools() {
  return (dispatch, getState) => {
    dispatch({
      type: DEPRECATED_POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const calls = [];
      for (let pool of deprecatedPools) {
        const stakingPoolsContract = new web3.eth.Contract(stakingPoolsABI, pool.poolAddress);
        calls.push({
          result: stakingPoolsContract.methods.getStakeTotalDeposited(address, pool.pid),
        });
      }

      multicall
        .all([calls])
        .then(([results]) => {
          const output = {};
          for (let index = 0; index < deprecatedPools.length; index++) {
            let pool = deprecatedPools[index];
            output[pool.pid] = {
              yourStaked: convertAmountFromRawNumber(
                new BigNumber(results[index].result),
                pool.stakedTokenDecimals
              ),
            };
          }
          dispatch({
            type: DEPRECATED_POOLS_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: DEPRECATED_POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchDeprecatedPools() {
  const dispatch = useDispatch();

  const { deprecatedPoolContext, fetchDeprecatedPoolsPending, fetchDeprecatedPoolsDone } =
    useSelector(
      state => ({
        deprecatedPoolContext: state.pools.deprecatedPoolContext,
        fetchDeprecatedPoolsPending: state.pools.fetchDeprecatedPoolsPending,
        fetchDeprecatedPoolsDone: state.pools.fetchDeprecatedPoolsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchDeprecatedPools(data));
    },
    [dispatch]
  );

  return {
    deprecatedPoolContext,
    fetchDeprecatedPools: boundAction,
    fetchDeprecatedPoolsDone,
    fetchDeprecatedPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DEPRECATED_POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchDeprecatedPoolsPending: true,
      };

    case DEPRECATED_POOLS_FETCH_SUCCESS:
      return {
        ...state,
        deprecatedPoolContext: action.data,
        fetchDeprecatedPoolsDone: true,
        fetchDeprecatedPoolsPending: false,
      };

    case DEPRECATED_POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchDeprecatedPoolsPending: false,
      };

    default:
      return state;
  }
}
