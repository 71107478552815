export const tokens = {
  dai: {
    token: 'DAI',
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    decimals: 18,
    correspondingMintableToken: 'waUSD',
  },
  wausd: {
    token: 'waUSD',
    address: '0xc2db4c131adaf01c15a1db654c040c8578929d55',
    decimals: 18,
    baseToken: 'DAI',
  },
  wbtc: {
    token: 'wBTC',
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    decimals: 8,
    correspondingMintableToken: 'waBTC',
  },
  lusd: {
    token: 'LUSD',
    address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    decimals: 18,
    correspondingMintableToken: 'waLUSD',
  },
  walusd: {
    token: 'waLUSD',
    address: '0xcbf335Bb8eE86A5A88bEbCda4506a665aA8d7022',
    decimals: 18,
    baseToken: 'LUSD',
  },
  wabtc: {
    token: 'waBTC',
    address: '0xfd8e70e83e399307db3978d3f34b060a06792c36',
    decimals: 8,
    baseToken: 'wBTC',
  },
  wasabi: {
    token: 'WASABI',
    address: '0x896e145568624a498c5a909187363AE947631503',
    decimals: 18,
  },
  weth: {
    token: 'WETH',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
    correspondingMintableToken: 'waETH',
  },
  waeth: {
    token: 'waETH',
    address: '0x6a1fbefdF67445C7F531b4F3e04Ffb37b7b13794',
    decimals: 18,
    baseToken: 'WETH',
  },
  wit: {
    token: 'WIT',
    address: '0x80bdf509dd4f8114cb05e2ff166a100d3499ef7c',
    decimals: 18,
  },
  wvvt: {
    token: 'WVVT',
    address: '0xb457b400B817898Efe60a614FDBCdF6626026293',
    decimals: 18,
  },
  wizt: {
    token: 'wizt',
    address: '0x805C76c90D4Af4c0a1e32DFa90B0B73Ea8B459A2',
    decimals: 18,
  },
  link: {
    token: 'LINK',
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    decimals: 18,
    correspondingMintableToken: 'waLINK',
  },
  walink: {
    token: 'waLINK',
    address: '0x29DDF2c4F1E80DC9E95CC57a9cA03a28E4aB6D6c',
    decimals: 18,
  },
};
