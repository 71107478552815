import { EthersLiquity } from "@liquity/lib-ethers";

const ethers = require('ethers');

export const getTroveStatus = async ({ web3, address }) => {
  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const signer = provider.getSigner();
  const liquity = await EthersLiquity.connect(signer);

  const userTrove = await liquity.getTrove(address);
  const ethPrice = await liquity.getPrice();
  const borrowingFee = await liquity.getFees();
  const ethBalance = await signer.getBalance();
  const lUSDBalance = await liquity.getLUSDBalance(address)


  return {debt:userTrove.debt,netDebt:userTrove.debt.gt(200)?userTrove.netDebt:userTrove.debt,collateral:userTrove.collateral,ethPrice:ethPrice,status:userTrove.status,borrowingFee:borrowingFee.borrowingRate(),ethBalance:ethBalance, lUSDBalance}
}


export const openTrove = async ({ web3, address, depositETH, borrowLUSD, dispatch }) => {
  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const signer = provider.getSigner();
  const liquity = await EthersLiquity.connect(signer);
  const userTrove = await liquity.getTrove(address);
  const ethPrice = await liquity.getPrice();

  let isNew = true;
  if (userTrove.status === 'open') {
    isNew = false;
  }
  const data = await _openTrove({ liquity, depositETH, borrowLUSD, isNew, dispatch });
  return data;
};

export const adjustTrove = async ({ web3, withdrawETH, repayLUSD, dispatch }) => {
  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const signer = provider.getSigner();
  const liquity = await EthersLiquity.connect(signer);

  const data = await _adjustTrove({ liquity, withdrawETH, repayLUSD, dispatch });
  return data;
}

export const closeTrove = async ({ web3, dispatch }) => {
  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const signer = provider.getSigner();
  const liquity = await EthersLiquity.connect(signer);
  const data = await _closeTrove(liquity);
  return data;
};

const _openTrove = async({ liquity, depositETH, borrowLUSD, isNew, dispatch }) => {
  // const maxBorrowingRate = undefined;
  // const overrides = undefined;
  let newTrove;
  if (isNew) {
    newTrove = await liquity.openTrove({ depositCollateral: depositETH, borrowLUSD: borrowLUSD });
  } else {
    newTrove = await liquity.adjustTrove({ depositCollateral: depositETH, borrowLUSD: borrowLUSD });
  }
  return newTrove
};

const _adjustTrove = async({ liquity, withdrawETH, repayLUSD, dispatch }) => {
  const adjustedTrove = await liquity.adjustTrove({ withdrawCollateral: withdrawETH, repayLUSD: repayLUSD });
  return adjustedTrove
};

const _closeTrove = async(liquity) => {
  const closeTrove = await liquity.closeTrove();
  return closeTrove
};
