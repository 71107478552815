import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, FormControl, Grid } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "white",
    marginBottom: 10,
    padding: "5px 10px",
    paddingTop: 8,
  },
  icon: {
    color: "black",
    fontSize: 20,
    verticalAlign: "middle"
  },
  text: {
    fontSize: "0.9rem",
    lineHeight: 1.5,
    fontWeight: 700
  },
  link: {
    color: "black",
    borderBottom: "1px solid black",
    fontSize: "0.9rem",
    paddingBottom: 0,
    lineHeight: 1.5,
    marginLeft: 10,
    fontWeight: 1000
  }
}));


export const Notifications = ({ data }) => {
  const classes = useStyles();

  if (!data || data.length == 0) return <div />
  return (
    data.map(news =>
      <Grid container className={classes.paper} key={news.title} >
        <Grid item xs={"auto"}>
          <VolumeUpIcon className={classes.icon} />
        </Grid>
        <Grid item xs={11}>
          <div style={{ marginLeft: 10 }}>
            <span className={classes.text} style={{ color: (news.fontColor || "black") }}>{news.title}</span>
            <a href={news.linkUrl} className={classes.link}>{news.linkTitle}</a>
          </div>
        </Grid>
      </Grid>
    )


  );
}

export default Notifications;
