import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import _ from "lodash";
import styles from "./styles"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from 'components/CustomButtons/Button.js';

const HarvestCard = ({ useContext, pool, onClick, isLock = false }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const unclaimed = useContext && !isNaN(useContext.unclaimed) ? parseFloat(useContext.unclaimed).toFixed(2) : 'Loading...'
    const unclaimedValue = useContext && !isNaN(useContext.unclaimedValue) ? new BigNumber(useContext.unclaimedValue).toFormat(1) : 'Loading...'
    return (
        <div className={classes.card} style={{ padding: 0 }}>
            <div className={classes.subTitle} style={{ borderBottom: "1px solid white", padding: isLock ? 12 : 30 }}>
                Reward
            </div>
            <div style={{ padding: 20 }}>
                <div style={{ textAlign: "center" }}>
                    <img src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + ".png")} className={"lgIcon"} />
                    <ArrowRightAltIcon style={{ fontSize: 50, verticalAlign: "middle", marginRight: 20 }} />
                    <img src={require('assets/img/' + _.toLower(pool.rewardTokenSymbol) + ".png")} className={"lgIcon"} style={{ marginRight: 0 }} />
                </div>
                <div className={classes.subTitle} style={{ padding: isLock ? 26 : "57px 0", fontSize: "1.3rem" }}>
                    {pool.rewardTokenSymbol} Reward
                </div>
                <div className={classes.contentText}>
                    Reward Earned:
                    <span className={classes.rightText}>
                        {unclaimed}
                    </span>
                </div>
                <div className={classes.contentText}>
                    $:
                    <span className={classes.rightText}>
                        ${unclaimedValue}
                    </span>
                </div>
                <Button fullWidth color="green"
                    disabled={(useContext && parseFloat(useContext['unclaimed']) == 0)}
                    onClick={onClick}
                >{"Harvest All"}</Button>
            </div>
        </div>
    )


};

export default HarvestCard;
