import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from '../styles';
import { useConnectWallet } from '../../home/redux/hooks';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import _ from "lodash"

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';

import {
  useFetchForceTransmuterDetail,
  useForceCollect
} from 'features/collector/redux/hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);
export default function ForceCollect({ tokenId }) {
  const classes = useStyles();
  const { address, web3 } = useConnectWallet();
  const { forceTransmuteDetails, fetchForceTransmuterDetail } = useFetchForceTransmuterDetail();
  const { forceCollect } = useForceCollect()
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  useEffect(() => {
    if (address) {
      fetchForceTransmuterDetail(tokenId);

      const id = setInterval(() => {
        fetchForceTransmuterDetail(tokenId);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenId]);

  const onForce = (collectAddress) => {
    forceCollect({
      address,
      collectAddress,
      web3,
      tokenId,
    })
      .then(() => {
        enqueueSnackbar(t('Force Collect Success'), { variant: 'success' });
      })
      .catch(error => enqueueSnackbar(t('Force Collect Error', { error }), { variant: 'error' }));
  }
  const userInfo = forceTransmuteDetails[tokenId]
  const list = _.sortBy(userInfo, [function (o) { return -o.available; }])
  if (!userInfo || userInfo.length == 0) return <div />
  return (
    <>
      <div className="block" />
      <div className={"card"}>
        <div className={classes.title}>Force Collect
          <Tooltip
            placement="right"
            interactive
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/introduction-to-collectors' target='_blank'>this link</a> to see more details.
                </Typography>
              </React.Fragment>
            }
          >
            <IconButton className={"tooltip"}>
              <HelpIcon style={{ fontSize: "0.95rem" }} />
            </IconButton>
          </Tooltip>
        </div>
        <CustomTable
          headers={["Address", "Available", "Action"]} contents={
            list.map(data =>
              [
                `${data.address.slice(0, 6)}...${data.address.slice(-4)}`,
                parseFloat(data.available).toFixed(tokenId == "wabtc" ? 8 : 2),
                <Button
                  color="text"
                  size="sm"
                  onClick={() => { onForce(data.address) }}>
                  Force Collect
                </Button>
              ])
          } />
      </div>
    </>
  );
}
