import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_LIQUIDATE_BEGIN,
  VAULT_FETCH_LIQUIDATE_SUCCESS,
  VAULT_FETCH_LIQUIDATE_FAILURE,
} from './constants';
import { liquidate } from '../../web3';
import { fetchVaultDetail } from './action';

export function fetchLiquidate({ address, web3, amount, tokenId, strategyId }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_LIQUIDATE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      liquidate({ web3, address, amount, tokenId, strategyId, dispatch })
        .then(data => {
          dispatch({
            type: VAULT_FETCH_LIQUIDATE_SUCCESS,
            data
          });
          dispatch(fetchVaultDetail(tokenId, strategyId));
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_LIQUIDATE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchLiquidate() {
  const dispatch = useDispatch();

  const { fetchLiquidatePending } = useSelector(state => ({
    fetchLiquidatePending: state.vault.fetchLiquidatePending,
  }));

  const boundAction = useCallback(data => dispatch(fetchLiquidate(data)), [dispatch]);

  return {
    fetchLiquidate: boundAction,
    fetchLiquidatePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_LIQUIDATE_BEGIN:
      return {
        ...state,
        fetchLiquidatePending: {
          ...state.fetchLiquidatePending
        },
      };

    case VAULT_FETCH_LIQUIDATE_SUCCESS:
      return {
        ...state,
        fetchLiquidatePending: {
          ...state.fetchLiquidatePending
        },
      };

    case VAULT_FETCH_LIQUIDATE_FAILURE:
      return {
        ...state,
        fetchLiquidatePending: {
          ...state.fetchLiquidatePending
        },
      };

    default:
      return state;
  }
}
