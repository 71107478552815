import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useSnackbar } from 'notistack';
import {
  useFetchVaultDetail, useFetchLiquidate
} from 'features/vault/redux/hooks';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from 'features/home/redux/hooks';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import { byDecimals, calculateReallyNum } from 'features/helpers/bignumber';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import Container from '@material-ui/core/Container';
import IconToolTip from '../../../components/ToolTip/IconToolTip';
import { contracts, tokens } from 'features/configure';
const useStyles = makeStyles(styles);

export default function Liquidate() {

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const decimals = tokens[tokenId].decimals;

  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);
  const { fetchLiquidate, fetchLiquidatePending } = useFetchLiquidate();
  const [liquidateBalance, setLiquidateBalance] = useState({
    amount: '',
    slider: 0,
  });

  const handleLiquidatedBalance = (_, sliderNum) => {
    const total = getTotal()

    const amount = sliderNum === 0 ? '' : sliderNum === 100 ? new BigNumber(total).toFormat() : calculateReallyNum(total, sliderNum);

    setLiquidateBalance({
      amount: amount,
      slider: sliderNum,
    });
  };

  const onLiquidate = () => {
    let amountValue = liquidateBalance.amount
      ? liquidateBalance.amount.replaceAll(',', '')
      : liquidateBalance.amount;

    fetchLiquidate({
      address,
      web3,
      amount: new BigNumber(amountValue)
        .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
        .toString(10),
      tokenId,
      strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Vault-LiquidateSuccess'), { variant: 'success' });
        setLiquidateBalance({
          amount: '',
          slider: 0
        });
      })
      .catch(error => enqueueSnackbar(t('Vault-LiquidateError', { error }), { variant: 'error' }));
  };
  const getTotal = () => {
    return new BigNumber(details.collateralBalance).comparedTo(new BigNumber(details.remainingDebt)) ? details.remainingDebt : details.collateralBalance;
  }

  const setMaximumInput = event => {
    let value = getTotal()

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    setLiquidateBalance({
      amount: inputFinalVal(value, value, decimals),
    });
  };

  const changeDetailInputValue = event => {
    let value = event.target.value;
    const total = getTotal()

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    setLiquidateBalance({
      amount: inputFinalVal(value, total, decimals),
      slider: sliderNum,
    });
  };

  return (
    <div className={classes.sliderDetailContainer} >
      {/* <IconToolTip>
        Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a> to see more details.
      </IconToolTip> */}
      <Container maxWidth="md" disableGutters={true}>

        <div className={classes.showDetailLeft}>
          <h3 className={classes.detailHeader}>Repay the remaining {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat() : 0} {tokens[tokenId].correspondingMintableToken} debt by liquidating your {tokens[tokenId].token} collateral.</h3>
          <div className={classes.detailContent}>
            {t('Remaining Debt')}: {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat() : 0}<br /><br />
            {t('Vault-CollateralBalance')}: {details.collateralBalance ? new BigNumber(details.collateralBalance).toFormat() : 0}
          </div>
        </div>

        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={liquidateBalance.amount} onChange={changeDetailInputValue} setMax={setMaximumInput} />
        </FormControl>

        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={!Boolean(liquidateBalance.amount) || getTotal() == '0'}
            onClick={() => onLiquidate()}
          >
            {t('Liquidate')}
          </Button>

        </div>
      </Container>

    </div>
  );
}
