import React, { useEffect, useState, useRef } from 'react';
import Button from 'components/CustomButtons/Button.js';
import { Grid, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from "lodash";
import LockerField from './components/LockerField';
import AirdropsReward from './components/AirdropsReward';
import {
  useFetchVotingEscrowDetails,
} from 'features/pools/redux/hooks';

import { useConnectWallet } from 'features/home/redux/hooks';
import { useFetchAirdrops } from 'features/info/redux/hooks';
const useStyles = makeStyles((theme) => ({

}));
export default function Airdrops(props) {
  const { address } = useConnectWallet();
  const { votingEscrowDetails, fetchVotingEscrowDetails } = useFetchVotingEscrowDetails();
  const { airdropsData, fetchAirdrops } = useFetchAirdrops();

  useEffect(() => {
    if (address) {
      fetchVotingEscrowDetails();
      fetchAirdrops({ address })
      const id = setInterval(() => {
        fetchVotingEscrowDetails();
        fetchAirdrops({ address })
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);
  return (
    <>
      <div>
        <h1 className={"header"}>
          Locking Status
        </h1>
        <LockerField votingEscrowDetails={votingEscrowDetails} />
      </div>
      <div className="block">
        <h1 className={"header"}>
          veWASABI Airdrops(Without Vesting)
        </h1>
        <div className="card" style={{ padding: 30 }}>
          <Grid container spacing={3}>
            {(airdropsData ? airdropsData : []).map((reward, index) =>
              <Grid item xs={12} md={6} key={index}>
                <AirdropsReward reward={reward} />
              </Grid>

            )}
          </Grid>

        </div>
      </div>
    </>
  );
}
