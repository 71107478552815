import { stakingPoolsABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const poolClaim = async ({ web3, address, poolId, dispatch }) => {
  const contract = new web3.eth.Contract(stakingPoolsABI, contracts.stakingPools.address);
  const data = await _poolClaim({ web3, contract, poolId, address, dispatch });
  return data;
};

const _poolClaim = ({ web3, contract, poolId, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .claim(poolId)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
