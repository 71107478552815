import { pools } from '../../configure';

const tokens = {};

pools.forEach(({ token, tokenAddress, earnedToken, earnedTokenAddress }) => {
  tokens[token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0,
  };
  tokens[earnedToken] = {
    tokenAddress: earnedTokenAddress,
    tokenBalance: 0,
  };
});

const dai = { allowance: 0, checkApprovalPending: false, fetchApprovalPending: false };
const alusd = { allowance: 0, checkApprovalPending: false, fetchApprovalPending: false };

const initialState = {
  pools,
  tokens,
  dai,
  alusd,
  apys: null,
  fetchApysDone: false,
  fetchApysPending: false,
  fetchVaultsDataDone: {},
  fetchVaultsDataPending: {},
  fetchBalancesDone: false,
  fetchBalancesPending: false,
  fetchApprovalPending: {},
  fetchDepositPending: {},
  fetchWithdrawPending: {},
  fetchHarvestPending: {},
  fetchVaultDetailDone: {
    dai: {},
    wbtc: {},
    lusd: {},
    weth: {},
    link: {},
  },
  fetchVaultDetailPending: {
    dai: {},
    wbtc: {},
    lusd: {},
    weth: {},
    link: {},
  },
  fetchLiquityStatisticsDone: {},
  fetchLiquityStatisticsPending: {},
  fetchDepositPending: false,
  details: {
    dai: {
      yearn: {},
      idle: {},
      pickle: {},
    },
    wbtc: {
      vesper: {},
    },
    lusd: {
      liquity: {},
    },
    weth: {
      vesper: {},
    },
    link: {
      vesper: {},
    },
  },
  fetchRepayPending: false,
  fetchCurrentTrovePending: false,
  currentTrove: {},
  liquityDetails: {},
  fetchRepayPending: false,
};

export default initialState;
