import React, { useEffect, useState } from 'react';
import AllCollectorsTable from './components/AllCollectorsTable.js';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTVL } from 'components/TVL/useTVL';
import { useConnectWallet } from 'features/home/redux/hooks';
import styles from './styles';

export default function Collectors(props) {
  const { address } = useConnectWallet();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { fetchT, collectorTvls } = useTVL();
  useEffect(() => {
    if (address) {
      fetchT()
      const id = setInterval(() => {
        fetchT()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  return (
    <div >
      <div className="vaultHeader">Collectors</div>
      <h1 className={"header"}>
        All Collectors
      </h1>
      <AllCollectorsTable collectorTvls={collectorTvls} />
    </div>
  );
}
