import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import _ from "lodash";
import styles from "./styles"
import { contracts } from 'features/configure';

const FarmExtra = ({ useContext, pool, isLock = false }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const tokenPrice = useContext && !isNaN(useContext.tokenPrice) ? new BigNumber(useContext.tokenPrice).toFormat(2) : 'Loading...'
    return (
        <Grid container>
            <Grid item xs={12} md={4} style={{ paddingBottom: 20 }}>
                <div className={classes.textTitle}>
                    {isLock ? "Locked" : "Deposit"} Asset
                </div>
                <div className={classes.textValue}>
                    {pool.stakedTokenSymbol}
                </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingBottom: 20 }}>
                <div className={classes.textTitle}>
                    Token Price
                </div>
                <div className={classes.textValue}>
                    ${tokenPrice}
                </div>
            </Grid>
            <Grid item xs={12} md={isLock ? 12 : 4} >
                <div className={classes.textValue} style={{ paddingTop: 0 }}>
                    Token contract: <a href={`https://etherscan.com/address/${pool.stakedTokenAddress}`} className={classes.labelLink} target="_blank">Link</a>
                </div>
                <div className={classes.textValue}>
                    {isLock ? "Locker" : "Pool"} contract: <a href={`https://etherscan.com/address/${isLock ? contracts.votingEscrow.address : contracts.stakingPools.address}`} className={classes.labelLink} target="_blank">Link</a>
                </div>
            </Grid>
        </Grid>
    )


};

export default FarmExtra;
