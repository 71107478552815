import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PieChartIcon from '@material-ui/icons/PieChart';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import _ from "lodash"
const useStyles = makeStyles(styles);

const TotalLockedValue = ({ dashboardData }) => {
  const classes = useStyles();
  const theme = useTheme();


  return (
    <>
      <div style={{ borderBottom: "1px solid white", paddingBottom: 30 }}>
        <div className={classes.subTitle}>
          Cross-Chain TVL
          <Tooltip
            placement="right"
            interactive
            title={
              <Typography color="inherit">
                ETH: ${new BigNumber(_.get(dashboardData, 'TVL.eth', 0)).toFormat(2)}<br />
                BSC: ${new BigNumber(_.get(dashboardData, 'TVL.bsc', 0)).toFormat(2)}<br />
                Polygon: ${new BigNumber(_.get(dashboardData, 'TVL.polygon', 0)).toFormat(2)}
              </Typography>

            }
          >
            <IconButton className={"tooltip"}>
              <PieChartIcon style={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.bigGreenValue}>
          {`$${dashboardData ? new BigNumber(dashboardData.TVL.total).toFormat(0) : 'Loading...'}`}
        </div>
        <div className={classes.subTitle}>
          Accumulated Earnings
          <Tooltip
            placement="right"
            interactive
            title={
              <Typography color="inherit">
                Accumulated Earnings from Wasabix Finance. WASABI included.<br />
                ETH: ${new BigNumber(_.get(dashboardData, 'accumulateEarnings.eth', 0)).toFormat(2)}<br />
                BSC: ${new BigNumber(_.get(dashboardData, 'accumulateEarnings.bsc', 0)).toFormat(2)}<br />
                Polygon: ${new BigNumber(_.get(dashboardData, 'accumulateEarnings.polygon', 0)).toFormat(2)}

              </Typography>

            }
          >
            <IconButton className={"tooltip"}>
              <PieChartIcon style={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.bigGreenValue} >
          {`$${dashboardData ? new BigNumber(dashboardData.accumulateEarnings.total).toFormat(0) : 'Loading...'}`}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} sm={6} style={{ borderRight: "1px solid white", paddingBottom: 30 }}>
          <div className={classes.subTitle}>
            Gas Fee Saved
            <Tooltip
              placement="right"
              interactive
              title={
                <Typography color="inherit">
                  This shows the amount of gas fee has been saved for users in Wasabix Finance(Cross-Chain)<br />
                  ETH: ${new BigNumber(_.get(dashboardData, 'gasFeeSaved.eth', 0)).toFormat(2)}<br />
                  BSC: ${new BigNumber(_.get(dashboardData, 'gasFeeSaved.bsc', 0)).toFormat(2)}<br />
                  Polygon: ${new BigNumber(_.get(dashboardData, 'gasFeeSaved.polygon', 0)).toFormat(2)}
                </Typography>

              }
            >
              <IconButton className={"tooltip"}>
                <HelpIcon style={{ fontSize: "0.95rem" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.bigValue} >
            {`$${dashboardData ? new BigNumber(dashboardData.gasFeeSaved.total).toFormat(0) : 'Loading...'}`}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingBottom: 30 }}>
          <div className={classes.subTitle}>
            Wasabix Revenue
            <Tooltip
              placement="right"
              interactive
              title={
                <Typography color="inherit">
                  Revenue = All fee+WASABI penalty. 50% of the fee will be distributed to all veWASABI holders.<br />
                  {/* ETH: ${new BigNumber(_.get(dashboardData, 'accumulateEarnings.eth', 0)).toFormat(2)}<br />
                  BSC: ${new BigNumber(_.get(dashboardData, 'accumulateEarnings.bsc', 0)).toFormat(2)} */}
                </Typography>

              }
            >
              <IconButton className={"tooltip"}>
                <PieChartIcon style={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.bigValue} >
            Coming soon
            {/* {`$${dashboardData ? new BigNumber(dashboardData.accumulateEarnings.total).toFormat(0) : 'Loading...'}`} */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalLockedValue;
