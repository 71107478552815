import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.js';
import NeonTop from "assets/img/neon_top.png";
import Arrow from "assets/img/arrow.png";
import ArrowRight from "assets/img/arrow_right.png";
import Cerik from "assets/img/cerik.png";
import HIW from "assets/img/HIW.png";
import Rdauditors from "assets/img/rdauditors.png";
import Lending1 from "assets/img/lending1.png";
import Lending2 from "assets/img/lending2.png";
import Lending3 from "assets/img/lending3.png";
import Container from '@material-ui/core/Container';
const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "330px",
    padding: 25,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    margin: "20px auto",
    maxWidth: 275
  },
  auditCard: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
  },
  title: {
    fontSize: 40,
    fontWeight: 800,
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 800,
    color: theme.palette.text.primary,
    margin: "25px 0"
  },
  lendingTitle: {
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.text.primary,
    margin: "5px 0"
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 800,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: 13,
    lineHeight: 1.8,
    color: theme.palette.text.secondary
  },
  dot: {
    color: theme.palette.text.green
  },
  hr: {
    border: `0.5px solid ${theme.palette.text.green}`,
    maxWidth: 600,
    margin: '40px auto',

  },
  fullHr: {
    border: `0.5px solid ${theme.palette.text.green}`,
    margin: '35px auto',

  },
  cardButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: 20
  }

}));

const renderCards = (classes) => {
  return (
    <Grid container justify="center" style={{ margin: '30px auto', maxWidth: 950 }} >
      <Grid item xs={12} md={4} >
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            {"Earn interest with strategies"}
          </div>
          <div className={classes.description} >
            {"Deposit your crypto assets to automatically earn compounded interest from multiple blockchain strategies."}
          </div>
          <div className={classes.cardButton}>
            <Link to={"/app"}><Button fullWidth color="primary"> Start </Button></Link>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} >
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            {"Borrow with 0% interest"}
          </div>
          <div className={classes.description}>
            {"Once deposited, you are able to take your deposit as collateral to borrow waToken with 0% interest rate. The loan will be self repaid by the strategies."}
          </div>
          <div className={classes.cardButton}>
            <Link to={"/app"}><Button fullWidth color="primary"> Start </Button></Link>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} >
        <div className={classes.card}>
          <div className={classes.cardTitle}>
            {"Maximize return: Yield optimizer"}
          </div>
          <div className={classes.description}>
            {"Once borrowed, you are able to "}<br />
            {"1) stake your waToken to earn WASABI and more tokens."}<br />
            {"2) convert waToken to other cryptoassets."}
          </div>
          <div className={classes.cardButton}>
            <Link to={"/app"}><Button fullWidth color="primary"> Start </Button></Link>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
const LandingContent = () => {
  const classes = useStyles();

  return (
    <div style={{ textAlign: "center", maxWidth: 900, margin: "10px auto" }}>
      <Link to={'/app'} >
        <img className="lg:h-60 w-auto xs:h-20" width={250} src={NeonTop} alt="Logo" />
      </Link>
      <div className={classes.title}>
        {"Lending Protocol 3.0"}
      </div>
      <div className={classes.subTitle} style={{ marginBottom: 33 }}>
        {"Enjoy zero interest yield-backed lending and boost your capital efficiency by 1.5x"}
      </div>
      <Link to="/app">
        <Button size="lg" color="greenOutlined" >
          Enter App
        </Button>
      </Link>
      <div className={classes.hr} />
      <div className={classes.subTitle}>
        {"More than a Lending protocol"}<span className={classes.dot}>.</span>
      </div>
      {renderCards(classes)}
      <div className={classes.hr} />
      <div className={classes.subTitle}>
        {"Receive better yield with wasabix"}<span className={classes.dot}>.</span>
      </div>

      <Container style={{ marginTop: 90, position: "relative" }}>
        <div className={classes.subTitle} style={{ textAlign: "left", padding: 20, position: "absolute", top: 0, left: 0 }}>
          {"Lending protocol 3.0 ="}<br /><br />
          {"Lending + auto compounding +"}<br />
          {"borrow future-yield + yield optimizer"}
        </div>
        <img src={Arrow} style={{ width: "90%", marginTop: -40 }} />
        <Grid container justify="center" alignItems="center">
          <Grid item xs={10} md={2} style={{ paddingTop: 20 }}>
            <img src={Lending1} style={{ width: "120%", margin: "0 auto" }} />
            <div className={classes.subTitle}>
              {"Lending 1.0"}
            </div>
          </Grid>
          <Grid item xs={10} md={2}>
            <div className={classes.lendingTitle} style={{ marginLeft: 10 }}>
              <img src={ArrowRight} style={{ width: "110%" }} />
            </div>
          </Grid>
          <Grid item xs={10} md={2}>
            <div className={classes.lendingTitle}>
              <img src={Lending2} style={{ width: "120%", margin: "0 auto" }} />
              {"Lending 2.0"}
            </div>
          </Grid>
          <Grid item xs={10} md={2}>
            <div className={classes.lendingTitle} style={{ marginLeft: 10 }}>
              <img src={ArrowRight} style={{ width: "110%" }} />
            </div>
          </Grid>
          <Grid item xs={10} md={2}>
            <div className={classes.lendingTitle}>
              <img src={Lending3} style={{ width: "120%", margin: "0 auto" }} />
              {"Lending 3.0"}
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.fullHr} />
      <div className={classes.subTitle}>
        {"How It Works"}<span className={classes.dot}>.</span>
      </div>
      <img src={HIW} style={{ width: "100%", margin: "0 auto" }} />
      <div className={classes.fullHr} />
      <div className={classes.subTitle}>
        {"Security"}
      </div>
      <div className={classes.subTitle} style={{ fontSize: 20, margin: "20px auto" }}>
        {"Our smart contracts are audited by multiple firms"}
      </div>
      <Grid container justify="flex-start" alignItems="center" spacing={3}
        style={{ margin: "30px auto", width: "100%", maxWidth: 1000, marginBottom: 80 }}>

        <Grid item xs={12} md={6}>
          <a href="https://wasabix-finance.gitbook.io/wasabix_finance/code/audit-report" target="_blank">
            <div className={classes.auditCard}>
              <img src={Cerik} style={{ height: 160 }} />
            </div>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a href="https://wasabix-finance.gitbook.io/wasabix_finance/code/audit-report" target="_blank">
            <div className={classes.auditCard}>
              <img src={Rdauditors} style={{ height: 160 }} />
            </div>
          </a>
        </Grid>


      </Grid>
      <Link to="/app" >
        <Button size="lg" color="greenOutlined" >
          Connect App
        </Button>
      </Link>
    </div >
  );
};

export default LandingContent;
