import initialState from './initialState';
import { reducer as fetchDashboardReducer } from './fetchDashboard';
import { reducer as fetchUserStatsReducer } from './fetchUserStats';
import { reducer as fetchAssetStatsReducer } from './fetchAssetStats';
import { reducer as fetchStrategyEarnReducer } from './fetchStrategyEarn';
import { reducer as fetchAirdropsReducer } from './fetchAirdrops';
import { reducer as fetchLockerRewardsReducer } from './fetchLockerRewards';
const reducers = [
  fetchDashboardReducer,
  fetchUserStatsReducer,
  fetchAssetStatsReducer,
  fetchStrategyEarnReducer,
  fetchAirdropsReducer,
  fetchLockerRewardsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
