import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HighchartsReact from "highcharts-react-official";
import Highstockcharts from "highcharts/highstock";
import BigNumber from 'bignumber.js';

const styles = theme => ({
  detailHeader: {
    fontSize: "1.3rem",
    marginBottom: 15,
    fontWeight: 800,
  },
  detailSubHeader: {
    fontSize: "1rem",
    fontWeight: 800,
  },
  green: {
    color: theme.palette.text.green
  },
  red: {
    color: theme.palette.text.red
  }

})

export default function InvestChart({ assetStatsData }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const investedData = assetStatsData && assetStatsData.hourData ?
    assetStatsData.hourData.map(data => [data.timestamp * 1000, data.totalInvested]) : ""
  const earnData = assetStatsData && assetStatsData.hourData ?
    assetStatsData.hourData.map(data => [data.timestamp * 1000, data.totalEarning]) : ""
  const options = assetStatsData ? {
    title: { text: "" },
    chart: {
      backgroundColor: "#0000",
      zoomType: "x",
      height: 260,
      style: {
        fontFamily: 'Menlo, Roboto',
        color: "white"
      }
    },
    rangeSelector: {
      buttonPosition: { align: "center" },
      verticalAlign: "bottom",
      buttonSpacing: 10,
      buttonTheme: {
        stroke: "#0000",
        fill: "#0000",
        padding: 10,
        width: 60,
        style: {
          color: 'white',
          fontSize: 14,
          fontWeight: 500
        },
      },
      allButtonsEnabled: true,
      selected: 1,
      buttons: [{
        type: 'hour',
        count: 60,
        text: 'HOUR',
        forced: true,
      }, {
        type: 'day',
        count: 30,
        text: 'DAY',
        forced: true,
      }, {
        type: 'all',
        text: 'WEEK',
        forced: true,
      }],
      labelStyle: {
        display: "none"
      },
      inputEnabled: false,
    },
    yAxis: [
      {
        startOnTick: false,
        endOnTick: false,
        labels: {
          style: {
            fontFamily: 'Menlo, Roboto',
            color: "white"
          }
        },
        title: {
          text: "",
        },
        height: "100%",
        lineWidth: 0,
        gridLineWidth: 0,
        resize: {
          enabled: true,
        },
        gridLineColor: "#0000",
      },
    ],

    xAxis: {
      zoomEnabled: true,
      type: "datetime",
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          fontFamily: 'Menlo, Roboto',
          color: "white"
        }
      },
    },
    tooltip: {
      split: false,
      backgroundColor: "black",
      outside: true,
      valueDecimals: 2,
      shared: true,
      style: {
        color: "white",
        position: "absolute",
        left: 0,
        top: 0
      }
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        name: "Invested",
        data: investedData,
        color: "#7B5CCF",
      },
      {
        type: "line",
        name: "Earnings",
        data: earnData,
        color: "#84FFAC",
      },
    ]
  } : []
  return (
    <div className={"card"} >
      <div className={classes.detailHeader}>
        Invested: ${assetStatsData && assetStatsData.totalInvested ? new BigNumber(assetStatsData.totalInvested.total).toFormat(2) : "-"}
        {/* (<span className={classes.red}>{"-2.9%"}</span>) */}
      </div>
      <div className={classes.detailSubHeader}>
        Earnings: ${assetStatsData && assetStatsData.totalEarning ? new BigNumber(assetStatsData.totalEarning.total).toFormat(2) : "-"}
        {/* (<span className={classes.green}>{"+2.9%"}</span>) */}
      </div>
      {assetStatsData && assetStatsData.hourData ? <HighchartsReact
        constructorType={"stockChart"}
        allowChartUpdate={false}
        highcharts={Highstockcharts}
        options={options}
      />
        : <div style={{ height: 260 }} />}

    </div >
  );
}
