import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { formatCountdown } from '../../helpers/format';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { deprecatedPools } from 'features/configure';
import _ from 'lodash';
const AllFarmsTable = ({ context, pools, poolTlv, apiPoolData, linkPrefix, isSelf = false }) => {
  let data = [[]];
  if (apiPoolData) {
    data = apiPoolData.map(pool => {
      if (!pool.stakedTokenSymbol) return;
      return [
        <>
          <img
            src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + '.png')}
            className={'icon'}
            alt=""
          />
          {pool.stakedTokenSymbol}
        </>,
        pool.pid == 3
          ? parseFloat(pool.userDeposited).toFixed(6)
          : parseFloat(pool.userDeposited).toFixed(2),
        (parseFloat(pool.APY) * 100).toFixed(2) + '%',
        `$${new BigNumber(pool.TVL).toFormat(2)}`,
        pool.pid == 3
          ? parseFloat(pool.availableToHarvest).toFixed(6)
          : parseFloat(pool.availableToHarvest).toFixed(2),
        <Button color="text" component={'a'} href={(linkPrefix || '') + `/farms/${pool.pid}`}>
          Enter <ArrowRightIcon />
        </Button>,
      ];
    });
  } else if (context) {
    data = pools.map(pool => {
      const showHarvest = isSelf ? 'unclaimed' : 'balance';
      return [
        <>
          <img
            src={require('assets/img/' + _.toLower(pool.stakedTokenSymbol) + '.png')}
            className={'icon'}
            alt=""
          />
          {pool.stakedTokenSymbol}
        </>,
        context[pool.pid]
          ? pool.pid == 3
            ? parseFloat(context[pool.pid].yourStaked).toFixed(6)
            : parseFloat(context[pool.pid].yourStaked).toFixed(2)
          : 0,

        context[pool.pid] && isFinite(parseFloat(context[pool.pid].apy))
          ? ((parseFloat(context[pool.pid].apy) / 2.5) * 100).toFixed(2) +
            '%' +
            ' - ' +
            (parseFloat(context[pool.pid].apy) * 100).toFixed(2) +
            '%'
          : 'Loading...',
        poolTlv[pool.pid] ? `$${new BigNumber(poolTlv[pool.pid]).toFormat(2)}` : 'Loading...',
        context[pool.pid]
          ? pool.pid == 3
            ? parseFloat(context[pool.pid][showHarvest]).toFixed(6)
            : parseFloat(context[pool.pid][showHarvest]).toFixed(2)
          : 0,
        <Button color="text" component={Link} to={`/app/farms/${pool.pid}`}>
          Enter <ArrowRightIcon />
        </Button>,
      ];
    });
  }

  return (
    <CustomTable
      headers={[
        'Asset\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0',
        'Deposited',
        'APR',
        'TVL',
        isSelf ? 'Available to harvest' : 'Available to deposit',
      ]}
      contents={data}
    />
  );
};

export default AllFarmsTable;
