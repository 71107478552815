import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button';
const useStyles = makeStyles((theme) => ({
  showDetail: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'space-around',
    width: '100%',
    height: '40px',
    color: theme.palette.text.primary,
    backgroundColor: `transparent`,
    fontWeight: 500,
    '& fieldset': {
      border: `1px solid${theme.palette.text.primary}`,
      borderRadius: 5,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.grey,
      color: theme.palette.text.primary,
      border: `none`,
      '& fieldset': {
        border: `none`,
      },
    },
    outline: 'none',
  },
  hovered: {
    '& fieldset': {
    },
  },
  focused: {
    '& fieldset': {
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: '18px', padding: 11 }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const { classes, disabled, setMax } = props;
  const filteredProps = _.omit(props, ['setMax', 'startAdornment']);

  if (setMax) {
    return (
      <div>
        <TextField  {...filteredProps} placeholder={"0"} classes={Object.assign({}, commonClasses, classes)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: props.startAdornment,
            endAdornment: (
              <InputAdornment position="end" className={commonStyle.inputAdornment} >
                <Button
                  onClick={setMax}
                  color="primary"
                  size="sm"
                  disabled={disabled}>
                  MAX
                </Button>
              </InputAdornment>
            )

          }}
        />
      </div>
    )
  } else {
    return (
      <div>
        <TextField  {...filteredProps} placeholder={"0"} classes={Object.assign({}, commonClasses, classes)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: props.startAdornment,
            endAdornment: props.endAdornment || (
              <InputAdornment position="end" className={commonStyle.inputAdornment} >
                <></>
              </InputAdornment>
            )

          }}
        />
      </div>
    )
  }

}

CustomOutlinedInput.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
};
