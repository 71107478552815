import initialState from './initialState';
import { reducer as fetchTransmuterDetailReducer } from './fetchTransmuterDetail';
import { reducer as fetchForceTransmuterReducer } from './fetchForceTransmuter';
import { reducer as checkAlUsdApprovalReducer } from './checkAlUsdApproval';
import { reducer as fetchAlUsdApprovalReducer } from './fetchAlUsdApproval';


const reducers = [
  fetchTransmuterDetailReducer,
  fetchForceTransmuterReducer,
  checkAlUsdApprovalReducer,
  fetchAlUsdApprovalReducer
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
