import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

const AllCollectorsTable = ({ collectorTvls }) => {
  const theme = useTheme();
  const data = [
    {
      asset1: 'waUSD',
      asset2: 'DAI',
      icon1: 'wausd.png',
      icon2: theme.palette.daiIcon,
      tlv: collectorTvls.collectorDaiTlv,
      link: '/app/collectors/wausd',
    },
    {
      asset1: 'waBTC',
      asset2: 'wBTC',
      icon1: 'wabtc.png',
      icon2: theme.palette.wbtcIcon,
      tlv: collectorTvls.collectorWbtcTlv,
      link: '/app/collectors/wabtc',
    },
    {
      asset1: 'waLUSD',
      asset2: 'LUSD',
      icon1: 'walusd.png',
      icon2: theme.palette.lusdIcon,
      tlv: collectorTvls.collectorLusdTlv,
      link: '/app/collectors/walusd',
    },
    {
      asset1: 'waETH',
      asset2: 'wETH',
      icon1: 'waeth.png',
      icon2: theme.palette.ethIcon,
      tlv: collectorTvls.collectorWethTlv,
      link: '/app/collectors/waeth',
    },
    {
      asset1: 'waLINK',
      asset2: 'LINK',
      icon1: 'walink.png',
      icon2: 'link.png',
      tlv: collectorTvls.collectorLinkTlv,
      link: '/app/collectors/walink',
    },
  ];
  return (
    <CustomTable
      headers={['Collect Asset', 'TVL']}
      contents={data.map(collector => [
        <>
          <img src={require('assets/img/' + collector.icon1)} className={'icon'} alt="" />
          {collector.asset1}
          <SyncAltIcon style={{ margin: '0 20px', verticalAlign: 'middle' }} />
          <img src={require('assets/img/' + collector.icon2)} className={'icon'} alt="" />
          {collector.asset2}
        </>,
        collector.tlv ? `$${new BigNumber(collector.tlv).toFormat(2)}` : 'Loading...',
        <Button color="text" component={Link} to={collector.link}>
          Enter <ArrowRightIcon />
        </Button>,
      ])}
    />
  );
};

export default AllCollectorsTable;
