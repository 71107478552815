const styles = theme => ({
  detailContent: {
    color: theme.palette.text.primary,
    fontSize: 16, fontWeight: 700, marginBottom: 20
  },
  detailText: {
    color: theme.palette.text.primary,
    fontSize: 14, fontWeight: 500
  },
  detailHeader: {
    color: theme.palette.text.primary,
    lineHeight: 1.3,
    fontWeight: 800,
    marginBottom: 10
  },
  subTitle: {
    fontWeight: 900,
    padding: "10px 0",
    textAlign: "center",
    fontSize: "1.2rem",
    color: theme.palette.text.primary,
  },
  rightText: {
    float: "right"
  },
  contentText: {
    fontWeight: 700,
    paddingBottom: 20
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "left",
    fontSize: "1.2rem",
    paddingTop: 15,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16, fontWeight: 600,
    textDecoration: "underline"
  },
  contentHeader: {
    color: theme.palette.text.secondary,
    lineHeight: 1.3,
    fontWeight: 700,
    fontSize: "0.9rem"
  },
  value: {
    lineHeight: 1.8,
    fontWeight: 800,
    textAlign: "right",
    fontSize: "1rem"
  },
  detailContainer: {
    textAlign: 'left',
  },
  showDetailButton: {
    marginTop: 30
  },
  select: {
    fontSize: 20,
  },
  ul: {
    listStyleType: 'decimal'
  },
  li: {
    marginBottom: 24,
    lineHeight: 1.4
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
    fontWeight: 1000
  },
  card: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 10,
    padding: 20
  },
  textTitle: {
    fontWeight: 900,
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  textValue: {
    fontWeight: 900,
    padding: "10px 0",
    fontSize: "0.95rem",
    color: theme.palette.text.primary,
  },
});

export default styles;
