import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { strategiesData } from "features/configure"
import _ from "lodash";
const MyMintersTable = ({ details, linkPrefix, strategies = strategiesData }) => {
  let data = strategies.map((strategie) => {
    if (!details[strategie.tokenId]) return;
    const detail = details[strategie.tokenId][strategie.name];
    if (!details || details.length == 0 || isNaN(detail.remainingDebt) || parseFloat(detail.remainingDebt) < 10e-6) return;
    return (
      [(<div style={{ position: "relative" }}>
        <img src={require('assets/img/' + _.toLower(strategie.asset1) + ".png")} className={"icon"} alt='' />
        {strategie.asset1}
        <ArrowRightAltIcon style={{ position: "absolute", right: -20, top: 8 }} />
      </div>),
      (<>
        <img src={require('assets/img/' + _.toLower(strategie.asset2) + ".png")} className={"icon"} alt='' />
        {strategie.asset2}
      </>),
      _.upperFirst(strategie.name),
      isNaN(detail.remainingDebt) ? 'Loading...' : `${new BigNumber(detail.remainingDebt).toFormat(2)}`,
        "1.5x",
      isNaN(detail.globalMintable) ? 'Loading...' : `${new BigNumber(detail.globalMintable).toFormat(2)}`,
      (linkPrefix ?
        <Button
          color="text"
          component={"a"} href={linkPrefix ? (linkPrefix + (strategie.minterTo).replace('/app', '')) : strategie.minterTo}>Enter <ArrowRightIcon /></Button>
        : <Button
          color="text"
          component={Link} to={strategie.minterTo}>Enter <ArrowRightIcon /></Button>)
      ]
    )
  })

  return (

    < CustomTable leftText={{}}
      headers={["Collateral Asset", "Borrowed\xa0Asset", "Platform", "You've Borrowed", "Capital Efficiency", "Available to mint"]}
      contents={data} />
  );
};

export default MyMintersTable;
