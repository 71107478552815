import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { strategiesData } from "features/configure"
import _ from "lodash";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
const AllMintersTable = ({ details }) => {

  return (

    < CustomTable
      headers={["Collateral Asset", "Borrowed Asset", "Platform", "Global borrowed",
        <>Ceiling Rate%
          <Tooltip
            placement="right"
            interactive
            title={
              <React.Fragment>
                <Typography color="inherit">
                  Global lending rate. When it reaches 100% it means the Minter debt ceiling has been reached. <a target="_blank" className={"labelLink"}
                    href="https://wasabix-finance.gitbook.io/wasabix_finance/introduction-to-minters">{"More>>"}</a>
                </Typography>
              </React.Fragment>
            }
          >
            <IconButton className={"tooltip"}>
              <HelpIcon style={{ fontSize: "0.95rem" }} />
            </IconButton>
          </Tooltip></>]} contents={
            strategiesData.map((strategie) => {

              const detail = details[strategie.tokenId][strategie.name];
              if (!strategie.hidden) {
                return [(<div style={{ position: "relative" }}>
                  <img src={require('assets/img/' + _.toLower(strategie.asset1) + ".png")} className={"icon"} alt='' />
                  {strategie.asset1}
                  <ArrowRightAltIcon style={{ position: "absolute", right: 0, top: 10 }} />
                </div>),
                (<>
                  <img src={require('assets/img/' + _.toLower(strategie.asset2) + ".png")} className={"icon"} alt='' />
                  {strategie.asset2}
                </>),
                _.upperFirst(strategie.name),
                isNaN(detail.alusdHasMinted) ? 'Loading...' : `${new BigNumber(detail.alusdHasMinted).toFormat(2)}`,
                isNaN(detail.alUsdLendingRate) ? 'Loading...' : `${parseFloat(detail.alUsdLendingRate).toFixed(2)} %`,
                (<Button
                  color="text"
                  component={Link} to={strategie.minterTo}>Enter <ArrowRightIcon /></Button>)
                ]
              }
            }


            )
          } />
  );
};

export default AllMintersTable;
