import BigNumber from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import Button from 'components/CustomButtons/Button.js';
import LockerInfo from './LockerInfo';
import MeterialButton from '@material-ui/core/Button';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from '../../home/redux/hooks';
import { contracts } from "features/configure"
import {
  useFetchVotingEscrowDetails,
  useFetchCreateLock,
  useFetchAddAmount,
  useFetchExtendLock,
  useFetchLockerApproval,
  useFetchLockerWithdraw
} from 'features/pools/redux/hooks';
import { formatCountdown } from '../../helpers/format';
import { useSnackbar } from 'notistack';
import moment from 'moment';
const styles = theme => ({


  header: {
    lineHeight: 1.5,
    marginBottom: 25,
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  value: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    marginBottom: 5,
    fontSize: "0.95rem",
    fontWeight: 800,
  },
  tableContent: {
    textAlign: 'right',
  },
  datePicker: {
    width: "100%",

  }


})

const LockerField = () => {

  const dateButtons = [
    { label: "1 week", value: 7 },
    { label: "1 month", value: 30 },
    { label: "3 months", value: 90 },
    { label: "6 months", value: 180 },
    { label: "1 year", value: 360 },
    { label: "4 years", value: 1440 },
  ]
  const extendButtons = [
    { label: "1 week", value: 7 },
    { label: "1 month", value: 30 },
    { label: "3 months", value: 90 },
    { label: "6 months", value: 180 },
    { label: "1 year", value: 360 },
  ]
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { web3, address } = useConnectWallet();
  const [balance, setBalance] = useState('');
  const [hasLock, setHasLock] = useState(false);
  const [lockEnd, setLockEnd] = useState(false);
  const [selectedButton, setSelectedButton] = useState(-1);
  const { fetchCreateLock, fetchCreateLockPending } = useFetchCreateLock();
  const { fetchAddAmount, fetchAddAmountPending } = useFetchAddAmount();
  const { fetchExtendLock, fetchExtendLockPending } = useFetchExtendLock();
  const { fetchLockerApproval, fetchLockerApprovalPending } = useFetchLockerApproval();
  const { fetchLockerWithdraw, fetchLockerWithdrawPending } = useFetchLockerWithdraw();
  const { votingEscrowDetails } = useFetchVotingEscrowDetails();

  useEffect(() => {
    if (votingEscrowDetails.userLocked > 0) {
      setHasLock(true);
      if (votingEscrowDetails.userLockedEnd && votingEscrowDetails.userLockedEnd < moment().unix()) {
        setLockEnd(true)
      }
      // setSelectedButton(-1);
    } else {
      setHasLock(false);
      // setSelectedButton(2);
    }

  }, [votingEscrowDetails]);

  const setMaximumInput = event => {
    let value = votingEscrowDetails.userWasabiBalance;

    if (!inputLimitPass(value, 18)) {
      return;
    }
    setBalance(inputFinalVal(value, value, 18));
  }

  const changeBalanceInputValue = event => {
    let value = event.target.value;
    const total = votingEscrowDetails.userWasabiBalance;

    if (!inputLimitPass(value, 18)) {
      return;
    }

    setBalance(inputFinalVal(value, total, 18));
  };
  const onApprove = () => {
    fetchLockerApproval()
  };
  const onCreateLock = () => {
    fetchCreateLock({
      address,
      web3,
      amount: new BigNumber(balance)
        .multipliedBy(new BigNumber(10).exponentiatedBy(18))
        .toString(10),
      lockDays: selectedButton
    })
      .then(() => {
        enqueueSnackbar('Vault Create Lock Success', { variant: 'success' });
        setBalance('');
        setSelectedButton(-1);
      })
      .catch(error => enqueueSnackbar('Vault Create Lock Error', { variant: 'error' }));
  };

  const onAddAmount = () => {
    fetchAddAmount({
      address,
      web3,
      amount: new BigNumber(balance)
        .multipliedBy(new BigNumber(10).exponentiatedBy(18))
        .toString(10),
    })
      .then(() => {
        enqueueSnackbar('Vault Add Amount Success', { variant: 'success' });
        setBalance('');
      })
      .catch(error => enqueueSnackbar('Vault Add Amount Error', { variant: 'error' }));
  };

  const onExtendLock = () => {
    fetchExtendLock({
      address,
      web3,
      extendDays: selectedButton
    })
      .then(() => {
        enqueueSnackbar(t('Vault-ExtendLockSuccess'), { variant: 'success' });
        setSelectedButton(-1);
      })
      .catch(error => enqueueSnackbar(t('Vault-ExtendLockError', { error }), { variant: 'error' }));
  };

  const onWithdraw = () => {
    fetchLockerWithdraw({
      address,
      web3,
    })
      .then(() => {
        enqueueSnackbar('Locker Withdraw Success', { variant: 'success' });
        setSelectedButton(-1);
      })
      .catch(error => enqueueSnackbar(t('Locker Withdraw Error', { error }), { variant: 'error' }));
  };


  let currentLockDays = isNaN(votingEscrowDetails.userLockedEnd) || votingEscrowDetails.userLockedEnd == "0" ? 0 : moment(votingEscrowDetails.userLockedEnd * 1000).diff(moment(), 'days') + 1
  currentLockDays = currentLockDays < 0 ? 0 : currentLockDays


  const renderAmountField = () => {
    return (<>
      < div className={classes.value}>
        {hasLock ? "Increase" : "Add"} Amount: <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { setMaximumInput() }}>
          {isNaN(votingEscrowDetails.userWasabiBalance) ? 'Loading...' : parseFloat(votingEscrowDetails.userWasabiBalance).toFixed(3)}</span>
      </div>
      <CustomOutlinedInput value={balance} onChange={changeBalanceInputValue} setMax={setMaximumInput} />
      <div className={classes.header}>Your veWASABI will be: <span className={classes.value}>
        {hasLock ?
          (balance && !isNaN(votingEscrowDetails.userLocked) ? parseFloat((parseFloat(votingEscrowDetails.userLocked) + parseFloat(balance)) * currentLockDays / contracts.votingEscrow.MAX_LOCK_TIME).toFixed(2) : "0.00")
          : (selectedButton >= 0 && balance ? parseFloat(balance * (dateButtons[selectedButton].value) / contracts.votingEscrow.MAX_LOCK_TIME).toFixed(2) : "0.00")
        } veWASABI</span>
      </div>
    </>)
  }

  if (!contracts.votingEscrow) return <div />
  return (

    <div className="card" style={{ padding: 30 }}>
      <LockerInfo votingEscrowDetails={votingEscrowDetails} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          {lockEnd && < div className={classes.value}>Your lock time is end, please increase lock time first.</div>}
          {!lockEnd && renderAmountField()}


        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.value}>
            {hasLock ? "Increase " : ""} Lock Time:
          </div>
          {(hasLock ? extendButtons : dateButtons).map((button, index) => {
            return (
              <MeterialButton key={button.label}
                color="primary"
                variant={selectedButton == index ? "contained" : "outlined"}
                style={{ margin: 8, width: 100, height: 38, fontSize: 12 }}
                onClick={() => {
                  setSelectedButton(index)
                }}>
                {button.label}
              </MeterialButton>
            )
          })}
          {hasLock &&
            <div className={classes.header}>Your veWASABI will be: <span className={classes.value}>
              {selectedButton >= 0 && !isNaN(votingEscrowDetails.userLocked) ? parseFloat(votingEscrowDetails.userLocked * (currentLockDays + extendButtons[selectedButton].value) / contracts.votingEscrow.MAX_LOCK_TIME).toFixed(2) : "0.00"} veWASABI
            </span>
            </div>
          }
        </Grid>

        <Grid item xs={12} md={6} >
          {hasLock && <Button
            fullWidth color="green"
            disabled={!Boolean(balance)}
            onClick={() => {
              onAddAmount()
            }}>
            Add Amount
          </Button>}
        </Grid>
        <Grid item xs={12} md={6} >
          {hasLock && <Button
            fullWidth
            color="green"
            disabled={!Boolean(selectedButton >= 0)}
            onClick={() => {
              onExtendLock()
            }}>
            Extend Lock
          </Button>
          }
          {!hasLock &&
            (!isNaN(votingEscrowDetails.allowance) && parseFloat(votingEscrowDetails.allowance) > 0 ?
              <Button
                fullWidth
                color="green"
                disabled={!Boolean(balance) || !Boolean(selectedButton >= 0)}
                onClick={() => {
                  onCreateLock()
                }}>
                Create Lock
              </Button> :
              <Button
                fullWidth
                color="green"
                disabled={fetchLockerApprovalPending}
                onClick={() => {
                  onApprove()
                }}>
                Approve
              </Button>)}
        </Grid >
        {(hasLock && ((votingEscrowDetails.userLockedEnd * 1000) - new Date().getTime()) < 0)
          ? (<Grid item xs={12} style={{ marginTop: 30 }}>
            <Button
              fullWidth
              color="secondary"
              onClick={() => {
                onWithdraw()
              }}>
              Withdraw
            </Button>
          </Grid>)
          : <div />
        }
      </Grid>

    </div >

  );
};

export default LockerField;
