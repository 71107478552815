const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxWidth: '40rem',
    margin: '2rem auto'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 900,
    fontSize: '1.5rem',
    marginBottom: '0.2rem',
    textAlign: 'center'
  },
  subTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: 20,
    fontWeight: 600,
    marginBottom: 18,
    color: theme.palette.text.secondary,
  },
  bigGreenValue: {
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: 800,
    color: theme.palette.text.green,
  },
  bigValue: {
    textAlign: "center",
    fontSize: "1.5rem",
    padding: "10px 0",
    fontWeight: 800,
    color: theme.palette.text.primary,
  },
  link: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkIcon: {
    marginRight: '0.5rem',
    minWidth: '24px',
  },

});

export default styles;
