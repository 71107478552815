import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  POOLS_FETCH_EXIT_BEGIN,
  POOLS_FETCH_EXIT_SUCCESS,
  POOLS_FETCH_EXIT_FAILURE,
} from './constants';
import { poolExit } from '../../web3';

export function fetchExit({ address, web3, poolId, amount }) {
  return dispatch => {
    dispatch({
      type: POOLS_FETCH_EXIT_BEGIN,
      index: poolId
    });

    const promise = new Promise((resolve, reject) => {
      poolExit({ web3, address, poolId, amount, dispatch })
        .then(data => {
          dispatch({
            type: POOLS_FETCH_EXIT_SUCCESS,
            data,
            index: poolId
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_EXIT_FAILURE,
            index: poolId
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchExit(poolId) {
  const dispatch = useDispatch();

  const { fetchExitPending } = useSelector(state => ({
    fetchExitPending: state.pools.fetchExitPending,
  }));

  const boundAction = useCallback(
    (data, poolId) => {
      return dispatch(fetchExit(data, poolId));
    },
    [dispatch]
  );

  return {
    fetchExit: boundAction,
    fetchExitPending,
  };
}

export function reducer(state, action) {
  let { fetchExitPending } = state;
  switch (action.type) {
    case POOLS_FETCH_EXIT_BEGIN:
      fetchExitPending[action.index] = true;
      return {
        ...state,
        fetchExitPending
      };

    case POOLS_FETCH_EXIT_SUCCESS:
      fetchExitPending[action.index] = false;
      return {
        ...state,
        fetchExitPending
      };

    case POOLS_FETCH_EXIT_FAILURE:
      fetchExitPending[action.index] = false;
      return {
        ...state,
        fetchExitPending
      };

    default:
      return state;
  }
}
