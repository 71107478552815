import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_EXTEND_LOCK_BEGIN,
  FETCH_EXTEND_LOCK_SUCCESS,
  FETCH_EXTEND_LOCK_FAILURE,
} from './constants';
import { extendLock } from '../../web3';
import { fetchVotingEscrowDetails } from './action';

export function fetchExtendLock({ address, web3, amount, extendDays }) {
  return dispatch => {
    dispatch({
      type: FETCH_EXTEND_LOCK_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      extendLock({ web3, address, amount, extendDays, dispatch })
        .then(data => {
          dispatch({
            type: FETCH_EXTEND_LOCK_SUCCESS,
            data,
          });
          dispatch(fetchVotingEscrowDetails());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_EXTEND_LOCK_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchExtendLock() {
  const dispatch = useDispatch();

  const { fetchExtendLockPending } = useSelector(state => ({
    fetchExtendLockPending: state.pools.fetchExtendLockPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchExtendLock(data));
    },
    [dispatch]
  );

  return {
    fetchExtendLock: boundAction,
    fetchExtendLockPending,
  };
}

export function reducer(state, action) {
  let { fetchExtendLockPending } = state;
  switch (action.type) {
    case FETCH_EXTEND_LOCK_BEGIN:
      fetchExtendLockPending = true;
      return {
        ...state,
        fetchExtendLockPending
      };

    case FETCH_EXTEND_LOCK_SUCCESS:
      fetchExtendLockPending = false;
      return {
        ...state,
        fetchExtendLockPending
      };

    case FETCH_EXTEND_LOCK_FAILURE:
      fetchExtendLockPending = false;
      return {
        ...state,
        fetchExtendLockPending
      };

    default:
      return state;
  }
}
