import { HomePage } from './';
import Stats from '../info/Stats';
import Airdrops from '../locker/Airdrops';
import Calculator from '../locker/Calculator';
export default {
  path: '',
  childRoutes: [
    { path: 'stats', component: Stats, isIndex: false },
    { path: 'airdrops', component: Airdrops, isIndex: false },
    { path: 'calculator', component: Calculator, isIndex: false },
    { path: 'index', component: HomePage, isIndex: true },
  ],
};
