import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_ADD_AMOUNT_BEGIN,
  FETCH_ADD_AMOUNT_SUCCESS,
  FETCH_ADD_AMOUNT_FAILURE,
} from './constants';
import { addAmount } from '../../web3';
import { fetchVotingEscrowDetails } from './action';

export function fetchAddAmount({ address, web3, amount }) {
  return dispatch => {
    dispatch({
      type: FETCH_ADD_AMOUNT_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      addAmount({ web3, address, amount, dispatch })
        .then(data => {
          dispatch({
            type: FETCH_ADD_AMOUNT_SUCCESS,
            data,
          });
          dispatch(fetchVotingEscrowDetails());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_ADD_AMOUNT_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchAddAmount() {
  const dispatch = useDispatch();

  const { fetchAddAmountPending } = useSelector(state => ({
    fetchAddAmountPending: state.pools.fetchAddAmountPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchAddAmount(data));
    },
    [dispatch]
  );

  return {
    fetchAddAmount: boundAction,
    fetchAddAmountPending,
  };
}

export function reducer(state, action) {
  let { fetchAddAmountPending } = state;
  switch (action.type) {
    case FETCH_ADD_AMOUNT_BEGIN:
      fetchAddAmountPending = true;
      return {
        ...state,
        fetchAddAmountPending
      };

    case FETCH_ADD_AMOUNT_SUCCESS:
      fetchAddAmountPending = false;
      return {
        ...state,
        fetchAddAmountPending
      };

    case FETCH_ADD_AMOUNT_FAILURE:
      fetchAddAmountPending = false;
      return {
        ...state,
        fetchAddAmountPending
      };

    default:
      return state;
  }
}
