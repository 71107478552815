import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import TotalLockedValue from '../../components/TVL/TotalLockedValue';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFetchAllVaultDetails } from '../vault/redux/hooks';
import { useDashboard } from './redux/fetchDashboard';
import AddIcon from '@material-ui/icons/Add';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useTVL } from '../../components/TVL/useTVL';
import Button from 'components/CustomButtons/Button.js';
import Notifications from 'components/Notifications/Notifications.js';
import AllStrategiesTable from '../strategy/components/AllStrategiesTable.js';
import AllMintersTable from '../minter/components/AllMintersTable.js';
import AllFarmsTable from '../pools/components/AllFarmsTable.js';
import AllCollectorsTable from '../collector/components/AllCollectorsTable.js';
import { contracts, pools } from '../configure';
import { useFetchApys } from '../vault/redux/fetchApys';
import { useFetchPools } from '../pools/redux/fetchPools';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import { convertAprFloatToString } from 'features/helpers/utils';
import Tooltip from '@material-ui/core/Tooltip';
import PieChartIcon from '@material-ui/icons/PieChart';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { useFetchStrategyEarn } from 'features/info/redux/hooks';
import { useFetchVotingEscrowDetails } from 'features/pools/redux/hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: 'hidden',
    borderRadius: 5,
    marginTop: 10,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontSize: '1.1rem',
    paddingTop: 15,
  },
  subTitle: {
    textAlign: 'center',
    fontSize: '1.3rem',
    color: theme.palette.text.secondary,
  },
  metricsTitle: {
    fontSize: '0.8rem',
    marginTop: 18,
    marginBottom: 8,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  metricsValue: {
    fontSize: '0.9rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  cardTitle: {
    fontSize: '0.85rem',
    marginBottom: 20,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  cardValue: {
    fontSize: '0.9rem',
    fontWeight: 700,
    marginBottom: 20,
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  cardApyValue: {
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.green,
    marginBottom: 20,
    textAlign: 'right',
    textDecoration: 'underline',
  },
  bigGreenValue: {
    textAlign: 'center',
    fontSize: '2.3rem',
    color: theme.palette.text.green,
  },
  bigValue: {
    textAlign: 'center',
    fontSize: '2rem',
    color: theme.palette.text.primary,
  },
  label: {
    color: theme.palette.text.primary,
    fontFamily: 'Menlo',
    fontSize: '1.15rem',
    fontWeight: '800',
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const { dashboardData, fetchDashboard } = useDashboard();
  const { address } = useConnectWallet();
  const { earnData, fetchStrategyEarn } = useFetchStrategyEarn();
  const notifications = [
    {
      title:
        'WaLUSD Collector will be migrated to new version on 2022/07/08, please visit',
      linkTitle: 'WaLUSD Collctor>>',
      linkUrl: 'https://wasabix.finance/#/app/collectors/walusd',
      fontColor: 'red',
    },
  ];
  const { details, fetchAllVaultDetails } = useFetchAllVaultDetails();
  const { poolTlv, fetchT, fetchV, tvls, collectorTvls } = useTVL();
  const { votingEscrowDetails, fetchVotingEscrowDetails } = useFetchVotingEscrowDetails();
  const { context, fetchPools } = useFetchPools();
  const { apys, fetchApys } = useFetchApys();
  const matchComputer = useMediaQuery(theme.breakpoints.up('md'));
  let filteredPools = pools;
  useEffect(() => {
    if (address) {
      fetchAllVaultDetails();
      fetchDashboard();
      fetchT();
      fetchPools();
      fetchApys();
      fetchVotingEscrowDetails();
      fetchStrategyEarn(address);
      const id = setInterval(() => {
        fetchAllVaultDetails();
        fetchDashboard();
        fetchT();
        fetchPools();
        fetchApys();
        fetchVotingEscrowDetails();
        fetchStrategyEarn(address);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  const topPortfolioData = [
    {
      asset1: 'LUSD',
      asset2: 'waLUSD',
      asset1Icon: 'lusd.png',
      asset2Icon: 'walusd.png',
      platform: 'Liquity+Wasabix',
      earned: 'LUSD + WASABI',
      ceRatio: '200%',
      apy: `${
        apys ? convertAprFloatToString(_.find(apys, { tokenId: 'lusd' }).apy) : 'Loading...'
      } + ${
        context[4]
          ? isFinite(parseFloat(context[4].apy))
            ? (parseFloat(context[4].apy) * 100).toFixed(2) + '%'
            : 'Loading...'
          : 'Loading...'
      }`,
      link: 'app/strategies/lusd/liquity',
    },
    {
      asset1: 'WBTC',
      asset2: 'waBTC',
      asset1Icon: theme.palette.wbtcIcon,
      asset2Icon: 'wabtc.png',
      platform: 'Vesper+Wasabix',
      earned: 'WBTC + WASABI',
      ceRatio: '200%',
      apy: `${
        apys ? convertAprFloatToString(_.find(apys, { tokenId: 'wbtc' }).apy) : 'Loading...'
      } + ${
        context[3]
          ? isFinite(parseFloat(context[3].apy))
            ? (parseFloat(context[3].apy) * 100).toFixed(2) + '%'
            : 'Loading...'
          : 'Loading...'
      }`,
      link: 'app/strategies/wbtc/vesper',
    },
    {
      asset1: 'ETH',
      asset2: 'waETH',
      asset1Icon: 'eth.png',
      asset2Icon: 'waeth.png',
      platform: 'Vesper+Wasabix',
      earned: 'ETH + WASABI',
      ceRatio: '200%',
      apy: `${
        apys ? convertAprFloatToString(_.find(apys, { tokenId: 'weth' }).apy) : 'Loading...'
      } + ${
        context[5]
          ? isFinite(parseFloat(context[5].apy))
            ? (parseFloat(context[5].apy) * 100).toFixed(2) + '%'
            : 'Loading...'
          : 'Loading...'
      }`,
      link: 'app/strategies/weth/vesper',
    },
  ];
  const renderTopPortfolio = () => {
    return topPortfolioData.map((data, index) => {
      return (
        <Grid item xs={12} md={4} key={index}>
          <div className={classes.card} style={{ padding: 15 }}>
            <Grid container alignItems="center">
              <Grid item xs={12} style={{ textAlign: 'center', padding: '20px 0', color: 'white' }}>
                <img
                  src={require('../../assets/img/' + data.asset1Icon)}
                  style={{ width: 60, height: 60, verticalAlign: 'middle' }}
                  alt=""
                />
                <AddIcon fontSize="large" style={{ verticalAlign: 'middle', margin: 10 }} />
                <img
                  src={require('../../assets/img/' + data.asset2Icon)}
                  style={{ width: 60, height: 60, verticalAlign: 'middle' }}
                  alt=""
                />
              </Grid>
              <Grid item xs={5} className={classes.cardTitle}>
                Asset
              </Grid>
              <Grid item xs={7} className={classes.cardValue}>
                {data.asset1} + {data.asset2}
              </Grid>
              <Grid item xs={4} className={classes.cardTitle}>
                Platform
              </Grid>
              <Grid item xs={8} className={classes.cardValue}>
                {data.platform}
              </Grid>
              <Grid item xs={5} className={classes.cardTitle}>
                Earn
              </Grid>
              <Grid item xs={7} className={classes.cardValue}>
                {data.earned}
              </Grid>
              <Grid item xs={7} className={classes.cardTitle}>
                C-Ratio
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        Wasabix Finance offers 200% Collateral Ratio in all Minters. For example,
                        when you deposited 200 BUSD in the strategy, you are able to borrow/minter
                        up to 100 waBUSD with 0% interest.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <HelpIcon style={{ fontSize: '0.95rem' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={5} className={classes.cardValue}>
                {data.ceRatio}
              </Grid>
              <Grid item xs={3} className={classes.cardTitle}>
                APY
              </Grid>
              <Grid item xs={9} className={classes.cardApyValue}>
                {data.apy}
              </Grid>
            </Grid>
            <Link to={data.link} style={{ marginTop: 10 }}>
              <Button fullWidth color="primary">
                {' '}
                Enter{' '}
              </Button>
            </Link>
          </div>
        </Grid>
      );
    });
  };
  const stakingData = [
    {
      asset: 'WASABI Locker',
      assetIcon: 'wasabi_locker.png',
      platform: 'Wasabix Finance',
      earned: 'WASABI + Fee',
      apy:
        (votingEscrowDetails && !isNaN(votingEscrowDetails.apy)
          ? parseFloat(votingEscrowDetails.apy * 100).toFixed(2) + '%'
          : 'Loading...') + ' + more%',
      link: 'app/locker',
    },
    {
      asset: 'WASABI-ETH SLP',
      assetIcon: 'wasabi-eth slp.png',
      platform: 'Wasabix+Sushi',
      earned: 'WASABI',
      apy:
        context[1] && isFinite(parseFloat(context[1].apy))
          ? ((parseFloat(context[1].apy) * 100) / 2.5).toFixed(2) +
            '% - ' +
            (parseFloat(context[1].apy) * 100).toFixed(2) +
            '%'
          : 'Loading...',
      link: 'app/farms/1',
    },
  ];
  const renderStakingData = () => {
    return stakingData.map((data, index) => {
      return (
        <Grid item xs={12} md={4} key={index}>
          <div className={classes.card} style={{ padding: 15 }}>
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center', padding: '20px 0', color: 'white ' }}
              >
                <img
                  src={require('../../assets/img/' + data.assetIcon)}
                  style={{ width: 70, height: 70, objectFit: 'contain' }}
                  alt=""
                />
              </Grid>
              <Grid item xs={4} className={classes.cardTitle}>
                Asset
              </Grid>
              <Grid item xs={8} className={classes.cardValue}>
                {data.asset}
              </Grid>
              <Grid item xs={4} className={classes.cardTitle}>
                Platform
              </Grid>
              <Grid item xs={8} className={classes.cardValue}>
                {data.platform}
              </Grid>
              <Grid item xs={4} className={classes.cardTitle}>
                Earn
              </Grid>
              <Grid item xs={8} className={classes.cardValue}>
                {data.earned}
              </Grid>
              <Grid item xs={6} className={classes.cardTitle}>
                APR
              </Grid>
              <Grid item xs={6} className={classes.cardApyValue}>
                {data.apy}
              </Grid>
            </Grid>
            <Link to={data.link} style={{ marginTop: 10 }}>
              <Button fullWidth color="primary">
                {' '}
                Enter{' '}
              </Button>
            </Link>
          </div>
        </Grid>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Notifications data={notifications} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <h1 className={'header'}>Cross-Chain Information</h1>
          <div className={classes.card}>
            <TotalLockedValue dashboardData={dashboardData} />
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <h1 className={'header'}>Cross-Chain Metrics</h1>
          <div className={classes.card}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              <div className={classes.metricsTitle}>
                Avg Earnings/day
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      Average Earnings per day in Wasabix Finance. Earnings = Earning from
                      strategies + Earnings from Farm + Earning from Penalty + Earning from
                      Airdrops.
                      <br />
                      ETH: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByDay.eth', 0)).toFormat(2)}
                      <br />
                      BSC: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByDay.bsc', 0)).toFormat(2)}
                      <br />
                      Polygon: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByDay.polygon', 0)).toFormat(
                        2
                      )}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                $
                {dashboardData
                  ? new BigNumber(dashboardData.avgEarningByDay.total).toFormat(0)
                  : '-'}
              </div>
              <div className={classes.metricsTitle}>
                Est Earnings/yr
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      Avg Earnings/day * 365
                      <br />
                      ETH: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByYear.eth', 0)).toFormat(2)}
                      <br />
                      BSC: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByYear.bsc', 0)).toFormat(2)}
                      <br />
                      Polygon: $
                      {new BigNumber(_.get(dashboardData, 'avgEarningByYear.polygon', 0)).toFormat(
                        2
                      )}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                $
                {dashboardData
                  ? new BigNumber(dashboardData.avgEarningByYear.total).toFormat(0)
                  : '-'}
              </div>
              <div className={classes.metricsTitle}>
                WASABI Circulating supply
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      Cross-Chain WASABI circulating supply
                      <br />
                      ETH:{' '}
                      {new BigNumber(_.get(dashboardData, 'circulatingSupply.eth', 0)).toFormat(2)}
                      <br />
                      BSC:{' '}
                      {new BigNumber(_.get(dashboardData, 'circulatingSupply.bsc', 0)).toFormat(2)}
                      <br />
                      Polygon:{' '}
                      {new BigNumber(_.get(dashboardData, 'circulatingSupply.polygon', 0)).toFormat(
                        2
                      )}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                {dashboardData
                  ? new BigNumber(dashboardData.circulatingSupply.total).toFormat(1)
                  : '-'}
              </div>
              <div className={classes.metricsTitle}>
                Total WASABI locked
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      The amount of WASABI locked in Locker cross-chain
                      <br />
                      ETH:{' '}
                      {new BigNumber(_.get(dashboardData, 'totalWasabiLocked.eth', 0)).toFormat(2)}
                      <br />
                      BSC:{' '}
                      {new BigNumber(_.get(dashboardData, 'totalWasabiLocked.bsc', 0)).toFormat(2)}
                      <br />
                      Polygon:{' '}
                      {new BigNumber(_.get(dashboardData, 'totalWasabiLocked.polygon', 0)).toFormat(
                        2
                      )}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                {dashboardData
                  ? new BigNumber(dashboardData.totalWasabiLocked.total).toFormat(1)
                  : '-'}
              </div>
              <div className={classes.metricsTitle}>
                No. of strategies & Farms
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      ETH: {_.get(dashboardData, 'numOfStrategies.eth', 0)}
                      <br />
                      BSC: {_.get(dashboardData, 'numOfStrategies.bsc', 0)}
                      <br />
                      Polygon: {_.get(dashboardData, 'numOfStrategies.polygon', 0)}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                {dashboardData ? dashboardData.numOfStrategies.total : '-'}
              </div>
              <div className={classes.metricsTitle}>
                veWASABI APY%
                <Tooltip
                  placement="right"
                  interactive
                  title={
                    <Typography color="inherit">
                      Coming soon
                      {/* ETH: -%<br />
                      BSC: -% */}
                    </Typography>
                  }
                >
                  <IconButton className={'tooltip'}>
                    <PieChartIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.metricsValue}>
                {dashboardData && dashboardData.veWasabiAPY
                  ? parseFloat(dashboardData.veWasabiAPY * 100).toFixed(1) + '%'
                  : '-%'}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <h1 className={'header'}>Top Portfolio (Strategy + Miner + Farm)</h1>
        <Grid container spacing={3}>
          {renderTopPortfolio()}
        </Grid>
      </div>

      <div>
        <h1 className={'header'}>WASABI Staking(Farm & Locker)</h1>
        <Grid container spacing={3}>
          {renderStakingData()}
        </Grid>
      </div>

      <div style={{ marginTop: 40 }}>
        <h1 className={'header'}>All Strategies</h1>
        <AllStrategiesTable details={details} tvls={tvls} earnData={earnData} />
      </div>
      <div style={{ marginTop: 40 }}>
        <h1 className={'header'}>All Minters</h1>
        <AllMintersTable details={details} />
      </div>
      <div style={{ marginTop: 40 }}>
        <h1 className={'header'}>All Farms</h1>
        <AllFarmsTable context={context} pools={filteredPools} poolTlv={poolTlv} />
      </div>
      <div style={{ marginTop: 40 }}>
        <h1 className={'header'}>All Collectors</h1>
        <AllCollectorsTable collectorTvls={collectorTvls} />
      </div>
    </div>
  );
}
