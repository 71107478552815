import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CustomTable from 'components/CustomTable/CustomTable.js';
import Button from 'components/CustomButtons/Button.js';
import BigNumber from 'bignumber.js';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { converAprStringToApyString, convertApyFloatToString } from 'features/helpers/utils';
import { strategiesData } from 'features/configure';
import { useFetchApys } from '../../vault/redux/fetchApys';
import _ from 'lodash';
const MyStrategiesTable = ({ details, earnData }) => {
  const { apys, fetchApys } = useFetchApys();

  useEffect(() => {
    fetchApys();
  }, []);

  return (
    <CustomTable
      headers={['Asset', 'Platform', "You've deposit", "You've earned", 'APR → APY']}
      contents={strategiesData.map(strategie => {
        const detail = details[strategie.tokenId][strategie.name];
        const query = { tokenId: strategie.tokenId, strategyId: strategie.name };

        const displayAPR =
          apys && _.find(apys, query)
            ? convertApyFloatToString(_.find(apys, query).apy)
            : 'Loading...';
        const displayAPY = converAprStringToApyString(displayAPR);

        const targetEarnData = earnData ? _.find(earnData, query) : null;
        const displayEarning = targetEarnData
          ? new BigNumber(_.get(targetEarnData, 'user.totalUserVaultEarningValue', 0)).toFormat(1)
          : 'Loading...';

        if (isNaN(detail.collateralBalance) || parseFloat(detail.collateralBalance) == 0) return;
        return [
          <>
            <img
              src={require('assets/img/' + _.toLower(strategie.asset1) + '.png')}
              className={'icon'}
              alt=""
            />
            {strategie.asset1}
          </>,
          _.upperFirst(strategie.name),
          isNaN(detail.collateralBalance)
            ? 'Loading...'
            : parseFloat(detail.collateralBalance).toFixed(2),
          displayEarning,
          displayAPR + ' → ' + displayAPY,
          <Button color="text" component={Link} to={strategie.strategyTo}>
            Enter <ArrowRightIcon />
          </Button>,
        ];
      })}
    />
  );
};

export default MyStrategiesTable;
