import React, { useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button.js';
import Tabs from 'components/CustomTabs/Tabs.js';
import { Grid, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { useParams, Link } from 'react-router-dom';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import Trove from './components/Trove';
import FeeField from 'features/fee/FeeField';
import { strategiesData } from 'features/configure';
import { useFetchApys } from '../vault/redux/fetchApys';
import { useConnectWallet } from '../home/redux/hooks';
import { converAprStringToApyString, convertApyFloatToString } from 'features/helpers/utils';
import { useFetchVaultDetail } from '../vault/redux/hooks';
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1rem',
    fontWeight: 1000,
    marginTop: 20,
    marginBottom: 30,
    color: theme.palette.text.secondary,
  },
  subTitle: {
    fontSize: '0.9rem',
    marginBottom: 20,
  },
  textTitle: {
    textAlign: 'center',
    fontWeight: 900,
    paddingTop: 20,
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
  textValue: {
    textAlign: 'center',
    fontWeight: 900,
    padding: 20,
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: 'hidden',
    borderRadius: 5,
    marginTop: 30,
    padding: 20,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));
export default function StrategyPage(props) {
  const { tokenId, strategyId, vaultId } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const { address } = useConnectWallet();
  const [tab, setTab] = useState(parseInt(vaultId || 0));
  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);
  const { apys, fetchApys } = useFetchApys();

  useEffect(() => {
    if (address) {
      fetchVaultDetail(tokenId, strategyId);

      const id = setInterval(() => {
        fetchVaultDetail(tokenId, strategyId);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, tokenId, strategyId]);

  useEffect(() => {
    fetchApys();
  }, []);

  const stratgy = _.find(strategiesData, {
    name: strategyId,
    asset1: _.toUpper(tokenId == 'weth' ? 'ETH' : tokenId),
  });
  // const displayAPR = apys ? _.find(apys, {tokenId: stratgy : 'Loading...'
  let query = { tokenId: stratgy.tokenId, strategyId: stratgy.name };
  const displayAPR =
    apys && _.find(apys, query) ? convertApyFloatToString(_.find(apys, query).apy) : 'Loading...';

  let feeType = 'strategyDeposit';
  if ((tokenId == 'lusd' && tab == 2) || (tokenId !== 'lusd' && tab == 1)) {
    feeType = 'strategyWithdraw';
  }

  let tabsData = [
    {
      label: 'Deposit',
      content: <Deposit />,
    },
    {
      label: 'Withdraw',
      content: <Withdraw />,
    },
  ];
  if (tokenId == 'lusd') {
    tabsData = _.concat(
      {
        label: 'Trove',
        content: <Trove />,
      },
      tabsData
    );
  }
  const displayAPY = converAprStringToApyString(displayAPR);
  return (
    <>
      <Link to={'/app/strategies'} className={classes.labelLink}>
        {'<< back to strategies'}
      </Link>
      <div className={classes.card}>
        <div style={{ maxWidth: 800, margin: '0 auto' }}>
          <div className="vaultHeader">
            <img
              src={require('assets/img/' + _.toLower(stratgy.asset1) + '.png')}
              className={'lgIcon'}
            />
            <img
              src={require('assets/img/' + _.toLower(stratgy.name) + '.png')}
              className={'lgIcon'}
              style={{ marginLeft: -30, zIndex: 3 }}
            />
            {'Strategy: ' + _.toUpper(tokenId) + ' @ ' + _.upperFirst(stratgy.name)}
          </div>
          <div className="vaultSubHeader">Deposit you asset to strategies to earn interest</div>
          <div className={classes.card} style={{ padding: 0 }}>
            <div style={{ borderBottom: '1px solid white' }}>
              <div className={classes.textTitle}>
                <span style={{ paddingLeft: 40 }}> {'Base APR'} </span>
                <span style={{ marginLeft: 70 }}>{'Compounded APY'}</span>
              </div>
              <div className={classes.textValue}>
                <span style={{ marginRight: 30 }}>{displayAPR}</span> {' -> '}{' '}
                <span style={{ marginLeft: 30 }}>{displayAPY}</span>
              </div>
            </div>
            <Grid container>
              <Grid item xs={12} sm={4} style={{ borderRight: '1px solid white' }}>
                <div className={classes.textTitle}>Your wallet balance</div>
                <div className={classes.textValue}>
                  {details.daiBalance ? parseFloat(details.daiBalance).toFixed(2) : 'Loading...'}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} style={{ borderRight: '1px solid white' }}>
                <div className={classes.textTitle}>You've deposited</div>
                <div className={classes.textValue}>
                  {details.collateralBalance
                    ? parseFloat(details.collateralBalance).toFixed(2)
                    : 'Loading...'}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.textTitle}>Available to withdraw</div>
                <div className={classes.textValue}>
                  {details.availableToWithdraw
                    ? parseFloat(details.availableToWithdraw).toFixed(2)
                    : 'Loading...'}
                </div>
              </Grid>
            </Grid>
          </div>
          <Tabs tabs={tabsData} initIndex={vaultId} tabIndex={tab} setTabIndex={setTab} />
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <h1 className={classes.title}>Strategy Structure</h1>
            <div className={classes.subTitle}>
              Base APR: {displayAPR}({(parseFloat(displayAPR.replace('%', '')) / 365).toFixed(4)}
              %/day)
            </div>
            <div className={classes.subTitle}>Optimal compounds per year: 365</div>
            <div className={classes.subTitle}>
              Strategy compounded APY: {displayAPY}(
              {(parseFloat(displayAPY.replace('%', '')) / 365).toFixed(4)}%/day)
            </div>
          </div>
          <FeeField type={feeType} />
          <Button
            fullWidth
            color="primary"
            to={
              '/app/minters/' +
              _.toLower(stratgy.asset1) +
              '/' +
              _.toLower(stratgy.asset2) +
              '/' +
              stratgy.name
            }
            style={{ marginTop: 30 }}
            component={Link}
          >
            {' '}
            Next step: Go to Minters{' '}
          </Button>
        </div>
      </div>
    </>
  );
}
