import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchVaultDetail,
  useCheckDaiApproval,
  useFetchDaiApproval,
  useCheckAlusdApproval,
  useFetchAlusdApproval,
  useFetchRepay,
  useFetchEthBalance
} from 'features/vault/redux/hooks';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import { byDecimals } from 'features/helpers/bignumber';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import Container from '@material-ui/core/Container';
import IconToolTip from '../../../components/ToolTip/IconToolTip';
import { contracts, tokens } from 'features/configure';
import { useSnackbar } from 'notistack';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
const useStyles = makeStyles(styles);

export default function Repay() {

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const contractAddress = contracts.vault[tokenId][strategyId].address;
  const daiAddress = tokens[tokenId].address;
  const alUsdAddress = tokens[tokens[tokenId].correspondingMintableToken.toLowerCase()].address;
  const decimals = tokens[tokenId].decimals;

  const { details } = useFetchVaultDetail(tokenId, strategyId);

  const daiAllowance = useCheckDaiApproval().allowance;
  const checkDaiApproval = useCheckDaiApproval().checkApproval;
  const fetchDaiApproval = useFetchDaiApproval().fetchDaiApproval;
  const fetchDaiApprovalPending = useFetchDaiApproval().fetchApprovalPending;

  const alusdAllowance = useCheckAlusdApproval().allowance;
  const checkAlusdApproval = useCheckAlusdApproval().checkAlusdApproval;
  const fetchAlusdApproval = useFetchAlusdApproval().fetchAlusdApproval;
  const fetchAlusdApprovalPending = useFetchAlusdApproval().fetchApprovalPending;

  const { fetchRepay, fetchRepayPending } = useFetchRepay();

  const [isNeedDaiApproval, setIsNeedDaiApproval] = useState(true);
  const [approvalDaiAble, setApprovalDaiAble] = useState(true);

  const [isNeedAlusdApproval, setIsNeedAlusdApproval] = useState(true);
  const [approvalAlusdAble, setApprovalAlusdAble] = useState(true);

  const [repayAble, setRepayAble] = useState(true);

  const { fetchEthBalance, ethBalance } = useFetchEthBalance();

  useEffect(() => {
    if (web3, address) {
      fetchEthBalance({ web3, address });

      const id = setInterval(() => {
        fetchEthBalance({ web3, address });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    setIsNeedDaiApproval(Boolean(daiAllowance === 0));
  }, [daiAllowance]);

  useEffect(() => {
    setApprovalDaiAble(!Boolean(fetchDaiApprovalPending));
  }, [fetchDaiApprovalPending]);

  useEffect(() => {
    setIsNeedAlusdApproval(Boolean(alusdAllowance === 0));
  }, [alusdAllowance]);

  useEffect(() => {
    setApprovalAlusdAble(!Boolean(fetchAlusdApprovalPending));
  }, [fetchAlusdApprovalPending]);

  useEffect(() => {
    setRepayAble(!Boolean(fetchRepayPending));
  }, [fetchRepayPending]);

  useEffect(() => {
    if (address) {
      checkDaiApproval(daiAddress, contractAddress);
      checkAlusdApproval(alUsdAddress, contractAddress);
      const id = setInterval(() => {
        checkDaiApproval(daiAddress, contractAddress);
        checkAlusdApproval(alUsdAddress, contractAddress);
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address, daiAddress, alUsdAddress, contractAddress]);


  const onApprovalDai = () => {
    fetchDaiApproval(daiAddress, contractAddress);
  };

  const onApprovalAlusd = () => {
    fetchAlusdApproval(alUsdAddress, contractAddress);
  };

  const [currency, setCurrency] = useState('waUSD');
  const [state, setState] = useState({
    waUSD: { amount: '', slider: 0, },
    DAI: { amount: '', slider: 0, },
    ETH: { amount: '', slider: 0, }
  });

  const onRepay = () => {
    let amountValue;
    if (currency === 'waUSD') {
      // repay waUSD
      amountValue = state.waUSD.amount
        ? state.waUSD.amount.replaceAll(',', '')
        : state.waUSD.amount;

      fetchRepay({
        address,
        web3,
        daiAmount: 0,
        alusdAmount: new BigNumber(amountValue)
          .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
          .toString(10),
        ethAmount: 0,
        tokenId,
        strategyId,
        isETH: false
      })
        .then(() => {
          enqueueSnackbar(t('Vault-RepaySuccess'), { variant: 'success' });
          setState({
            ...state, [currency]: {
              amount: '',
              slider: 0
            }
          });
        })
        .catch(error => enqueueSnackbar(t('Vault-RepayError', { error }), { variant: 'error' }));
    } else if (currency === 'DAI') {
      // repay DAI
      amountValue = state.DAI.amount
        ? state.DAI.amount.replaceAll(',', '')
        : state.DAI.amount;

      fetchRepay({
        address,
        web3,
        daiAmount: new BigNumber(parseFloat(amountValue))
          .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
          .toString(10),
        alusdAmount: 0,
        ethAmount: 0,
        tokenId,
        strategyId,
        isETH: false
      })
        .then(() => {
          enqueueSnackbar(t('Vault-RepaySuccess'), { variant: 'success' });
          setState({
            ...state, [currency]: {
              amount: '',
              slider: 0
            }
          });
        })
        .catch(error => enqueueSnackbar(t('Vault-RepayError', { error }), { variant: 'error' }));
    } else {
      // repay ETH
      amountValue = state.ETH.amount
        ? state.ETH.amount.replaceAll(',', '')
        : state.ETH.amount;

      fetchRepay({
        address,
        web3,
        daiAmount: 0,
        alusdAmount: 0,
        ethAmount: new BigNumber(parseFloat(amountValue))
          .multipliedBy(new BigNumber(10).exponentiatedBy(18))
          .toString(10),
        tokenId,
        strategyId,
        isETH: true
      })
        .then(() => {
          enqueueSnackbar(t('Vault-RepaySuccess'), { variant: 'success' });
          setState({
            ...state, [currency]: {
              amount: '',
              slider: 0
            }
          });
        })
        .catch(error => enqueueSnackbar(t('Vault-RepayError', { error }), { variant: 'error' }));
    }
  };

  const getTotal = () => {
    let total = 0
    if (currency === 'waUSD') {
      total = new BigNumber(details.alusdBalance).isGreaterThan(new BigNumber(details.remainingDebt)) ? details.remainingDebt : details.alusdBalance;
    } else if (currency === 'DAI') {
      total = new BigNumber(details.daiBalance).isGreaterThan(new BigNumber(details.remainingDebt)) ? details.remainingDebt : details.daiBalance;
    } else {
      total = new BigNumber(ethBalance).isGreaterThan(new BigNumber(details.remainingDebt)) ? details.remainingDebt : ethBalance;
    }
    return total
  }

  const setMaximumInput = event => {
    const { name } = event.target;
    let value = getTotal();

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let target = name || currency
    setState({
      ...state, [target]: {
        amount: inputFinalVal(value, value, decimals),
      }
    });
  };

  const changeDetailInputValue = event => {
    const { value, name } = event.target;
    let total = getTotal()

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    let target = name || currency
    setState({
      ...state, [target]: {
        amount: inputFinalVal(value, total, decimals),
        slider: sliderNum,
      }
    });
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  let currencyList = [
    { label: tokens[tokenId].correspondingMintableToken, value: "waUSD" },
    { label: tokens[tokenId].token, value: "DAI" },
  ];
  if (tokenId == "weth") {
    currencyList.push({ label: "ETH", value: "ETH" })
  }
  return (
    <div className={classes.sliderDetailContainer} >
      {/* <IconToolTip>
        Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a> to see more details.
      </IconToolTip> */}
      <Container maxWidth="md" disableGutters={true}>

        <div className={classes.showDetailLeft}>
          {tokenId === 'weth' ?
            <h3 className={classes.detailHeader}>Repay the remaining {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat() : 0} {tokens[tokenId].correspondingMintableToken} debt from your wallet using {tokens[tokenId].correspondingMintableToken}, {tokens[tokenId].token} and/or ETH</h3>
            :
            <h3 className={classes.detailHeader}>Repay the remaining {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat() : 0} {tokens[tokenId].correspondingMintableToken} debt from your wallet using {tokens[tokenId].correspondingMintableToken} and/or {tokens[tokenId].token}</h3>
          }
          <div className={classes.detailContent}>
            {t('Remaining Debt')}: {details.remainingDebt ? new BigNumber(details.remainingDebt).toFormat() : 0}
          </div>
          <div className={classes.detailContent}>
            {t('Vault-Balance')}: {currency === 'waUSD' ? details.alusdBalance : currency === 'DAI' ? details.daiBalance : ethBalance}
          </div>
        </div>
        <FormControl fullWidth variant="outlined" className={classes.numericInput} >
          <CustomOutlinedInput value={state[currency].amount} onChange={changeDetailInputValue} setMax={setMaximumInput}
            startAdornment={
              <InputAdornment position="start" >
                <FormControl style={{ minWidth: 120 }} focused={false}>
                  <CustomDropdown
                    type={"startAdornment"}
                    value={currency}
                    data={currencyList}
                    onSelect={handleCurrencyChange}
                  />

                </FormControl>
              </InputAdornment>
            } />

        </FormControl>

        <div className={classes.showDetailButton}>
          {currency === 'waUSD' ?
            isNeedAlusdApproval ? (
              <Button
                fullWidth
                color="green"
                disabled={!Boolean(approvalAlusdAble)}
                onClick={onApprovalAlusd}
              >
                {t('Approve')}
              </Button>
            ) : (
              <Button
                fullWidth
                color="green"
                disabled={!Boolean(repayAble) || !Boolean(state.waUSD.amount) || getTotal() == '0'}
                onClick={onRepay}
              >
                {t('Repay')}
              </Button>
            )
            : currency === 'DAI' ?
              (isNeedDaiApproval ?
                (
                  <Button
                    fullWidth
                    color="green"
                    disabled={!Boolean(approvalDaiAble)}
                    onClick={onApprovalDai}
                  >
                    {t('Approve')}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    color="green"
                    disabled={!Boolean(repayAble) || !Boolean(state.DAI.amount) || getTotal() == '0'}
                    onClick={onRepay}
                  >
                    {t('Repay')}
                  </Button>
                )
              ) : (
                <Button
                  fullWidth
                  color="green"
                  disabled={!Boolean(repayAble) || !Boolean(state.ETH.amount) || getTotal() == '0'}
                  onClick={onRepay}
                >
                  {t('Repay')}
                </Button>
              )
          }

        </div>
      </Container>

    </div>
  );
}
