// 1. user dai balance
// 2. collateral balance = Alchemix.getCDPTotalDeposited(userAddr)
// 3. available to withdraw =
//    (Alchemix.getCDPTotalDeposited(userAddr) - Alchemix.getCDPTotalDebt(userAddr)) * COLL.LIMIT/100
// 4. Remaining alUSD Debt = Alchemix.getCDPTotalDebt(userAddr)
// 5. available to borrow = available to withdraw  / COLL.LIMIT/100 + Alchemix.getCDPTotalCredit(userAddr)
// 6. user alUSD balance
// 7. global mintable alUsd = alUsd.ceiling(alchmist.address) - alUsd.hasMinted(alchmist.address)

import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  VAULT_DETAIL_FETCH_BEGIN,
  VAULT_DETAIL_FETCH_SUCCESS,
  VAULT_DETAIL_FETCH_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import {
  multiCallABI,
  erc20ABI,
  wethABI,
  alchmistABI,
  alUSDABI,
  tokens,
  contracts,
} from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import { strategiesData } from 'features/configure';
export function fetchAllVaultDetails() {
  return dispatch => {
    strategiesData.map(strategy => {
      dispatch(fetchVaultDetail(strategy.tokenId, strategy.name));
    });
  };
}

export function fetchVaultDetail(tokenId, strategyId) {
  return (dispatch, getState) => {
    dispatch({
      type: VAULT_DETAIL_FETCH_BEGIN,
      tokenId: tokenId,
      strategyId: strategyId,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const wethPrice = new BigNumber(price.priceData.ethPrice);

      const { address, web3 } = home;
      const decimals = tokens[tokenId].decimals;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const vaultAddress = contracts.vault[tokenId][strategyId].address;

      let multicallContract = new web3.eth.Contract(multiCallABI, contracts.multicall.address);
      let daiContract = new web3.eth.Contract(erc20ABI, tokens.dai.address);
      let alusdContract = new web3.eth.Contract(alUSDABI, tokens.wausd.address);
      if (tokenId === 'wbtc') {
        daiContract = new web3.eth.Contract(erc20ABI, tokens.wbtc.address);
        alusdContract = new web3.eth.Contract(alUSDABI, tokens.wabtc.address);
      } else if (tokenId === 'lusd') {
        daiContract = new web3.eth.Contract(erc20ABI, tokens.lusd.address);
        alusdContract = new web3.eth.Contract(alUSDABI, tokens.walusd.address);
      } else if (tokenId === 'weth') {
        daiContract = new web3.eth.Contract(wethABI, tokens.weth.address);
        alusdContract = new web3.eth.Contract(alUSDABI, tokens.waeth.address);
      } else if (tokenId === 'link') {
        daiContract = new web3.eth.Contract(erc20ABI, tokens.link.address);
        alusdContract = new web3.eth.Contract(alUSDABI, tokens.walink.address);
      }

      const alchemixContract = new web3.eth.Contract(alchmistABI, vaultAddress);

      const calls = [
        { result: daiContract.methods.balanceOf(address) },
        { result: alchemixContract.methods.getCdpTotalDeposited(address) },
        { result: alchemixContract.methods.getCdpTotalDebt(address) },
        { result: alchemixContract.methods.collateralizationLimit() },
        { result: alchemixContract.methods.getCdpTotalCredit(address) },
        { result: alusdContract.methods.balanceOf(address) },
        { result: alusdContract.methods.ceiling(vaultAddress) },
        { result: alusdContract.methods.hasMinted(vaultAddress) },
        { result: alchemixContract.methods.totalDeposited() },
        { result: multicallContract.methods.getEthBalance(address) },
      ];

      multicall
        .all([calls])
        .then(([results]) => {
          const daiBalance = new BigNumber(results[0].result);
          const collateralBalance = new BigNumber(results[1].result);
          const remainingDebt = new BigNumber(results[2].result);
          const colLimit =
            results[3].result.length == 0 ? 2 : new BigNumber(results[3].result[0]) / 10e18;
          const availableToWithdraw = collateralBalance.minus(remainingDebt.multipliedBy(colLimit));
          const totalCredit = new BigNumber(results[4].result);
          const availableToBorrow = totalCredit.plus(availableToWithdraw.div(colLimit));
          const alusdBalance = new BigNumber(results[5].result);
          const alusdCeiling = new BigNumber(results[6].result);
          const alusdHasMinted = new BigNumber(results[7].result);
          const tlv = new BigNumber(results[8].result);
          const ethBalance = new BigNumber(results[9].result);

          const output = {
            daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
            collateralBalance: convertAmountFromRawNumber(collateralBalance, decimals),
            availableToWithdraw: convertAmountFromRawNumber(availableToWithdraw, decimals),
            remainingDebt: convertAmountFromRawNumber(remainingDebt, decimals),
            availableToBorrow: convertAmountFromRawNumber(availableToBorrow, decimals),
            alusdBalance: convertAmountFromRawNumber(alusdBalance, decimals),
            alusdHasMinted: convertAmountFromRawNumber(alusdHasMinted, decimals),
            alUsdLendingRate: (alusdHasMinted / alusdCeiling) * 100,
            globalMintable: convertAmountFromRawNumber(alusdCeiling - alusdHasMinted, decimals),
            totalLockedValue: convertAmountFromRawNumber(tlv, decimals),
            ethBalance: convertAmountFromRawNumber(ethBalance, 18),
            wethPrice: wethPrice,
          };

          dispatch({
            type: VAULT_DETAIL_FETCH_SUCCESS,
            tokenId: tokenId,
            strategyId: strategyId,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: VAULT_DETAIL_FETCH_FAILURE,
            tokenId: tokenId,
            strategyId: strategyId,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchAllVaultDetails() {
  const dispatch = useDispatch();

  const { details } = useSelector(
    state => ({
      details: state.vault.details,
    }),
    shallowEqual
  );

  const boundAction = useCallback(() => {
    return dispatch(fetchAllVaultDetails());
  }, [dispatch]);

  return {
    details,
    fetchAllVaultDetails: boundAction,
  };
}

export function useFetchVaultDetail(tokenId, strategyId) {
  const dispatch = useDispatch();

  const { details, fetchVaultDetailPending, fetchVaultDetailDone } = useSelector(
    state => ({
      details: state.vault.details[tokenId][strategyId],
      fetchVaultDetailDone: state.vault.fetchVaultDetailDone[tokenId][strategyId],
      fetchVaultDetailPending: state.vault.fetchVaultDetailPending[tokenId][strategyId],
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (tokenId, strategyId) => {
      return dispatch(fetchVaultDetail(tokenId, strategyId));
    },
    [dispatch]
  );

  return {
    details,
    fetchVaultDetail: boundAction,
    fetchVaultDetailDone,
    fetchVaultDetailPending,
  };
}

export function reducer(state, action) {
  let { details, fetchVaultDetailPending, fetchVaultDetailDone } = state;

  switch (action.type) {
    case VAULT_DETAIL_FETCH_BEGIN:
      fetchVaultDetailPending[action.tokenId][action.strategyId] = true;
      return {
        ...state,
        fetchVaultDetailPending,
      };

    case VAULT_DETAIL_FETCH_SUCCESS:
      details[action.tokenId][action.strategyId] = action.data;
      fetchVaultDetailDone[action.tokenId][action.strategyId] = true;
      fetchVaultDetailPending[action.tokenId][action.strategyId] = false;
      return {
        ...state,
        details,
        fetchVaultDetailDone,
        fetchVaultDetailPending,
      };

    case VAULT_DETAIL_FETCH_FAILURE:
      fetchVaultDetailPending[action.tokenId][action.strategyId] = false;
      return {
        ...state,
        fetchVaultDetailPending,
      };
    default:
      return state;
  }
}
