import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_SINGLE_AIRDROPS_BEGIN,
  FETCH_SINGLE_AIRDROPS_SUCCESS,
  FETCH_SINGLE_AIRDROPS_FAILURE,
} from './constants';
import { extendLock } from '../../web3';
import { fetchVotingEscrowDetails } from './action';
import { airdropABI, contracts } from '../../configure';
import { MultiCall } from 'eth-multicall';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import BigNumber from 'bignumber.js';
export function fetchSingleAirdrop({ address, web3, contractAddress }) {
  return dispatch => {
    dispatch({
      type: FETCH_SINGLE_AIRDROPS_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, contracts.multicall.address);
      const airdropContract = new web3.eth.Contract(airdropABI, contractAddress);
      const calls = [
        { result: airdropContract.methods.getInitialAirdropAmount(address) },
        { result: airdropContract.methods.getDistributedAmount(address) },
        { result: airdropContract.methods.getAvailableAmount(address) },
      ]
      multicall
        .all([calls])
        .then(([results]) => {
          const output = {
            initAmount: convertAmountFromRawNumber(new BigNumber(results[0].result)),
            distributedAmount: convertAmountFromRawNumber(new BigNumber(results[1].result)),
            availableAmount: convertAmountFromRawNumber(new BigNumber(results[2].result)),
          }
          dispatch({
            type: FETCH_SINGLE_AIRDROPS_SUCCESS,
            data: output,
            index: contractAddress
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: FETCH_SINGLE_AIRDROPS_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchSingleAirdrop() {
  const dispatch = useDispatch();

  const { fetchSingleAirdropPending, singleAirdrop } = useSelector(state => ({
    singleAirdrop: state.pools.singleAirdrop,
    fetchSingleAirdropPending: state.pools.fetchSingleAirdropPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchSingleAirdrop(data));
    },
    [dispatch]
  );

  return {
    singleAirdrop,
    fetchSingleAirdrop: boundAction,
    fetchSingleAirdropPending,
  };
}

export function reducer(state, action) {
  let { fetchSingleAirdropPending, singleAirdrop } = state;
  switch (action.type) {
    case FETCH_SINGLE_AIRDROPS_BEGIN:
      fetchSingleAirdropPending = true;
      return {
        ...state,
        fetchSingleAirdropPending
      };

    case FETCH_SINGLE_AIRDROPS_SUCCESS:
      fetchSingleAirdropPending = false;
      singleAirdrop[action.index] = action.data
      return {
        ...state,
        singleAirdrop,
        fetchSingleAirdropPending
      };

    case FETCH_SINGLE_AIRDROPS_FAILURE:
      fetchSingleAirdropPending = false;
      return {
        ...state,
        fetchSingleAirdropPending
      };

    default:
      return state;
  }
}
