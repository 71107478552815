import React from 'react';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.js';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const MenuItems = ({ handleDrawerToggle, style, footer = false, color = "white" }) => {
  const { t } = useTranslation();

  return (
    <div style={style}>
      <Hidden mdUp>
        <ListItem button component="a" href="https://wasabix-finance.gitbook.io/wasabix_finance/code/audit-report" onClick={handleDrawerToggle} style={{ marginTop: 20 }}>
          <ListItemText primary={t('Audit')} />
        </ListItem>
        <ListItem button component="a" href="https://github.com/wasabi-swap-team" onClick={handleDrawerToggle} style={{ marginTop: 20 }}>
          <ListItemText primary={t('Github')} />
        </ListItem>
        <ListItem button component="a" href="https://t.me/WasabixFinance" onClick={handleDrawerToggle} style={{ marginTop: 20 }}>
          <ListItemText primary={t('Telegram')} />
        </ListItem>
        <ListItem button component="a" href="https://twitter.com/WasabixFinance" onClick={handleDrawerToggle} style={{ marginTop: 20 }}>
          <ListItemText primary={t('Twitter')} />
        </ListItem>
        <ListItem button component="a" href="https://wasabix-finance.gitbook.io/wasabix_finance/" onClick={handleDrawerToggle} style={{ marginTop: 20 }}>
          <ListItemText primary={t('Docs')} />
        </ListItem>
        <LinkButton href={"/app"} title="Enter App" />
      </Hidden>
      <Hidden smDown>
        <div>
          <Box display="flex" justifyContent={"flex-start"} alignItems={"center"} style={{ marginLeft: '-16px' }}>
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Audit")}
              href="https://wasabix-finance.gitbook.io/wasabix_finance/code/audit-report"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Github")}
              href="https://github.com/wasabi-swap-team"
            />
            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Telegram")}
              href="https://t.me/WasabixFinance"
            />

            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Twitter")}
              href="https://twitter.com/WasabixFinance"
            />

            <LinkHref
              color={color}
              style={{ padding: 0, paddingRight: footer ? 32 : 0 }}
              title={t("Docs")}
              href="https://wasabix-finance.gitbook.io/wasabix_finance/"
            />
            <LinkButton href={"/app"} title="Enter App" />
          </Box>
        </div>
      </Hidden>
    </div>
  );
};
const Href = styled.a`
  a:hover {
    text-decoration: none;
  }
`
const ContainerDiv = styled.div`
  padding-left: 20px;
  a:hover {
    text-decoration: none;
  }
`
const StyledDiv = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${props => props.color};
  :hover {
    
  }
`
function LinkHref({ style, title, href, color }) {
  return (
    <ContainerDiv style={style}>
      <a href={href} target="_blank"
        style={{ textDecoration: "none" }}>
        <StyledDiv color={color}>{title}</StyledDiv>
      </a>
    </ContainerDiv>
  );
}
function LinkButton({ style, title, href, color }) {
  return (
    <ContainerDiv style={style}>
      <Link to={href}
        style={{ textDecoration: "none" }}>
        <Button color="greenOutlined" >{title}</Button>
      </Link>
    </ContainerDiv>
  );
}

export default MenuItems;
