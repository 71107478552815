import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  useFetchVaultDetail,
  useFetchBorrow
} from 'features/vault/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useSnackbar } from 'notistack';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import { byDecimals, calculateReallyNum } from 'features/helpers/bignumber';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import Container from '@material-ui/core/Container';
import IconToolTip from '../../../components/ToolTip/IconToolTip';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { tokens } from 'features/configure';

const useStyles = makeStyles(styles);

export default function Borrow() {

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const decimals = tokens[tokenId].decimals;

  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);
  const { fetchBorrow, fetchBorrowPending } = useFetchBorrow();
  const [borrowBalance, setBorrowBalance] = useState({
    amount: ''
  });

  let strategyName = 'yearn.finance';

  if (strategyId === 'alpaca') {
    strategyName = 'alpaca.finance';
  } else if (strategyId === 'pickle') {
    strategyName = 'pickle.finance';
  } else if (strategyId === 'vesper') {
    strategyName = 'vesper.finance';
  } else if (strategyId === 'liquity') {
    strategyName = 'liquity.org';
  }


  const onBorrow = () => {
    let amountValue = borrowBalance.amount
      ? borrowBalance.amount.replaceAll(',', '')
      : borrowBalance.amount;

    fetchBorrow({
      address,
      web3,
      amount: new BigNumber(amountValue)
        .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
        .toString(10),
      tokenId,
      strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Vault-BorrowSuccess'), { variant: 'success' });
        setBorrowBalance({
          amount: ''
        });
      })
      .catch(error => enqueueSnackbar(t('Vault-BorrowError', { error }), { variant: 'error' }));
  };

  const getTotal = () => {
    return new BigNumber(details.globalMintable).isGreaterThan(new BigNumber(details.availableToBorrow)) ? details.availableToBorrow : details.globalMintable;
  }

  const setMaximumInput = event => {
    let value = getTotal();

    if (!inputLimitPass(value, 18)) {
      return;
    }
    setBorrowBalance({
      amount: inputFinalVal(value, value, decimals)
    });
  }

  const changeDetailInputValue = event => {
    let value = event.target.value;
    const total = getTotal()

    if (!inputLimitPass(value, 18)) {
      return;
    }

    setBorrowBalance({
      amount: inputFinalVal(value, total, decimals)
    });

  };

  return (
    <div className={classes.sliderDetailContainer} >
      {/* <IconToolTip>
        Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a> to see more details.
      </IconToolTip> */}
      <Container maxWidth="md" disableGutters={true}>

        <div className={classes.showDetailLeft}>
          <h3 className={classes.detailHeader}>Borrow/Mint up to 50% the value of your collateral in {tokens[tokenId].correspondingMintableToken}. Your debt will be automatically paid down by yield from {strategyName}</h3>
          <div className={classes.detailContent}>{"Available to Borrow/Mint"}: {details.availableToBorrow ? new BigNumber(details.availableToBorrow).toFormat() : 0}<br />
          </div>
        </div>

        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={borrowBalance.amount} onChange={changeDetailInputValue} setMax={setMaximumInput} />
        </FormControl>

        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={!Boolean(borrowBalance.amount) || getTotal() == '0'}
            onClick={() => onBorrow()}
          >
            {t('Borrow')}
          </Button>
        </div>
      </Container>
    </div>
  );
}
