import { airdropABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const claimAirdrops = async ({ web3, address, contractAddress, dispatch }) => {
  const contractObj = new web3.eth.Contract(airdropABI, contractAddress);
  const data = await _claimAirdrops({ web3, contractObj, address, dispatch });
  return data;
};

const _claimAirdrops = ({ web3, contractObj, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contractObj.methods
      .withdraw()
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
