import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './history';
import homeReducer from 'features/home/redux/reducer';
import vaultReducer from 'features/vault/redux/reducer';
import commonReducer from 'features/common/redux/reducer';
import transmuterReducer from 'features/collector/redux/reducer';
import poolsReducer from 'features/pools/redux/reducer';
import dashboardReducer from 'features/info/redux/reducer';
import feeReducer from 'features/fee/redux/reducer';
import { reducer as priceReducer } from '../components/Price/fetchPrice';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  vault: vaultReducer,
  common: commonReducer,
  transmuter: transmuterReducer,
  pools: poolsReducer,
  price: priceReducer,
  dashboard: dashboardReducer,
  fee: feeReducer,
};

export default combineReducers(reducerMap);
