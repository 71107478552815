import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  FETCH_FORCE_TRANSMUTER_BEGIN,
  FETCH_FORCE_TRANSMUTER_SUCCESS,
  FETCH_FORCE_TRANSMUTER_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { transmuterABI, tokens, contracts } from 'features/configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

export function fetchAllForceTransmuterDetail() {
  return (dispatch) => {
    return Object.keys(contracts.transmuter).map(tokenId => {
      dispatch(fetchForceTransmuterDetail(tokenId))
    })
  }
}

export function fetchForceTransmuterDetail(tokenId) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FORCE_TRANSMUTER_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {

      const { home } = getState();
      const { address, web3 } = home;

      const decimals = tokens[tokenId].decimals;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const transmuterAddress = contracts.transmuter[tokenId].address;
      const transmuterContract = new web3.eth.Contract(transmuterABI, transmuterAddress);

      const nextUser = await transmuterContract.methods.nextUser().call()
      // console.log('nextUser', nextUser)

      const userListCalls = []
      const userAddressArray = []

      //get user address array by userList
      for (let i = 0; i < nextUser; i++) {
        const call = { result: transmuterContract.methods.userList(i) }
        userListCalls.push(call)
      }

      // console.log('userListCalls', userListCalls)
      multicall
        .all([userListCalls])
        .then(([results]) => {
          for (let i = 0; i < nextUser; i++) {
            const userAddress = results[i].result;
            // console.log('userAddress', i, userAddress)
            userAddressArray.push(userAddress)
          }
          // console.log('userAddressArray', userAddressArray)
          const userInfoCalls = []
          const userInfoArray = []

          //getUserInfo
          for (let x = 0; x < userAddressArray.length; x++) {
            const call = { result: transmuterContract.methods.userInfo(userAddressArray[x]) }
            userInfoCalls.push(call)
          }

          multicall
            .all([userInfoCalls])
            .then(([results]) => {
              for (let x = 0; x < userAddressArray.length; x++) {
                const userInfo = results[x].result;
                // console.log('userInfo', userInfo)

                if (userInfo) {
                  const depositedAl = new BigNumber(userInfo[0]);
                  const inbucket = new BigNumber(userInfo[2]);
                  const available = inbucket - depositedAl
                  if (inbucket.gt(depositedAl) && available > 1e10) {
                    userInfoArray.push({
                      address: userAddressArray[x],
                      available: convertAmountFromRawNumber(available),
                      depositedAl: convertAmountFromRawNumber(depositedAl),
                      inbucket: convertAmountFromRawNumber(inbucket)
                    });
                  }
                }
              }

              dispatch({
                type: FETCH_FORCE_TRANSMUTER_SUCCESS,
                data: userInfoArray,
                tokenId: tokenId
              });

              // console.log('userInfoArray', userInfoArray)
              resolve()
            }).catch(error => {
              dispatch({
                type: FETCH_FORCE_TRANSMUTER_FAILURE,
              });
              return reject(error.message || error);
            });
        });
    });

    return promise;
  };
}

export function useFetchForceTransmuterDetail() {
  const dispatch = useDispatch();

  const { forceTransmuteDetails, fetchForceTransmuterDetailPending, fetchForceTransmuterDetailDone } = useSelector(
    state => ({
      forceTransmuteDetails: state.transmuter.forceTransmuteDetails,
      fetchForceTransmuterDetailDone: state.transmuter.fetchForceTransmuterDetailDone,
      fetchForceTransmuterDetailPending: state.transmuter.fetchForceTransmuterDetailPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchForceTransmuterDetail(data));
    },
    [dispatch]
  );

  const boundAction1 = useCallback(
    () => {
      return dispatch(fetchAllForceTransmuterDetail());
    },
    [dispatch]
  );

  return {
    forceTransmuteDetails,
    fetchForceTransmuterDetail: boundAction,
    fetchAllForceTransmuterDetail: boundAction1,
    fetchForceTransmuterDetailDone,
    fetchForceTransmuterDetailPending,
  };
}

export function reducer(state, action) {
  let { forceTransmuteDetails } = state;

  switch (action.type) {
    case FETCH_FORCE_TRANSMUTER_BEGIN:
      return {
        ...state,
        fetchForceTransmuterDetailPending: true,
      };

    case FETCH_FORCE_TRANSMUTER_SUCCESS:
      forceTransmuteDetails[action.tokenId] = action.data;
      return {
        ...state,
        forceTransmuteDetails,
        fetchForceTransmuterDetailDone: true,
        fetchForceTransmuterDetailPending: false,
      };

    case FETCH_FORCE_TRANSMUTER_FAILURE:
      return {
        ...state,
        fetchForceTransmuterDetailPending: false,
      };

    default:
      return state;
  }
}
