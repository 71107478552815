import BigNumber from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';
import { useConnectWallet } from '../../home/redux/hooks';
import moment from 'moment';
import _ from 'lodash';
import { currentChain, websiteUrl } from 'features/configure';
import { useFetchAirdrops } from 'features/info/redux/hooks';
import { useFetchClaimAirdrops, useFetchSingleAirdrop } from 'features/pools/redux/hooks';
const styles = theme => ({
  title: {
    lineHeight: 1,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
  },

  value: {
    lineHeight: 1.5,
    textAlign: 'left',
    color: theme.palette.text.primary,
    margin: 18,
  },
  cardTitle: {
    fontSize: '0.9rem',
    marginBottom: 30,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  cardValue: {
    fontSize: '0.9rem',
    fontWeight: 600,
    marginBottom: 30,
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    fontWeight: 600,
  },
  red: {
    color: theme.palette.text.red,
  },
});

const AirdropsReward = ({ reward }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const [hasReward, setHasReward] = useState(false);
  const { fetchAirdrops } = useFetchAirdrops();
  const { claimAirdrops } = useFetchClaimAirdrops();
  const { fetchSingleAirdrop, singleAirdrop } = useFetchSingleAirdrop();

  const onClaimReward = contractAddress => {
    claimAirdrops({
      address,
      web3,
      contractAddress,
    })
      .then(() => {
        enqueueSnackbar('Claim Airdrops Success', { variant: 'success' });
        fetchAirdrops({ address });
      })
      .catch(error => console.log(error));
  };
  useEffect(() => {
    if (!reward) return;
    const isClaimable = currentChain == reward.chainName;
    if (isClaimable) {
      fetchSingleAirdrop({ address, web3, contractAddress: reward.contract });
      const id = setInterval(() => {
        fetchSingleAirdrop({ address, web3, contractAddress: reward.contract });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [reward]);

  if (!reward) return;
  const airdropInfo = singleAirdrop ? singleAirdrop[reward.contract] : '';
  const isClaimable = currentChain == reward.chainName;
  const toDecimal = reward.toDecimal || 2;
  return (
    <>
      <div className={classes.title} style={{ marginBottom: 30 }}>
        Earn {reward.symbol}
      </div>
      <div className="card" style={{ margin: 10, padding: 0 }}>
        <Grid container alignItems="center" style={{ padding: '0 20px', marginBottom: '-20px' }}>
          <Grid item xs={12} style={{ textAlign: 'center', padding: '20px 0' }}>
            <img src={reward.imgUrl} className="lgIcon" alt="" />
          </Grid>
          <Grid item xs={5} className={classes.cardTitle}>
            Token Symbol
          </Grid>
          <Grid item xs={7} className={classes.cardValue}>
            {reward.symbol}{' '}
          </Grid>
          <Grid item xs={5} className={classes.cardTitle}>
            Token Network
          </Grid>
          <Grid item xs={7} className={classes.cardValue}>
            {reward.network}{' '}
          </Grid>
          <Grid item xs={4} className={classes.cardTitle}>
            Snapshot Time
          </Grid>
          <Grid item xs={8} className={classes.cardValue}>
            {reward.snapshotTime ? moment(reward.snapshotTime).format() : '-'}{' '}
          </Grid>
          <Grid item xs={6} className={classes.cardTitle}>
            Available to claim
          </Grid>
          <Grid item xs={6} className={classes.cardValue}>
            {airdropInfo
              ? `${parseFloat(airdropInfo.initAmount).toFixed(toDecimal)}`
              : parseFloat(reward.distributeAmount).toFixed(toDecimal)}
            {` ${reward.symbol}`}
          </Grid>
          <Grid item xs={6} className={classes.cardTitle}>
            Token Value
          </Grid>
          <Grid item xs={6} className={classes.cardValue}>
            $
            {reward.tokenPrice
              ? `${new BigNumber(reward.tokenPrice * reward.distributeAmount).toFormat(1)}`
              : ''}
          </Grid>
          <Grid item xs={6} className={classes.cardTitle}>
            Airdrops APY
          </Grid>
          <Grid item xs={6} className={classes.cardValue}>
            {reward.rewardsAPY ? parseFloat(reward.rewardsAPY * 100).toFixed(1) : ''}%
          </Grid>
        </Grid>
        <hr style={{ width: '100%' }} />
        <Grid item xs={12} className={classes.value}>
          {/* <div style={{ marginBottom: 15 }} className={isClaimable ? "" : classes.red}>Please switch to the token network to claim airdrops. <a
              href="" className={isClaimable ? classes.link : `${classes.link} ${classes.red}`} target="_blank">{"How>>"}</a></div> */}
          {isClaimable ? (
            <Button
              fullWidth
              color="green"
              disabled={!airdropInfo || airdropInfo.availableAmount < 1e-8}
              onClick={() => {
                onClaimReward(reward.contract);
              }}
            >
              {airdropInfo && airdropInfo.availableAmount > 1e-8 ? 'Claim' : 'Claimed'}
            </Button>
          ) : (
            <Button
              fullWidth
              color="green"
              onClick={() => {
                window.location.href = websiteUrl[reward.chainName] + '/airdrops';
              }}
            >
              Go to Claim
            </Button>
          )}
        </Grid>
      </div>
    </>
  );
};

export default AirdropsReward;
