import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  POOLS_FETCH_WITHDRAW_EARNING_BEGIN,
  POOLS_FETCH_WITHDRAW_EARNING_SUCCESS,
  POOLS_FETCH_WITHDRAW_EARNING_FAILURE,
} from './constants';
import { withdrawEarning } from '../../web3';
import { fetchRewardVestingDetails } from './action';

export function fetchWithdrawEarning({ address, web3, contractAddress, amount }) {
  return dispatch => {
    dispatch({
      type: POOLS_FETCH_WITHDRAW_EARNING_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      withdrawEarning({ web3, address, contractAddress, amount, dispatch })
        .then(data => {
          dispatch({
            type: POOLS_FETCH_WITHDRAW_EARNING_SUCCESS,
            data,
          });
          dispatch(fetchRewardVestingDetails());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_WITHDRAW_EARNING_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchWithdrawEarning() {
  const dispatch = useDispatch();

  const { fetchWithdrawEarningPending } = useSelector(state => ({
    fetchWithdrawEarningPending: state.pools.fetchWithdrawEarningPending
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchWithdrawEarning(data));
    },
    [dispatch]
  );

  return {
    fetchWithdrawEarning: boundAction,
    fetchWithdrawEarningPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOLS_FETCH_WITHDRAW_EARNING_BEGIN:
      return {
        ...state,
        fetchWithdrawEarningPending: true
      };

    case POOLS_FETCH_WITHDRAW_EARNING_SUCCESS:
      return {
        ...state,
        fetchWithdrawEarningPending: false
      };

    case POOLS_FETCH_WITHDRAW_EARNING_FAILURE:
      return {
        ...state,
        fetchWithdrawEarningPending: false
      };

    default:
      return state;
  }
}
