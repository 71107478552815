import BigNumber from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import Button from 'components/CustomButtons/Button.js';
import MeterialButton from '@material-ui/core/Button';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from '../../home/redux/hooks';
import { contracts } from "features/configure"
import {
  useFetchVotingEscrowDetails,
  useFetchCreateLock,
  useFetchAddAmount,
  useFetchExtendLock,
  useFetchLockerApproval,
  useFetchLockerWithdraw
} from 'features/pools/redux/hooks';
import { formatCountdown } from '../../helpers/format';
import { useSnackbar } from 'notistack';
import moment from 'moment';
const styles = theme => ({


  header: {
    lineHeight: 1.5,
    marginBottom: 25,
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  value: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    marginBottom: 5,
    fontSize: "0.95rem",
    fontWeight: 800,
  },
  tableContent: {
    textAlign: 'right',
  },
  datePicker: {
    width: "100%",

  }

})
const LockerInfo = ({ votingEscrowDetails }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  if (!votingEscrowDetails) return <div />
  return (

    <div >
      <div className={classes.header}>Total WASABI locked: <span className={classes.value}>
        {isNaN(votingEscrowDetails.totalLocked) ? 'Loading...' : parseFloat(votingEscrowDetails.totalLocked).toFixed(3)}
        ( {isNaN(votingEscrowDetails.totalLocked) ? 'Loading...' : parseFloat(votingEscrowDetails.totalLocked * 100 / votingEscrowDetails.totalWasabiSupply).toFixed(1)}% of all circulating WASABI )
      </span>
      </div>
      <div className={classes.header}>Total veWASABI: <span className={classes.value}>
        {isNaN(votingEscrowDetails.totalSupply) ? 'Loading...' : parseFloat(votingEscrowDetails.totalSupply).toFixed(3)}
      </span>
      </div>
      <div className={classes.header}>Average lock time: {!isNaN(votingEscrowDetails.totalSupply) && !isNaN(votingEscrowDetails.totalLocked) ? (4 * votingEscrowDetails.totalSupply / votingEscrowDetails.totalLocked).toFixed(3) : "-"} years</div>
      <div style={{ marginLeft: '0px' }} >
        <div className={classes.header}>
          <img src={require("assets/img/wasabi-pool.png")} className={"smIcon"} />
          Your WASABI Balance: <span className={classes.value}>
            {isNaN(votingEscrowDetails.userWasabiBalance) ? 'Loading...' : parseFloat(votingEscrowDetails.userWasabiBalance).toFixed(3)}
          </span>
        </div>
        <div className={classes.header}>
          <img src={require("assets/img/wasabi-pool.png")} className={"smIcon"} />
          Your Locked WASABI: <span className={classes.value}>
            {isNaN(votingEscrowDetails.userLocked) ? 'Loading...' : parseFloat(votingEscrowDetails.userLocked).toFixed(3)}
          </span>
        </div>
      </div>
      <div className={classes.header}>
        Your veWASABI Balance: <span className={classes.value}>
          {isNaN(votingEscrowDetails.userVeWasabi) ? 'Loading...' : parseFloat(votingEscrowDetails.userVeWasabi).toFixed(3)}
        </span>
      </div>
      <div className={classes.header}>
        Your Lock expiration date: {isNaN(votingEscrowDetails.userLockedEnd) ? 'Loading...' : (votingEscrowDetails.userLockedEnd == "0" ? "No Locked" : formatCountdown(votingEscrowDetails.userLockedEnd * 1000))}
      </div>

    </div >

  );
};

export default LockerInfo;
