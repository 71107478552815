import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = (isNightMode) => createMuiTheme({
  palette: {
    type: isNightMode ? 'dark' : 'light',
    background: {
      default: isNightMode ? 'black' : '#fbf9f6',
      contrast: isNightMode ? '#fff' : 'rgb(33, 36, 41)',
      paper: isNightMode ? 'rgb(20, 20, 20)' : '#fff',
      primary: isNightMode ? '#1F4F2C' : '#1F4F2C',
      secondary: isNightMode ? '#97eac9' : '#97eac9',
      grey: '#505050',
      menu: "rgb(9,11,31)",
      dark: isNightMode ? '#2B2A3D' : '#999',
      paused: isNightMode ? '#2B2A5A' : '#FCE57E',
      retired: isNightMode ? '#2C2F36' : '#F5F3F3',
      hover: isNightMode ? '#2B2A3D' : '#EFE6DC',
      border: isNightMode ? '#2B2A3D' : '#DED9D5',
    },
    primary: {
      main: isNightMode ? '#fff' : '#000',
    },
    secondary: {
      main: isNightMode ? '#fff' : '#F8F2EC',
    },
    text: {
      primary: isNightMode ? '#ffffff' : '#000',
      green: '#84ffac',
      red: '#ff6363',
      contrast: isNightMode ? '#000' : '#fff',
      secondary: isNightMode ? '#c8c8c8' : '#5A286E',
      minor: "rgb(70, 200, 100)"
    },
    sectionBg: {
      primary: isNightMode ? 'gray' : 'lightgray',
    },
    farmIcon: isNightMode ? 'farm_white.png' : 'farm_grey.png',
    vaultIcon: isNightMode ? 'vault_white.png' : 'vault_grey.png',
    collectorIcon: isNightMode ? 'collector_white.png' : 'collector_grey.png',
    govIcon: isNightMode ? 'gov_white.png' : 'gov.png',
    docsIcon: isNightMode ? 'docs_white.png' : 'docs.png',
    exchangeIcon: isNightMode ? 'swap_white.png' : 'swap_grey.png',
    bridgeIcon: isNightMode ? 'bridge_white.png' : 'bridge_grey.png',
    discordIcon: isNightMode ? 'discord_white.svg' : 'discord.svg',
    yearnIcon: isNightMode ? 'yearn.png' : 'yearn.png',
    idleIcon: isNightMode ? 'idle.png' : 'idle.png',
    dashboardIcon: isNightMode ? 'dashboard_white.png' : 'dashboard_grey.png',
    daiIcon: isNightMode ? 'dai.png' : 'dai.png',
    wasabixKingIcon: isNightMode ? 'wasabix_king.png' : 'wasabix_king.png',
    pickleIcon: isNightMode ? 'pickle.png' : 'pickle.png',
    harvestIcon: isNightMode ? 'harvest.png' : 'harvest.png',
    vesperIcon: isNightMode ? 'vesper.png' : 'vesper.png',
    wbtcIcon: isNightMode ? 'wbtc.png' : 'wbtc.png',
    liquityIcon: isNightMode ? 'liquity.png' : 'liquity.png',
    liquityWasabixIcon: isNightMode ? 'wasabix_liquity.png' : 'wasabix_liquity.png',
    lusdIcon: isNightMode ? 'lusd.png' : 'lusd.png',
    newIcon: isNightMode ? 'new.png' : 'new.png',
    ethIcon: 'eth.png',
    wethIcon: 'eth.png'
  },
  overrides: {
    MuiButton: {
      label: {
        color: isNightMode ? '#000' : '#000',
      }
    },
    // for dropdown menu items
    MuiButtonBase: {
      root: {
        color: isNightMode ? '#fff' : '#000',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: isNightMode ? '#fff' : '#000',
      },
      colorSecondary: {
        color: isNightMode ? '#fff' : '#000',
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: 'Menlo'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: "Menlo"
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "Menlo"
      },
      body2: {
        fontFamily: "Menlo"
      }
    },
    MuiTab: {
      root: {
        fontFamily: "Menlo"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Menlo"
      },
      contained: {
        backgroundColor: 'rgb(33, 36, 41)',
        color: 'white',
        border: 'white 1px solid'
      }
    },
    MuiAlert: {
      filledWarning: {
        fontFamily: "Menlo",
        fontSize: "1rem",
        fontWeight: "800"
      },
      filledError: {
        fontFamily: "Menlo",
        fontSize: "1rem",
        fontWeight: "800"
      },
      filledInfo: {
        fontFamily: "Menlo",
        fontSize: "1rem",
        fontWeight: "800"
      }
    },
    MuiStepIcon: {
      root: {
        color: 'white'
      }
    }
  }
});

export default createTheme;
