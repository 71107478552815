export const POOLS_FETCH_STAKE_BEGIN = 'POOLS_FETCH_STAKE_BEGIN';
export const POOLS_FETCH_STAKE_SUCCESS = 'POOLS_FETCH_STAKE_SUCCESS';
export const POOLS_FETCH_STAKE_FAILURE = 'POOLS_FETCH_STAKE_FAILURE';

export const POOLS_FETCH_UNSTAKE_BEGIN = 'POOLS_FETCH_UNSTAKE_BEGIN';
export const POOLS_FETCH_UNSTAKE_SUCCESS = 'POOLS_FETCH_UNSTAKE_SUCCESS';
export const POOLS_FETCH_UNSTAKE_FAILURE = 'POOLS_FETCH_UNSTAKE_FAILURE';

export const POOLS_FETCH_CLAIM_BEGIN = 'POOLS_FETCH_CLAIM_BEGIN';
export const POOLS_FETCH_CLAIM_SUCCESS = 'POOLS_FETCH_CLAIM_SUCCESS';
export const POOLS_FETCH_CLAIM_FAILURE = 'POOLS_FETCH_CLAIM_FAILURE';

export const POOLS_FETCH_EXIT_BEGIN = 'POOLS_FETCH_EXIT_BEGIN';
export const POOLS_FETCH_EXIT_SUCCESS = 'POOLS_FETCH_EXIT_SUCCESS';
export const POOLS_FETCH_EXIT_FAILURE = 'POOLS_FETCH_EXIT_FAILURE';

export const POOLS_FETCH_BEGIN = 'POOLS_FETCH_BEGIN';
export const POOLS_FETCH_SUCCESS = 'POOLS_FETCH_SUCCESS';
export const POOLS_FETCH_FAILURE = 'POOLS_FETCH_FAILURE';

export const DEPRECATED_POOLS_FETCH_BEGIN = 'DEPRECATED_POOLS_FETCH_BEGIN';
export const DEPRECATED_POOLS_FETCH_SUCCESS = 'DEPRECATED_POOLS_FETCH_SUCCESS';
export const DEPRECATED_POOLS_FETCH_FAILURE = 'DEPRECATED_POOLS_FETCH_FAILURE';

export const POOL_USERS_FETCH_BEGIN = 'POOL_USERS_FETCH_BEGIN';
export const POOL_USERS_FETCH_SUCCESS = 'POOL_USERS_FETCH_SUCCESS';
export const POOL_USERS_FETCH_FAILURE = 'POOL_USERS_FETCH_FAILURE';

export const CHECK_TOKEN_APPROVAL_BEGIN = 'CHECK_TOKEN_APPROVAL_BEGIN';
export const CHECK_TOKEN_APPROVAL_SUCCESS = 'CHECK_TOKEN_APPROVAL_SUCCESS';
export const CHECK_TOKEN_APPROVAL_FAILURE = 'CHECK_TOKEN_APPROVAL_FAILURE';

export const FETCH_TOKEN_APPROVAL_BEGIN = 'FETCH_TOKEN_APPROVAL_BEGIN';
export const FETCH_TOKEN_APPROVAL_SUCCESS = 'FETCH_TOKEN_APPROVAL_SUCCESS';
export const FETCH_TOKEN_APPROVAL_FAILURE = 'FETCH_TOKEN_APPROVAL_FAILURE';

export const FETCH_REWARD_VESTING_DETAILS_BEGIN = 'FETCH_REWARD_VESTING_DETAILS_BEGIN';
export const FETCH_REWARD_VESTING_DETAILS_SUCCESS = 'FETCH_REWARD_VESTING_DETAILS_SUCCESS';
export const FETCH_REWARD_VESTING_DETAILS_FAILURE = 'FETCH_REWARD_VESTING_DETAILS_FAILURE';

export const FETCH_VOTING_ESCROW_DETAILS_BEGIN = 'FETCH_VOTING_ESCROW_DETAILS_BEGIN';
export const FETCH_VOTING_ESCROW_DETAILS_SUCCESS = 'FETCH_VOTING_ESCROW_DETAILS_SUCCESS';
export const FETCH_VOTING_ESCROW_DETAILS_FAILURE = 'FETCH_VOTING_ESCROW_DETAILS_FAILURE';

export const POOLS_FETCH_WITHDRAW_EARNING_BEGIN = 'POOLS_FETCH_WITHDRAW_EARNING_BEGIN';
export const POOLS_FETCH_WITHDRAW_EARNING_SUCCESS = 'POOLS_FETCH_WITHDRAW_EARNINGSTAKE_SUCCESS';
export const POOLS_FETCH_WITHDRAW_EARNING_FAILURE = 'POOLS_FETCH_WITHDRAW_EARNING_FAILURE';

export const FETCH_CREATE_LOCK_BEGIN = 'FETCH_CREATE_LOCK_BEGIN';
export const FETCH_CREATE_LOCK_SUCCESS = 'FETCH_CREATE_LOCK_SUCCESS';
export const FETCH_CREATE_LOCK_FAILURE = 'FETCH_CREATE_LOCK_FAILURE';

export const FETCH_ADD_AMOUNT_BEGIN = 'FETCH_ADD_AMOUNT_BEGIN';
export const FETCH_ADD_AMOUNT_SUCCESS = 'FETCH_ADD_AMOUNT_SUCCESS';
export const FETCH_ADD_AMOUNT_FAILURE = 'FETCH_ADD_AMOUNT_FAILURE';

export const FETCH_EXTEND_LOCK_BEGIN = 'FETCH_EXTEND_LOCK_BEGIN';
export const FETCH_EXTEND_LOCK_SUCCESS = 'FETCH_EXTEND_LOCK_SUCCESS';
export const FETCH_EXTEND_LOCK_FAILURE = 'FETCH_EXTEND_LOCK_FAILURE';

export const FETCH_CLAIM_REWARD_BEGIN = 'FETCH_CLAIM_REWARD_BEGIN';
export const FETCH_CLAIM_REWARD_SUCCESS = 'FETCH_CLAIM_REWARD_SUCCESS';
export const FETCH_CLAIM_REWARD_FAILURE = 'FETCH_CLAIM_REWARD_FAILURE';

export const FETCH_LOCKER_APPROVAL_BEGIN = 'FETCH_LOCKER_APPROVAL_BEGIN';
export const FETCH_LOCKER_APPROVAL_SUCCESS = 'FETCH_LOCKER_APPROVAL_SUCCESS';
export const FETCH_LOCKER_APPROVAL_FAILURE = 'FETCH_LOCKER_APPROVAL_FAILURE';

export const FETCH_LOCKER_WITHDRAW_BEGIN = 'FETCH_LOCKER_WITHDRAW_BEGIN';
export const FETCH_LOCKER_WITHDRAW_SUCCESS = 'FETCH_LOCKER_WITHDRAW_SUCCESS';
export const FETCH_LOCKER_WITHDRAW_FAILURE = 'FETCH_LOCKER_WITHDRAW_FAILURE';

export const FETCH_CLAIM_AIRDROPS_BEGIN = 'FETCH_CLAIM_AIRDROPS_BEGIN';
export const FETCH_CLAIM_AIRDROPS_SUCCESS = 'FETCH_CLAIM_AIRDROPS_SUCCESS';
export const FETCH_CLAIM_AIRDROPS_FAILURE = 'FETCH_CLAIM_AIRDROPS_FAILURE';

export const FETCH_SINGLE_AIRDROPS_BEGIN = 'FETCH_SINGLE_AIRDROPS_BEGIN';
export const FETCH_SINGLE_AIRDROPS_SUCCESS = 'FETCH_SINGLE_AIRDROPS_SUCCESS';
export const FETCH_SINGLE_AIRDROPS_FAILURE = 'FETCH_SINGLE_AIRDROPS_FAILURE';

export const FETCH_SINGLE_LOCKER_REWARD_BEGIN = 'FETCH_SINGLE_LOCKER_REWARD_BEGIN';
export const FETCH_SINGLE_LOCKER_REWARD_SUCCESS = 'FETCH_SINGLE_LOCKER_REWARD_SUCCESS';
export const FETCH_SINGLE_LOCKER_REWARD_FAILURE = 'FETCH_SINGLE_LOCKER_REWARD_FAILURE';

export const FETCH_CLAIM_MERKLE_REWARD_BEGIN = 'FETCH_CLAIM_MERKLE_REWARD_BEGIN';
export const FETCH_CLAIM_MERKLE_REWARD_SUCCESS = 'FETCH_CLAIM_MERKLE_REWARD_SUCCESS';
export const FETCH_CLAIM_MERKLE_REWARD_FAILURE = 'FETCH_CLAIM_MERKLE_REWARD_FAILURE';
