import { merkleRewardABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const claimMerkleReward = async ({
  web3,
  address,
  contractAddress,
  index,
  account,
  amount,
  merkleProof,
  dispatch,
}) => {
  const contractObj = new web3.eth.Contract(merkleRewardABI, contractAddress);
  const data = await _claimMerkleReward({
    web3,
    contractObj,
    address,
    index,
    account,
    amount,
    merkleProof,
    dispatch,
  });
  return data;
};

const _claimMerkleReward = ({
  web3,
  contractObj,
  address,
  index,
  account,
  amount,
  merkleProof,
  dispatch,
}) => {
  return new Promise((resolve, reject) => {
    contractObj.methods
      .claim(index, account, amount, merkleProof)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
