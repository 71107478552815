import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_TROVE_BEGIN,
  VAULT_FETCH_TROVE_SUCCESS,
  VAULT_FETCH_TROVE_FAILURE,
} from './constants';
import { openTrove } from '../../web3';
import { fetchVaultDetail } from './action';

export function fetchOpenTrove({ web3, address, depositETH, borrowLUSD, tokenId, strategyId }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_TROVE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      openTrove({ web3, address, depositETH, borrowLUSD, dispatch })
        .then(data => {
          dispatch({
            type: VAULT_FETCH_TROVE_SUCCESS,
            data
          });
          dispatch(fetchVaultDetail(tokenId, strategyId))
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_TROVE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchOpenTrove() {
  const dispatch = useDispatch();

  const { fetchOpenTrovePending } = useSelector(state => ({
    fetchOpenTrovePending: state.vault.fetchOpenTrovePending,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchOpenTrove(data));
    },
    [dispatch]
  );

  return {
    fetchOpenTrove: boundAction,
    fetchOpenTrovePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_TROVE_BEGIN:
      return {
        ...state,
        fetchOpenTrovePending: {
          ...state.fetchOpenTrovePending
        },
      };

    case VAULT_FETCH_TROVE_SUCCESS:
      return {
        ...state,
        fetchOpenTrovePending: {
          ...state.fetchOpenTrovePending
        },
      };

    case VAULT_FETCH_TROVE_FAILURE:
      return {
        ...state,
        fetchOpenTrovePending: {
          ...state.fetchOpenTrovePending
        },
      };

    default:
      return state;
  }
}
