import { transmuterABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const transmute = async ({ web3, address, tokenId, isExit, dispatch }) => {
  const contractAddress = contracts.transmuter[tokenId].address;
  const contract = new web3.eth.Contract(transmuterABI, contractAddress);
  const data = await _transmute({ web3, contract, address, isExit, dispatch });
  return data;
};

const _transmute = ({ web3, contract, address, isExit, dispatch }) => {
  return new Promise((resolve, reject) => {
    if (isExit) {
      contract.methods
        .transmuteClaimAndWithdraw()
        .send({ from: address })
        .on('transactionHash', function (hash) {
          console.log(hash);
          dispatch(
            enqueueSnackbar({
              message: hash,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
              hash,
            })
          );
        })
        .on('receipt', function (receipt) {
          console.log(receipt);
          resolve();
        })
        .on('error', function (error) {
          console.log(error);
          reject(error);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    } else {
      contract.methods
        .transmuteAndClaim()
        .send({ from: address })
        .on('transactionHash', function (hash) {
          console.log(hash);
          dispatch(
            enqueueSnackbar({
              message: hash,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
              hash,
            })
          );
        })
        .on('receipt', function (receipt) {
          console.log(receipt);
          resolve();
        })
        .on('error', function (error) {
          console.log(error);
          reject(error);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    }
  });
};
