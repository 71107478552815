import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  POOLS_FETCH_UNSTAKE_BEGIN,
  POOLS_FETCH_UNSTAKE_SUCCESS,
  POOLS_FETCH_UNSTAKE_FAILURE,
} from './constants';
import { poolUnstake } from '../../web3';
import { fetchPools } from './action';
import { contracts } from 'features/configure';

export function fetchUnstake({ address, web3, poolId, amount, poolAddress = contracts.stakingPools.address }) {
  return dispatch => {
    dispatch({
      type: POOLS_FETCH_UNSTAKE_BEGIN,
      index: poolId
    });

    const promise = new Promise((resolve, reject) => {
      poolUnstake({ web3, address, poolId, amount, poolAddress, dispatch })
        .then(data => {
          dispatch({
            type: POOLS_FETCH_UNSTAKE_SUCCESS,
            data,
            index: poolId
          });
          dispatch(fetchPools());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_UNSTAKE_FAILURE,
            index: poolId
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchUnstake(poolId) {
  const dispatch = useDispatch();

  const { fetchUnstakePending } = useSelector(state => ({
    fetchUnstakePending: state.pools.fetchUnstakePending[poolId],
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchUnstake(data));
    },
    [dispatch]
  );

  return {
    fetchUnstake: boundAction,
    fetchUnstakePending,
  };
}

export function reducer(state, action) {
  let { fetchUnstakePending } = state;
  switch (action.type) {
    case POOLS_FETCH_UNSTAKE_BEGIN:
      fetchUnstakePending[action.index] = true;
      return {
        ...state,
        fetchUnstakePending
      };

    case POOLS_FETCH_UNSTAKE_SUCCESS:
      fetchUnstakePending[action.index] = false;
      return {
        ...state,
        fetchUnstakePending
      };

    case POOLS_FETCH_UNSTAKE_FAILURE:
      fetchUnstakePending[action.index] = false;
      return {
        ...state,
        fetchUnstakePending
      };

    default:
      return state;
  }
}
