import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_WITHDRAW_BEGIN,
  VAULT_FETCH_WITHDRAW_SUCCESS,
  VAULT_FETCH_WITHDRAW_FAILURE,
} from './constants';
import { withdraw } from '../../web3';
import { fetchVaultDetail } from './action';

export function fetchWithdraw({ address, web3, amount, tokenId, strategyId }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_WITHDRAW_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      withdraw({ web3, address, amount, tokenId, strategyId, dispatch })
        .then(data => {
          dispatch({
            type: VAULT_FETCH_WITHDRAW_SUCCESS,
            data
          });
          dispatch(fetchVaultDetail(tokenId, strategyId));
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_WITHDRAW_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchWithdraw() {
  const dispatch = useDispatch();

  const { fetchWithdrawPending } = useSelector(state => ({
    fetchWithdrawPending: state.vault.fetchWithdrawPending,
  }));

  const boundAction = useCallback(data => dispatch(fetchWithdraw(data)), [dispatch]);

  return {
    fetchWithdraw: boundAction,
    fetchWithdrawPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_WITHDRAW_BEGIN:
      return {
        ...state,
        fetchWithdrawPending: {
          ...state.fetchWithdrawPending
        },
      };

    case VAULT_FETCH_WITHDRAW_SUCCESS:
      return {
        ...state,
        fetchWithdrawPending: {
          ...state.fetchWithdrawPending
        },
      };

    case VAULT_FETCH_WITHDRAW_FAILURE:
      return {
        ...state,
        fetchWithdrawPending: {
          ...state.fetchWithdrawPending
        },
      };

    default:
      return state;
  }
}
