import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_ASSET_STATS_BEGIN,
  FETCH_ASSET_STATS_SUCCESS,
  FETCH_ASSET_STATS_FAILURE,
} from './constants';
import { pools } from '../../configure/pools';
import { apiUrl } from "features/configure"
export function fetchAssetStats(address) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_ASSET_STATS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      axios.get(`${apiUrl}/users/${address}`).then(
        response => {
          dispatch({
            type: FETCH_ASSET_STATS_SUCCESS,
            data: response.data,
          });
          resolve(response);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_ASSET_STATS_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useFetchAssetStats() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { assetStatsData, assetStatsPending } = useSelector(
    state => ({
      assetStatsData: state.dashboard.assetStatsData,
      assetStatsPending: state.dashboard.assetStatsPending,
    })
  );

  const boundAction = useCallback(
    (address) => dispatch(fetchAssetStats(address)),
    [dispatch],
  );

  return {
    assetStatsData,
    fetchAssetStats: boundAction,
    assetStatsPending
  };
}

export function reducer(state, action) {
  let { assetStatsPending, assetStatsData } = state;
  switch (action.type) {
    case FETCH_ASSET_STATS_BEGIN:
      assetStatsPending = true;
      return {
        ...state,
        assetStatsPending,
      };

    case FETCH_ASSET_STATS_SUCCESS:

      return {
        ...state,
        assetStatsData: action.data,
        assetStatsPending: false
      };

    case FETCH_ASSET_STATS_FAILURE:
      // The request is failed
      assetStatsPending = false;

      return {
        ...state,
        assetStatsData: undefined,
        assetStatsPending
      };

    default:
      return state;
  }
}
