import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  LQTY_DETAIL_FETCH_BEGIN,
  LQTY_DETAIL_FETCH_SUCCESS,
  LQTY_DETAIL_FETCH_FAILURE,
} from './constants';
import {convertToInternationalCurrencySystem} from '../../helpers/bignumber';
import { EthersLiquity } from "@liquity/lib-ethers";
import { Decimal } from "@liquity/lib-base";

const ethers = require('ethers');

export function fetchLiquityStatistics() {
  return (dispatch,getState) => {
    dispatch({
      type: LQTY_DETAIL_FETCH_BEGIN,
    });

    const promise = new Promise(async(resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;
      const provider = new ethers.providers.Web3Provider(web3.currentProvider);
      const signer = provider.getSigner();
      const liquity = await EthersLiquity.connect(signer);

      const promises = [
        liquity.getPrice(),
        liquity.getFees(),
        liquity.getTotal(),
        liquity.getNumberOfTroves(),
        liquity.getLUSDInStabilityPool(),
        liquity.getTotalStakedLQTY(),        
      ]

      Promise
        .all(promises)
        .then(results => {
          const ethPrice = results[0]
          const fees = results[1];
          const total = results[2];
          const numOfTroves = results[3];          
          const lUSDInStabilityPool = convertToInternationalCurrencySystem(results[4]);
          const lUSDInStabilityPoolInPercentage = results[4].div(total.debt).mul(100).toString(2) +"%"
          const lUSDInStabilityPoolDisplay = `${lUSDInStabilityPool} (${lUSDInStabilityPoolInPercentage})`
          const stakedLQTY = convertToInternationalCurrencySystem(results[5]);           
          const lUSDSupply = convertToInternationalCurrencySystem(total.debt);
          const borrowingRate = fees.borrowingRate().mul(100).toString(2) + "%";
          const redeemedLUSDAmount = Decimal.from(100);
          const redeemedFractionOfSupply = redeemedLUSDAmount.div(total.debt);
          const redemptionRate = fees.redemptionRate(redeemedFractionOfSupply).mul(100).toString(2) + "%";
          const totalCollateral	= total.collateral.mul(ethPrice)	//Decimal	Amount of native currency (e.g. Ether) collateralized.
          const totalCollateralRatio = totalCollateral.div(total.debt).mul(100).toString(2) +"%"; // The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt.
          const stakedETH_USDValue = convertToInternationalCurrencySystem(total.collateral.mul(ethPrice).toString(2));
          const collaterialETH = convertToInternationalCurrencySystem(total.collateral.toString(2))
          const TVL = `${collaterialETH} ETH ($${stakedETH_USDValue})`; //The Total Value Locked (TVL) is the total value of Ether locked as collateral in the system, given in ETH and USD.
          const recoveryMode = totalCollateral.div(total.debt).lt(1.5) ? 'Yes' : 'No';

          const output = {
            borrowingFee: borrowingRate,
            redemptionFee: redemptionRate,
            TVL: TVL,
            troves: numOfTroves,
            lUSDSupply: lUSDSupply,
            lUSDInStabilityPool: lUSDInStabilityPoolDisplay,
            stakedLQTY: stakedLQTY,
            totalCollateralRatio: totalCollateralRatio,
            recoveryMode: recoveryMode,
          };
          dispatch({
            type: LQTY_DETAIL_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: LQTY_DETAIL_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchLiquityStatistics() {
  const dispatch = useDispatch();

  const { liquityDetails, fetchLiquityStatisticsPending, fetchLiquityStatisticsDone } = useSelector(
    state => ({
      liquityDetails: state.vault.liquityDetails,
      fetchLiquityStatisticsDone: state.vault.fetchLiquityStatisticsDone,
      fetchLiquityStatisticsPending: state.vault.fetchLiquityStatisticsPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    () => {
      return dispatch(fetchLiquityStatistics());
    },
    [dispatch]
  );

  return {
    details: liquityDetails,
    fetchLiquityStatistics: boundAction,
    fetchLiquityStatisticsDone,
    fetchLiquityStatisticsPending,
  };
}

export function reducer(state, action) {
  let { liquityDetails, fetchLiquityStatisticsPending, fetchLiquityStatisticsDone } = state;

  switch (action.type) {
    case LQTY_DETAIL_FETCH_BEGIN:
      fetchLiquityStatisticsPending = true;
      return {
        ...state,
        fetchLiquityStatisticsPending
      };

    case LQTY_DETAIL_FETCH_SUCCESS:
      liquityDetails= action.data;
      fetchLiquityStatisticsDone = true;
      fetchLiquityStatisticsPending = false;
      return {
        ...state,
        liquityDetails,
        fetchLiquityStatisticsDone,
        fetchLiquityStatisticsPending
      };

    case LQTY_DETAIL_FETCH_FAILURE:
      fetchLiquityStatisticsPending = false;
      return {
        ...state,
        fetchLiquityStatisticsPending
      };

    default:
      return state;
  }
}
