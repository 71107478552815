import React, { useEffect, useState } from 'react';
import YumInstruction from '../instruction/YumInstruction';
import AllMintersTable from './components/AllMintersTable.js';
import MyMintersTable from './components/MyMintersTable.js';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useFetchAllVaultDetails
} from 'features/vault/redux/hooks';
import { useConnectWallet } from 'features/home/redux/hooks';
import { useTVL } from '../../components/TVL/useTVL';
const useStyles = makeStyles((theme) => ({


}));

export default function Minters(props) {
  const { address } = useConnectWallet();
  const classes = useStyles();
  const { details, fetchAllVaultDetails } = useFetchAllVaultDetails();
  const { fetchP, tvls } = useTVL();
  useEffect(() => {
    if (address) {
      fetchAllVaultDetails()
      const id = setInterval(() => {
        fetchAllVaultDetails()
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  return (
    <div >
      <YumInstruction value={1} />
      <div >
        <h1 className={"header"}>
          Your Minters
        </h1>
        <MyMintersTable details={details} />
      </div>
      <div className="block">
        <h1 className={"header"}>
          All Minters
        </h1>
        <AllMintersTable details={details} />
      </div>
    </div>
  );
}
