import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TRANSMUTER_FETCH_TRANSMUTE_BEGIN,
  TRANSMUTER_FETCH_TRANSMUTE_SUCCESS,
  TRANSMUTER_FETCH_TRANSMUTE_FAILURE,
} from './constants';
import { transmute } from '../../web3';
import { fetchTransmuterDetail } from './action';


export function fetchTransmute({ address, web3, tokenId, isExit }) {
  return dispatch => {
    dispatch({
      type: TRANSMUTER_FETCH_TRANSMUTE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      transmute({ web3, address, tokenId, isExit, dispatch })
        .then(data => {
          dispatch({
            type: TRANSMUTER_FETCH_TRANSMUTE_SUCCESS,
            data
          });
          dispatch(fetchTransmuterDetail(tokenId))
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: TRANSMUTER_FETCH_TRANSMUTE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchTransmute() {
  const dispatch = useDispatch();

  const { fetchTransmutePending } = useSelector(state => ({
    fetchTransmutePending: state.transmuter.fetchTransmutePending,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchTransmute(data));
    },
    [dispatch]
  );

  return {
    fetchTransmute: boundAction,
    fetchTransmutePending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case TRANSMUTER_FETCH_TRANSMUTE_BEGIN:
      return {
        ...state,
        fetchTransmutePending: {
          ...state.fetchTransmutePending
        },
      };

    case TRANSMUTER_FETCH_TRANSMUTE_SUCCESS:
      return {
        ...state,
        fetchTransmutePending: {
          ...state.fetchTransmutePending
        },
      };

    case TRANSMUTER_FETCH_TRANSMUTE_FAILURE:
      return {
        ...state,
        fetchTransmutePending: {
          ...state.fetchTransmutePending
        },
      };

    default:
      return state;
  }
}
