import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useSnackbar } from 'notistack';
import {
  useFetchVaultDetail, useFetchWithdraw
} from 'features/vault/redux/hooks';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useConnectWallet } from 'features/home/redux/hooks';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import { byDecimals, calculateReallyNum } from 'features/helpers/bignumber';
import Button from 'components/CustomButtons/Button.js';
import styles from './styles';
import Container from '@material-ui/core/Container';
import IconToolTip from 'components/ToolTip/IconToolTip';
import { tokens } from 'features/configure';

const useStyles = makeStyles(styles);

export default function WithDraw({ balanceSingle, pool }) {

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, address } = useConnectWallet();
  const { tokenId, strategyId } = useParams();
  const decimals = tokens[tokenId].decimals;

  const { details, fetchVaultDetail } = useFetchVaultDetail(tokenId, strategyId);
  const { fetchWithdraw, fetchWithdrawPending } = useFetchWithdraw();
  const [withdrawBalance, setWithdrawBalance] = useState({
    amount: '',
    slider: 0,
  });

  const handleWithdrawnBalance = (_, sliderNum) => {
    const total = details.availableToWithdraw;
    const amount = sliderNum === 0 ? '' : sliderNum === 100 ? new BigNumber(total).toFormat() : calculateReallyNum(total, sliderNum);

    setWithdrawBalance({
      amount: amount,
      slider: sliderNum,
    });
  };

  const onWithdraw = () => {
    let amountValue = withdrawBalance.amount
      ? withdrawBalance.amount.replaceAll(',', '')
      : withdrawBalance.amount;

    fetchWithdraw({
      address,
      web3,
      amount: new BigNumber(amountValue)
        .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
        .toString(10),
      tokenId,
      strategyId
    })
      .then(() => {
        enqueueSnackbar(t('Vault-WithdrawSuccess'), { variant: 'success' });
        setWithdrawBalance({
          amount: '',
          slider: 0
        });
      })
      .catch(error => enqueueSnackbar(t('Vault-WithdrawError', { error }), { variant: 'error' }));
  };

  const setMaximumInput = event => {
    let value = details.availableToWithdraw;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    setWithdrawBalance({
      amount: inputFinalVal(value, value, decimals)
    });
  };

  const changeDetailInputValue = event => {
    let value = event.target.value;
    const total = details.availableToWithdraw;

    if (!inputLimitPass(value, decimals)) {
      return;
    }

    let sliderNum = 0;
    let inputVal = 0;
    if (value) {
      inputVal = Number(value.replaceAll(',', ''));
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }

    setWithdrawBalance({
      amount: inputFinalVal(value, total, decimals),
      slider: sliderNum,
    });
  };

  return (
    <div className={classes.sliderDetailContainer} >
      {/* <IconToolTip>
        Please visit <a href='https://wasabix-finance.gitbook.io/wasabix_finance/' target='_blank'>this link</a> to see more details.
      </IconToolTip> */}
      <Container maxWidth="md" disableGutters={true}>
        <div className={classes.showDetailLeft}>
          <h3 className={classes.detailHeader}>Withdraw your deposited {tokens[tokenId].token}</h3>
          <div className={classes.detailContent}>
            {t('Vault-Available-To-Withdraw')}: {details.availableToWithdraw ? new BigNumber(details.availableToWithdraw).toFormat() : 0}
          </div>
        </div>

        <FormControl fullWidth variant="outlined" className={classes.numericInput}>
          <CustomOutlinedInput value={withdrawBalance.amount} onChange={changeDetailInputValue} setMax={setMaximumInput} />
        </FormControl>
        <div className={classes.showDetailButton}>
          <Button
            fullWidth
            color="green"
            disabled={!Boolean(withdrawBalance.amount) || withdrawBalance.amount === "0"}
            onClick={() => onWithdraw()}
          >
            {t('Withdraw')}
          </Button>

        </div>
      </Container>
    </div>
  );
}
