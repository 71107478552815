import React from 'react'
import styled from 'styled-components'
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import twitterIcon from "../../assets/img/social/twitter.svg";
import gitbookIcon from "../../assets/img/social/gitbook.svg";
import githubIcon from "../../assets/img/social/github.svg";
import telegramIcon from "../../assets/img/social/telegram.svg";
import discordIcon from "../../assets/img/social/discord.svg";
import defipulseIcon from "../../assets/img/social/defipulse.png";
import logo from "../../assets/img/wasabi.png";
import Container from '@material-ui/core/Container';
import LandingHeader from './components/LandingHeader';
import LandingContent from './components/LandingContent';
import Grid from '@material-ui/core/Grid';
import createTheme from '../home/jss/appTheme';

const Landing = () => {
  const theme = createTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container>
          <div style={{
            minHeight: "100vh",
            position: "relative",
            overflow: "hidden",
            backgroundColor: theme.palette.background.default

          }}>
            <LandingHeader />
            <LandingContent />
            <Grid
              justify="space-between"
              alignItems="center"
              container
              spacing={3}
              style={{ marginTop: 100 }}
            >
              <Grid item style={{ color: "white", fontSize: 14 }}><img style={{ verticalAlign: "bottom", marginRight: 10 }} height={32} src={logo} />© 2021 wasabix.finance</Grid>
              <Grid item >
                <StyledNav>
                  <StyledLink target="_blank" href="https://twitter.com/WasabixFinance">
                    <ImageIcon src={twitterIcon} />
                  </StyledLink>
                  <StyledLink target="_blank" href="https://t.me/WasabixFinance">
                    <ImageIcon src={telegramIcon} />
                  </StyledLink>
                  <StyledLink target="_blank" href="https://github.com/wasabi-swap-team">
                    <ImageIcon src={githubIcon} />
                  </StyledLink>
                  <StyledLink target="_blank" href="https://wasabix-finance.gitbook.io/wasabix_finance/">
                    <ImageIcon src={gitbookIcon} />
                  </StyledLink>
                  <StyledLink target="_blank" href="https://discord.gg/Wyd4t2qzHH">
                    <ImageIcon src={discordIcon} />
                  </StyledLink>
                  <StyledLink href="javascript: void(0)">
                    <ImageIcon src={defipulseIcon} />
                  </StyledLink>
                </StyledNav>
              </Grid>
            </Grid>
          </div>
        </Container>
      </ThemeProvider>
    </StylesProvider >
  )
}

const ImageIcon = ({ src, size = 26 }) => {
  return (
    <img src={src}
      height={size}
      style={{
        filter: 'invert(1)',
        marginTop: -4,
      }}
    />
  )
}

const StyledText = styled.span`
  color: black;
  font-weight: 900;
`

const Blank = styled.div`
  height: 50px;
  @media (max-width: 500px) {
    height: 30px;
  }
`


const StyledNav = styled.nav`
  padding: 1.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  `;

const StyledLink = styled.a`
  color: white;
  padding: 16px;
  text-decoration: none;
  &:hover {
  color: #805e49;
  }
`

export default Landing
