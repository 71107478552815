import { votingEscrowABI, contracts } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const createLock = async ({ web3, address, amount, lockDays, dispatch }) => {
  const contract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
  const data = await _createLock({ web3, contract, address, amount, lockDays, dispatch });
  return data;
}

export const _createLock = async ({ web3, contract, address, amount, lockDays, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .createLock(amount, lockDays)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const addAmount = async ({ web3, address, amount, dispatch }) => {
  const contract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
  const data = await _addAmount({ web3, contract, address, amount, dispatch });
  return data;
}

export const _addAmount = async ({ web3, contract, address, amount, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .addAmount(amount)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const extendLock = async ({ web3, address, extendDays, dispatch }) => {
  const contract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
  const data = await _extendLock({ web3, contract, address, extendDays, dispatch });
  return data;
}

export const _extendLock = async ({ web3, contract, address, extendDays, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .extendLock(extendDays)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const claimReward = async ({ web3, address, dispatch }) => {
  const contract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
  const data = await _claimReward({ web3, contract, address, dispatch });
  return data;
}

export const _claimReward = async ({ web3, contract, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .vestEarning()
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const lockerWithdraw = async ({ web3, address, dispatch }) => {
  const contract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
  const data = await _lockerWithdraw({ web3, contract, address, dispatch });
  return data;
}

export const _lockerWithdraw = async ({ web3, contract, address, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .withdraw()
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
