const styles = theme => ({
  detailContent: {
    color: theme.palette.text.primary,
    fontSize: 14, fontWeight: 700, marginBottom: 20
  },
  detailText: {
    color: theme.palette.text.primary,
    fontSize: 13, fontWeight: 500
  },
  detailHeader: {
    color: theme.palette.text.secondary,
    lineHeight: 1.8,
    fontSize: 16,
    marginBottom: 50
  },
  detailContainer: {
    textAlign: 'left',
  },
  showDetailButton: {
    marginTop: 30
  },
  select: {
    fontSize: 16,
  },
});

export default styles;
