import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FETCH_FEE_DATA_BEGIN,
  FETCH_FEE_DATA_SUCCESS,
  FETCH_FEE_DATA_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { votingEscrowABI, contracts, fee } from '../../configure';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import BigNumber from 'bignumber.js';
export function fetchFeeData() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FEE_DATA_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;

      const votinEscrowContract = new web3.eth.Contract(votingEscrowABI, contracts.votingEscrow.address);
      const multicall = new MultiCall(web3, contracts.multicall.address);

      let calls = [
        { result: votinEscrowContract.methods.balanceOf(address) },
      ]


      multicall
        .all([calls])
        .then(([results]) => {
          let output = {};
          const userVeWasabi = convertAmountFromRawNumber(new BigNumber(results[0].result))

          let userLevelData = fee[0]

          for (let feeData of fee) {
            if (userVeWasabi >= feeData.range[0] && userVeWasabi < feeData.range[1]) {
              userLevelData = feeData
              break;
            }
          }
          let nextLevel = userLevelData.level + 1
          if (nextLevel >= fee.length) {
            nextLevel = userLevelData.level
          }
          output = {
            userVeWasabi,
            userLevelData,
            firstLevelData: fee[0],
            nextLevelData: fee[nextLevel]
          };

          dispatch({
            type: FETCH_FEE_DATA_SUCCESS,
            data: output
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: FETCH_FEE_DATA_FAILURE
          });
          reject(error.message || error);
        });
    });


    return promise;
  }
}

export function useFetchFeeData() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { feeData, fetchFeePending } = useSelector(
    state => ({
      feeData: state.fee.feeData,
      fetchFeePending: state.fee.fetchFeePending,
    })
  );

  const boundAction = useCallback(
    () => dispatch(fetchFeeData()),
    [dispatch],
  );

  return {
    feeData,
    fetchFeeData: boundAction,
    fetchFeePending
  };
}

export function reducer(state, action) {
  let { fetchFeePending, feeData } = state;
  switch (action.type) {
    case FETCH_FEE_DATA_BEGIN:
      fetchFeePending = true;
      return {
        ...state,
        fetchFeePending,
      };

    case FETCH_FEE_DATA_SUCCESS:

      return {
        ...state,
        feeData: action.data,
        fetchFeePending: false
      };

    case FETCH_FEE_DATA_FAILURE:
      // The request is failed
      fetchFeePending = false;

      return {
        ...state,
        feeData: undefined,
        fetchFeePending
      };

    default:
      return state;
  }
}
