import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_CLAIM_MERKLE_REWARD_BEGIN,
  FETCH_CLAIM_MERKLE_REWARD_SUCCESS,
  FETCH_CLAIM_MERKLE_REWARD_FAILURE,
} from './constants';
import { claimMerkleReward } from '../../web3';
import BigNumber from 'bignumber.js';

export function fetchClaimMerkleReward({ address, web3, reward }) {
  return dispatch => {
    dispatch({
      type: FETCH_CLAIM_MERKLE_REWARD_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      claimMerkleReward({
        web3,
        address,
        contractAddress: reward.contract,
        index: reward.balanceTree.index,
        account: reward.balanceTree.account,
        amount: (new BigNumber(reward.balanceTree.amount)).toString(),
        merkleProof: reward.balanceTree.proof,
        dispatch,
      })
        .then(data => {
          dispatch({
            type: FETCH_CLAIM_MERKLE_REWARD_SUCCESS,
            data,
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_CLAIM_MERKLE_REWARD_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaimMerkleReward() {
  const dispatch = useDispatch();

  const { fetchClaimMerkleRewardPending } = useSelector(state => ({
    fetchClaimMerkleRewardPending: state.pools.fetchClaimMerkleRewardPending,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchClaimMerkleReward(data));
    },
    [dispatch]
  );

  return {
    claimMerkleReward: boundAction,
    fetchClaimMerkleRewardPending,
  };
}

export function reducer(state, action) {
  let { fetchClaimMerkleRewardPending } = state;
  switch (action.type) {
    case FETCH_CLAIM_MERKLE_REWARD_BEGIN:
      fetchClaimMerkleRewardPending = true;
      return {
        ...state,
        fetchClaimMerkleRewardPending,
      };

    case FETCH_CLAIM_MERKLE_REWARD_SUCCESS:
      fetchClaimMerkleRewardPending = false;
      return {
        ...state,
        fetchClaimMerkleRewardPending,
      };

    case FETCH_CLAIM_MERKLE_REWARD_FAILURE:
      fetchClaimMerkleRewardPending = false;
      return {
        ...state,
        fetchClaimMerkleRewardPending,
      };

    default:
      return state;
  }
}
