import React, { useEffect, useState } from 'react';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import { Notifier } from 'features/common';
import { useConnectWallet } from './redux/hooks';
import appStyle from './jss/appStyle.js';
import createTheme from './jss/appTheme';
import Container from '@material-ui/core/Container';
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { networkId } = useConnectWallet();
  const { t } = useTranslation();

  // const { isNightMode, setNightMode } = useNightMode();
  const theme = createTheme(true);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div style={{ backgroundColor: theme.palette.background.default }}>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            <Header />
            <main className={classes.container}>
              <Toolbar />
              <div className={classes.children}>
                {Boolean(networkId === Number(process.env.REACT_APP_NETWORK_ID)) && children}
                <Notifier />
              </div>
            </main>
          </div>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
