import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_CLAIM_REWARD_BEGIN,
  FETCH_CLAIM_REWARD_SUCCESS,
  FETCH_CLAIM_REWARD_FAILURE,
} from './constants';
import { claimReward } from '../../web3';
import { fetchVotingEscrowDetails } from './action';

export function fetchClaimReward({ address, web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_CLAIM_REWARD_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      claimReward({ web3, address, dispatch })
        .then(data => {
          dispatch({
            type: FETCH_CLAIM_REWARD_SUCCESS,
            data,
          });
          dispatch(fetchVotingEscrowDetails());
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_CLAIM_REWARD_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaimReward() {
  const dispatch = useDispatch();

  const { fetchClaimRewardPending } = useSelector(state => ({
    fetchClaimRewardPending: state.pools.fetchClaimRewardPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchClaimReward(data));
    },
    [dispatch]
  );

  return {
    fetchClaimReward: boundAction,
    fetchClaimRewardPending,
  };
}

export function reducer(state, action) {
  let { fetchClaimRewardPending } = state;
  switch (action.type) {
    case FETCH_CLAIM_REWARD_BEGIN:
      fetchClaimRewardPending = true;
      return {
        ...state,
        fetchClaimRewardPending
      };

    case FETCH_CLAIM_REWARD_SUCCESS:
      fetchClaimRewardPending = false;
      return {
        ...state,
        fetchClaimRewardPending
      };

    case FETCH_CLAIM_REWARD_FAILURE:
      fetchClaimRewardPending = false;
      return {
        ...state,
        fetchClaimRewardPending
      };

    default:
      return state;
  }
}
