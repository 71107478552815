import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { useFetchRewardVestingDetails, useFetchWithdrawEarning } from '../../pools/redux/hooks';
import { useConnectWallet } from '../../home/redux/hooks';
import { contracts } from 'features/configure';

import VestedFieldDetail from './VestedFieldDetail.js';
import _ from "lodash";

const VestedField = ({ onlyFirst = false }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { web3, address } = useConnectWallet();
    const { rewardVestingDetails, fetchRewardVestingDetails } = useFetchRewardVestingDetails();


    useEffect(() => {
        if (address && contracts.rewardVestings && contracts.rewardVestings.length > 0) {
            fetchRewardVestingDetails();

            const id = setInterval(() => {
                fetchRewardVestingDetails();
            }, 10000);
            return () => clearInterval(id);
        }
    }, [address]);


    if (!contracts.rewardVestings || !contracts.rewardVestings.length > 0) return <div />
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
                <h1 className={classes.title}>
                    Your Vesting Rewards
                </h1>
                <div className={classes.card}>
                    {contracts.rewardVestings.map((d, index) =>
                        onlyFirst && index > 0 ? <div /> : <VestedFieldDetail key={index}
                            detail={rewardVestingDetails.length > index ? rewardVestingDetails[index] : {}} />
                    )}
                </div >
            </Grid >
            <Grid item xs={12} md={5}>
                <h1 className={classes.title}>
                    Vesting Rules
                </h1>
                <div className={classes.card} style={{ padding: 12 }}>
                    <ul className={classes.ul}>
                        <li className={classes.li}>All WASABI is vested for 90 days.</li>
                        <li className={classes.li}>Exiting a vest early always incurs a 50% penalty no matter how early or late you choose to exit.</li>
                        <li className={classes.li}>Penalties are distributed to veWASABI holders only and NOT vested.  <a href="/#/app/locker" className={classes.link}>{"How to get veWASABI? >>"}</a></li>
                        <li className={classes.li}>Locks are grouped by day so vests from the same day get pushed onto the same lock. Note: WASABI earned from farms are vested; WASABI earned from penalties are NOT vested.</li>
                    </ul>
                </div>
            </Grid>
        </Grid >
    )


};

export default VestedField;
