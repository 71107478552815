import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FORCE_COLLECT_BEGIN,
  FORCE_COLLECT_SUCCESS,
  FORCE_COLLECT_FAILURE,
} from './constants';
import { forceCollect } from '../../web3';
import { fetchTransmuterDetail, fetchForceTransmuterDetail } from './action';

export function doForceCollect({ address, web3, tokenId, collectAddress }) {
  return dispatch => {
    dispatch({
      type: FORCE_COLLECT_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      forceCollect({ web3, address, collectAddress, tokenId, dispatch })
        .then(data => {
          dispatch({
            type: FORCE_COLLECT_SUCCESS,
            data
          });
          dispatch(fetchTransmuterDetail(tokenId))
          dispatch(fetchForceTransmuterDetail(tokenId))
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FORCE_COLLECT_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useForceCollect() {
  const dispatch = useDispatch();

  const { forceCollectPending } = useSelector(state => ({
    forceCollectPending: state.transmuter.forceCollectPending,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(doForceCollect(data));
    },
    [dispatch]
  );

  return {
    forceCollect: boundAction,
    forceCollectPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FORCE_COLLECT_BEGIN:
      return {
        ...state,
        forceCollectPending: {
          ...state.forceCollectPending
        },
      };

    case FORCE_COLLECT_SUCCESS:
      return {
        ...state,
        forceCollectPending: {
          ...state.forceCollectPending
        },
      };

    case FORCE_COLLECT_FAILURE:
      return {
        ...state,
        forceCollectPending: {
          ...state.forceCollectPending
        },
      };

    default:
      return state;
  }
}
