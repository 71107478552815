import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_ETH_BALANCE_BEGIN,
  VAULT_FETCH_ETH_BALANCE_SUCCESS,
  VAULT_FETCH_ETH_BALANCE_FAILURE,
} from './constants';
import {convertAmountFromRawNumber} from '../../helpers/bignumber';

export function fetchEthBalance({ web3, address }) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_ETH_BALANCE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

      web3.eth.getBalance(address).then(data => {
          dispatch({
            type: VAULT_FETCH_ETH_BALANCE_SUCCESS,
            data
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_ETH_BALANCE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchEthBalance() {
  const dispatch = useDispatch();

  const { fetchEthBalancePending, ethBalance } = useSelector(state => ({
    fetchEthBalancePending: state.vault.fetchEthBalancePending,
    ethBalance: state.vault.ethBalance
  }));

  const boundAction = useCallback(
    (web3, address) => {
      return dispatch(fetchEthBalance(web3, address));
    },
    [dispatch]
  );

  return {
    fetchEthBalance: boundAction,
    fetchEthBalancePending,
    ethBalance
  };
}

export function reducer(state={fetchEthBalancePending:false,ethBalance:0}, action) {
  switch (action.type) {
    case VAULT_FETCH_ETH_BALANCE_BEGIN:
      return {
        ...state,
        fetchEthBalancePending: {
          ...state.fetchEthBalancePending
        },
      };

    case VAULT_FETCH_ETH_BALANCE_SUCCESS:
      return {
        ...state,
        ethBalance: convertAmountFromRawNumber(action.data),
        fetchEthBalancePending: {
          ...state.fetchEthBalancePending
        },
      };

    case VAULT_FETCH_ETH_BALANCE_FAILURE:
      return {
        ...state,
        fetchEthBalancePending: {
          ...state.fetchEthBalancePending
        },
      };

    default:
      return state;
  }
}
