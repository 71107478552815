import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import { useConnectWallet } from 'features/home/redux/hooks';
import {
  useFetchLiquityStatistics
} from 'features/vault/redux/hooks';

const useStyles = makeStyles(styles);

export default function LiquityStatistics() {
  const classes = useStyles();
  const { address } = useConnectWallet();
  let { details, fetchLiquityStatistics } = useFetchLiquityStatistics();

  useEffect(() => {
    if (address) {
      fetchLiquityStatistics();

      const id = setInterval(() => {
        fetchLiquityStatistics();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  // Borrowing Fee 0.54%
  // Redemption Fee 0.54%
  // TVL 1.10M ETH ($2.54B)
  // Troves 906
  // LUSD supply 1.21B
  // LUSD in Stability Pool 1.09B (89.7%)
  // Staked LQTY 1.05M
  // Total Collateral Ratio 210.3%
  // Recovery Mode No
  return (
    <div style={{ marginTop: '2rem' }}>
      <h3 className={classes.detailHeader}>Liquity Statistics</h3>
      <Grid container item justify="center" className={classes.detailContent}>
        <Grid item xs={6}>
          Borrowing Fee:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.borrowingFee ? details.borrowingFee : 'Loading...'}</Grid>
        <Grid item xs={6}>
          TVL:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.TVL ? details.TVL : 'Loading...'}</Grid>
        <Grid item xs={6}>
          Troves:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.troves ? parseFloat(details.troves) : 'Loading...'}</Grid>
        <Grid item xs={6}>
          LUSD supply:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.lUSDSupply ? details.lUSDSupply : 'Loading...'}</Grid>
        <Grid item xs={6}>
          LUSD in Stability Pool:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.lUSDInStabilityPool ? details.lUSDInStabilityPool : 'Loading...'} </Grid>
        <Grid item xs={6}>
          Staked LQTY:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.stakedLQTY ? details.stakedLQTY : 'Loading...'} </Grid>
        <Grid item xs={6}>
          Total Collateral Ratio:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.totalCollateralRatio ? details.totalCollateralRatio : 'Loading...'} </Grid>
        <Grid item xs={6}>
          Recovery Mode:
        </Grid>
        <Grid item xs={6} className={classes.tableContent}>{details.recoveryMode ? details.recoveryMode : 'Loading...'}</Grid>
      </Grid>
    </div>
  );
}
