import { useCallback } from 'react';
import BigNumber from "bignumber.js";
import { alUSDABI, tokens } from "../../configure";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  CHECK_ALUSD_APPROVAL_BEGIN,
  CHECK_ALUSD_APPROVAL_SUCCESS,
  CHECK_ALUSD_APPROVAL_FAILURE,
} from './constants';

export function checkAlUsdApproval(tokenAddress, contractAddress) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: CHECK_ALUSD_APPROVAL_BEGIN
    });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { home } = getState();
      const { address, web3 } = home;

      const contract = new web3.eth.Contract(alUSDABI, tokenAddress);
      contract.methods.allowance(address, contractAddress).call({ from: address }).then(
        data => {
          const balance = web3.utils.fromWei(data, "ether");
          dispatch({
            type: CHECK_ALUSD_APPROVAL_SUCCESS,
            data: new BigNumber(balance).toNumber()
          });
          resolve(data);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: CHECK_ALUSD_APPROVAL_FAILURE
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}


export function useCheckAlUsdApproval() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { allowance, checkApprovalPending } = useSelector(
    state => ({
      allowance: state.transmuter.alUsd.allowance,
      checkApprovalPending: state.transmuter.alUsd.checkApprovalPending,
    })
  );

  const boundAction = useCallback(
    (tokenAddress, contractAddress) => dispatch(checkAlUsdApproval(tokenAddress, contractAddress)),
    [dispatch],
  );

  return {
    allowance,
    checkApproval: boundAction,
    checkApprovalPending
  };
}

export function reducer(state, action) {
  let { allowance, checkApprovalPending } = state.alUsd;
  switch (action.type) {
    case CHECK_ALUSD_APPROVAL_BEGIN:
      // Just after a request is sent
      checkApprovalPending = true;
      let newAlUsdBegin = _.set(_.clone(state.alUsd),'checkApprovalPending',checkApprovalPending);
      return {
        ...state,
        alUsd:newAlUsdBegin,
      };

    case CHECK_ALUSD_APPROVAL_SUCCESS:
      // The request is success
      let newAlUsdSuccess = {...(_.clone(state.alUsd)),checkApprovalPending:false,allowance:action.data}

      checkApprovalPending = false;
      allowance = action.data;
      return {
        ...state,
        alUsd:newAlUsdSuccess
      };

    case CHECK_ALUSD_APPROVAL_FAILURE:
      // The request is failed
      checkApprovalPending = false;
      let newAlUsdFailure = _.set(_.clone(state.alUsd),'checkApprovalPending',checkApprovalPending);
      return {
        ...state,
        alUsd:newAlUsdFailure
      };

    default:
      return state;
  }
}
