import { primaryColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({

  detailHeader: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: "1rem",

    fontWeight: 800,
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
    fontSize: "1.3rem", fontWeight: 800,
    paddingBottom: 25,
  },
  tableContent: {
    textAlign: 'right',
    paddingBottom: 20,
    fontWeight: 600,
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 16, fontWeight: 600,
    textDecoration: "underline"
  },
});

export default styles;
