import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_CLAIM_AIRDROPS_BEGIN,
  FETCH_CLAIM_AIRDROPS_SUCCESS,
  FETCH_CLAIM_AIRDROPS_FAILURE,
} from './constants';
import { claimAirdrops } from '../../web3';

export function fetchClaimAirdrops({ address, web3, contractAddress }) {
  return dispatch => {
    dispatch({
      type: FETCH_CLAIM_AIRDROPS_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      claimAirdrops({ web3, address, contractAddress, dispatch })
        .then(data => {
          dispatch({
            type: FETCH_CLAIM_AIRDROPS_SUCCESS,
            data,
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: FETCH_CLAIM_AIRDROPS_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaimAirdrops() {
  const dispatch = useDispatch();

  const { fetchClaimAirdropsPending } = useSelector(state => ({
    fetchClaimAirdropsPending: state.pools.fetchClaimAirdropsPending,
  }));

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchClaimAirdrops(data));
    },
    [dispatch]
  );

  return {
    claimAirdrops: boundAction,
    fetchClaimAirdropsPending,
  };
}

export function reducer(state, action) {
  let { fetchClaimAirdropsPending } = state;
  switch (action.type) {
    case FETCH_CLAIM_AIRDROPS_BEGIN:
      fetchClaimAirdropsPending = true;
      return {
        ...state,
        fetchClaimAirdropsPending
      };

    case FETCH_CLAIM_AIRDROPS_SUCCESS:
      fetchClaimAirdropsPending = false;
      return {
        ...state,
        fetchClaimAirdropsPending
      };

    case FETCH_CLAIM_AIRDROPS_FAILURE:
      fetchClaimAirdropsPending = false;
      return {
        ...state,
        fetchClaimAirdropsPending
      };

    default:
      return state;
  }
}
