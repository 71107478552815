import initialState from './initialState';
import { reducer as fetchBalancesReducer } from './fetchBalances';
import { reducer as fetchVaultsData } from './fetchVaultsData';
import { reducer as fetchApprovalReducer } from './fetchApproval';
import { reducer as fetchDepositReducer } from './fetchDeposit';
import { reducer as fetchWithdrawReducer } from './fetchWithdraw';
import { reducer as fetchRepayReducer } from './fetchRepay';
import { reducer as fetchApysReducer } from './fetchApys';
import { reducer as fetchVaultDetailReducer } from './fetchVaultDetail';
import { reducer as fetchLiquityStatisticsReducer } from './fetchLiquityStatistics';
import { reducer as checkDaiApprovalReducer } from './checkDaiApproval';
import { reducer as checkAlusdApprovalReducer } from './checkAlusdApproval';
import { reducer as fetchEthBalanceReducer } from './fetchEthBalance';
import { reducer as fetchCurrentTroveReducer } from './fetchCurrentTrove';


const reducers = [
  fetchBalancesReducer,
  fetchVaultsData,
  fetchApprovalReducer,
  fetchDepositReducer,
  fetchWithdrawReducer,
  fetchApysReducer,
  fetchVaultDetailReducer,
  fetchLiquityStatisticsReducer,
  checkDaiApprovalReducer,
  checkAlusdApprovalReducer,
  fetchRepayReducer,
  fetchEthBalanceReducer,
  fetchCurrentTroveReducer
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
