import BigNumber from 'bignumber.js';
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { inputLimitPass, inputFinalVal } from 'features/helpers/utils';
import Button from 'components/CustomButtons/Button.js';
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import { useFetchRewardVestingDetails, useFetchWithdrawEarning } from '../redux/hooks';
import { useConnectWallet } from '../../home/redux/hooks';
import { contracts } from 'features/configure';
import CustomTable from 'components/CustomTable/CustomTable.js';
import VestedTable from './VestedTable';
import _ from "lodash";


const VestedFieldDetail = ({ detail }) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { web3, address } = useConnectWallet();
    const [balance, setBalance] = useState('');

    const { fetchWithdrawEarning, fetchWithdrawEarningPending } = useFetchWithdrawEarning();

    const setMaximumInput = event => {
        let value = detail.amount;

        if (!inputLimitPass(value, 18)) {
            return;
        }

        setBalance(inputFinalVal(value, value, 18));
    }

    const changeBalanceInputValue = event => {
        let value = event.target.value;
        const total = detail.amount;

        if (!inputLimitPass(value, 18)) {
            return;
        }

        setBalance(inputFinalVal(value, total, 18));
    };

    const onWithdraw = (amount) => {
        let amountValue = amount
            ? amount.replaceAll(',', '')
            : amount;
        const formated = new BigNumber(amountValue)
            .multipliedBy(new BigNumber(10).exponentiatedBy(18))
            .toString(10);
        fetchWithdrawEarning({
            address,
            web3,
            contractAddress: detail.address,
            amount: formated
        }).then(() => {
            setBalance('');
        });
    };
    const filterData = detail.earningsData ?
        _.filter(detail.earningsData, function (o) { return o.amount > 1e-10 }) : []

    return (
        <div>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={7} className={classes.contentHeader}>
                    Your {detail.token} Vesting Rewards:
                </Grid>
                <Grid item xs={12} md={5} className={classes.value}>
                    {isNaN(detail.totalEarningsInVesting) ? 'Loading...' : detail.totalEarningsInVesting > 0 ? parseFloat(detail.totalEarningsInVesting).toFixed(3) : '0'} WASABI
                </Grid>
                <Grid item xs={12} md={7} className={classes.contentHeader}>
                    Global {detail.token} Vesting Rewards:
                </Grid>
                <Grid item xs={12} md={5} className={classes.value}>
                    {isNaN(detail.totalPenalty) ? 'Loading...' : parseFloat(detail.totalPenalty).toFixed(3)} WASABI
                </Grid>
                <Grid item xs={12} className={classes.contentHeader} style={{ paddingBottom: 0 }}>
                    Your {detail.token} Vesting Schedule:
                </Grid>
                <Grid item xs={12}>
                    <VestedTable data={filterData} />
                    {/* <CustomTable headers={["Start Date", "End Date", "Days", "Vesting Amount"]} contents={
                        filterData.map(data => {
                            return [
                                data.startDate,
                                data.endDate,
                                data.remainingDays,
                                parseFloat(data.amount).toFixed(2)
                            ];
                        })
                    } /> */}
                </Grid>
                <Grid item xs={12} md={8} className={classes.contentHeader}>
                    Available(= Vesting - Penalty):
                </Grid>
                <Grid item xs={12} md={4} className={classes.value}>
                    {isNaN(detail.amount) ? 'Loading...' : `${parseFloat(detail.amount).toFixed(3)} ${detail.token}`}
                </Grid>
                <Grid item xs={12} md={8} className={classes.contentHeader}>
                    Available Withdraw Amount Without Penalty:
                </Grid>
                <Grid item xs={12} md={4} className={classes.value}>
                {isNaN(detail.amountWithoutPenalty)
                    ? 'Loading...'
                    : `${parseFloat(detail.amountWithoutPenalty).toFixed(3)} ${detail.token}`}
                </Grid>
            </Grid>
            <div style={{ padding: "20px 0" }}>
                <CustomOutlinedInput value={balance} onChange={changeBalanceInputValue} setMax={setMaximumInput} />
            </div>
            <Button
                fullWidth color="green"
                disabled={!Boolean(balance) || balance === "0" || fetchWithdrawEarningPending}
                onClick={() => {
                    onWithdraw(balance)
                }}>
                Withdraw
            </Button>
        </div>


    );



};

export default VestedFieldDetail;
