import React, { useEffect, useState, useRef } from 'react';
import Button from 'components/CustomButtons/Button.js';
import { Grid, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import YumInstruction from '../instruction/YumInstruction';
import LockerField from './components/LockerField';
import LockerReward from './components/LockerReward';
import LockerStakeFarm from './components/LockerStakeFarm';
import { useFetchVotingEscrowDetails } from 'features/pools/redux/hooks';
import { useParams, Link } from 'react-router-dom';
import { useConnectWallet } from '../home/redux/hooks';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 800,
    paddingTop: 15,
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  textTitle: {
    textAlign: 'center',
    fontWeight: 900,
    paddingTop: 10,
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },
  textValue: {
    textAlign: 'center',
    fontWeight: 900,
    padding: 20,
    fontSize: '1rem',
    color: theme.palette.text.primary,
  },
  greenValue: {
    fontWeight: 900,
    height: 120,
    padding: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    color: theme.palette.text.green,
  },
  grayValue: {
    fontWeight: 900,
    height: 120,
    padding: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    color: 'gray',
  },
}));
export default function Locker(props) {
  const classes = useStyles();
  const { address } = useConnectWallet();
  const { votingEscrowDetails, fetchVotingEscrowDetails, fetchVotingEscrowDetailsPending } =
    useFetchVotingEscrowDetails();
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    if (address) {
      fetchVotingEscrowDetails();
      const id = setInterval(() => {
        fetchVotingEscrowDetails();
      }, 10000);
      return () => clearInterval(id);
    }
  }, [address]);

  return (
    <>
      <YumInstruction value={3} />
      <div className="block">
        <h1 className="header">Lock WASABI and receive multiple benefits from Wasabix Finance -</h1>
        <div className="card" style={{ padding: 0 }}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={3} style={{ borderRight: '1px solid white' }}>
              <div className={classes.greenValue}>
                <span style={{ width: '100%' }}>Farm WASABI</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} style={{ borderRight: '1px solid white' }}>
              <div className={classes.greenValue}>Earn Platform fees and Penalty rewards</div>
            </Grid>
            <Grid item xs={12} sm={3} style={{ borderRight: '1px solid white', paddingTop: -20 }}>
              <div className={classes.greenValue}>Boost Farm rewards by up to 2.5x</div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className={classes.greenValue}>
                <span style={{ width: '100%' }}>Receive Airdrops</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="block">
        <h1 className={'header'}>Locking Status</h1>
        <LockerField votingEscrowDetails={votingEscrowDetails} />
      </div>
      <div className="block">
        <h1 className={'header'} ref={myRef}>
          Single Token Farming(Lock WASABI to earn WASABI)
        </h1>
        <LockerStakeFarm />
      </div>
      <div className="block">
        <LockerReward />
      </div>
    </>
  );
}
