import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_SINGLE_LOCKER_REWARD_BEGIN,
  FETCH_SINGLE_LOCKER_REWARD_SUCCESS,
  FETCH_SINGLE_LOCKER_REWARD_FAILURE,
} from './constants';

import { lockerRewardABI, contracts, merkleRewardABI } from '../../configure';
import { MultiCall } from 'eth-multicall';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import BigNumber from 'bignumber.js';
export function fetchSingleLockerReward({ address, web3, rewards }) {
  return dispatch => {
    dispatch({
      type: FETCH_SINGLE_LOCKER_REWARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const multicall = new MultiCall(web3, contracts.multicall.address);

      const calls = [];
      const dataMap = {};
      let dataIndex = 0;
      for (let r of rewards) {
        if (r.balanceTree) {
          const merkleContract = new web3.eth.Contract(merkleRewardABI, r.contract);
          calls.push({ result: merkleContract.methods.isClaimed(r.balanceTree.index) });
          dataMap[r.contract] = { dataIndex, isMerkle: true };
          dataIndex += 1;
        } else {
          const airdropContract = new web3.eth.Contract(lockerRewardABI, r.contract);
          calls.push({ result: airdropContract.methods.getInitialRewardAmount(address) });
          calls.push({ result: airdropContract.methods.getDistributedAmount(address) });
          calls.push({ result: airdropContract.methods.getAvailableAmount(address) });
          dataMap[r.contract] = { dataIndex, isMerkle: false };
          dataIndex += 3;
        }
      }

      multicall
        .all([calls])
        .then(([results]) => {
          const output = {};
          for (let r of rewards) {
            let rewardIndex = dataMap[r.contract].dataIndex;
            if (dataMap[r.contract].isMerkle) {
              output[r.contract] = {
                ...r.balanceTree,
                initAmount: convertAmountFromRawNumber(r.balanceTree.amount),
                distributedAmount: convertAmountFromRawNumber(r.balanceTree.amount),
                availableAmount: results[rewardIndex].result
                  ? 0
                  : convertAmountFromRawNumber(r.balanceTree.amount),
              };
            } else {
              output[r.contract] = {
                initAmount: convertAmountFromRawNumber(
                  new BigNumber(results[rewardIndex + 0].result)
                ),
                distributedAmount: convertAmountFromRawNumber(
                  new BigNumber(results[rewardIndex + 1].result)
                ),
                availableAmount: convertAmountFromRawNumber(
                  new BigNumber(results[rewardIndex + 2].result)
                ),
              };
            }
          }
          dispatch({
            type: FETCH_SINGLE_LOCKER_REWARD_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: FETCH_SINGLE_LOCKER_REWARD_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchSingleLockerReward() {
  const dispatch = useDispatch();

  const { fetchSingleLockerRewardPending, singleLockerReward } = useSelector(state => ({
    singleLockerReward: state.pools.singleLockerReward,
    fetchSingleLockerRewardPending: state.pools.fetchSingleLockerRewardPending,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchSingleLockerReward(data));
    },
    [dispatch]
  );

  return {
    singleLockerReward,
    fetchSingleLockerReward: boundAction,
    fetchSingleLockerRewardPending,
  };
}

export function reducer(state, action) {
  let { fetchSingleLockerRewardPending, singleLockerReward } = state;
  switch (action.type) {
    case FETCH_SINGLE_LOCKER_REWARD_BEGIN:
      fetchSingleLockerRewardPending = true;
      return {
        ...state,
        fetchSingleLockerRewardPending,
      };

    case FETCH_SINGLE_LOCKER_REWARD_SUCCESS:
      fetchSingleLockerRewardPending = false;
      singleLockerReward = action.data;
      return {
        ...state,
        singleLockerReward,
        fetchSingleLockerRewardPending,
      };

    case FETCH_SINGLE_LOCKER_REWARD_FAILURE:
      fetchSingleLockerRewardPending = false;
      return {
        ...state,
        fetchSingleLockerRewardPending,
      };

    default:
      return state;
  }
}
