import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Hidden from '@material-ui/core/Hidden';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import StepConnector from '@material-ui/core/StepConnector';
import { useTranslation } from 'react-i18next';
import strategy from '../../assets/img/strategy_white.png';
import farm from '../../assets/img/farm_white.png';
import minter from '../../assets/img/minter_white.png';
import locker from '../../assets/img/locker_white.png';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Link, useParams } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert from '@material-ui/lab/Alert';
import { tokens } from '../configure';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 30,
  },
  active: {
    '& $line': {
      backgroundColor:
        'white',
    },
  },
  completed: {
    '& $line': {
      backgroundColor:
        'white',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(180,180,180)',
    zIndex: 1,
    color: '#fff',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: "rgb(140,186,56)",
    color: theme.palette.text.green,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: "rgb(140,186,56)",
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: <img src={strategy} style={{ height: 30, verticalAlign: 'middle' }} alt='' />,
    2: <img src={minter} style={{ height: 30, verticalAlign: 'middle' }} alt='' />,
    3: <img src={farm} style={{ height: 30, verticalAlign: 'middle' }} alt='' />,
    4: <img src={locker} style={{ height: 30, verticalAlign: 'middle' }} alt='' />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}

    </div>
  );
}


ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center", fontWeight: 800,
    fontSize: 30, lineHeight: 1.5
  },
  subTitle: {
    color: theme.palette.text.primary,
    textAlign: "center", fontWeight: 600,
    fontSize: 18, lineHeight: 1.5
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 14, fontWeight: 700
  },
  labelLink: {
    color: theme.palette.text.primary,
    fontSize: 14, fontWeight: 700,
    textDecoration: "underline"
  }
}));



export default function YumInstruction(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchComputer = useMediaQuery(theme.breakpoints.up('md'));
  let activeStep = props.value;
  const keys = ["Strategies", "Minters", "Farms", "WASABI Locker"]

  return (
    <>
      <div >
        <div className={classes.title}>Lending 3.0 </div>
        <div className={classes.title}>{"Step " + (activeStep + 1) + ": " + keys[activeStep]}</div>
        <Hidden mdDown>
          <Grid container style={{ marginTop: 40, padding: '0 24px' }} alignItems="center">
            {keys.map((k, index) => <Grid key={index} item xs={3} className={classes.subTitle}>{"Step " + (index + 1)}</Grid>)}
          </Grid>
        </Hidden>
        <Stepper alternativeLabel={matchComputer} activeStep={activeStep} connector={<ColorlibConnector />}
          orientation={matchComputer ? "horizontal" : "vertical"} style={{ backgroundColor: "transparent" }}>
          <Step key={keys[0]}>
            <StepLabel StepIconComponent={ColorlibStepIcon} >
              <div className={classes.label}>Deposit to</div>
              <Link className={classes.labelLink} to="/app/strategies" >
                {keys[0]}
              </Link>
            </StepLabel>
            <div style={{
              position: "absolute", right: -75, top: 40, borderTop: "3px dashed white",
              textAlign: "center", width: 150, paddingTop: 5
            }}
              className={classes.label}>
              self-repay
            </div>
          </Step>
          <Step key={keys[1]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <div className={classes.label}>Mint waToken in
              </div>
              <Link className={classes.labelLink} to="/app/minters" >
                {keys[1]}
              </Link>
            </StepLabel>
          </Step>
          <Step key={keys[2]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <div className={classes.label}>Farm in </div>
              <Link className={classes.labelLink} to="/app/farms" >
                {keys[2]}
              </Link>
            </StepLabel>
          </Step>
          <Step key={keys[3]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <div className={classes.label}>Boost APY in </div>
              <Link className={classes.labelLink} to="/app/locker" >
                {keys[3]}
              </Link>
            </StepLabel>
          </Step>
        </Stepper>

      </div>
    </>
  );
}
