const styles = theme => ({
  title: {
    fontSize: "0.9rem",
    fontWeight: 600,
    paddingBottom: 10,
    paddingTop: 10,
    color: theme.palette.text.secondary,
  },
  tableTitle: {
    border: "none",
  },
  outlinedTitle: {
    border: `1px solid ${theme.palette.text.primary}`,
  },

  columnTitle: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    borderRight: `1px solid ${theme.palette.text.primary}`,
  },
  value: {
    fontSize: "0.95rem",
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  card: {
    flexGrow: 1,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "auto",
    borderRadius: 5,
    marginTop: 10
  },
  tableValue: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  outlinedValue: {
    border: `1px solid ${theme.palette.text.primary}`,
    padding: 10
  },
  columnValue: {
    borderRight: `1px solid ${theme.palette.text.primary}`,
    borderBottom: "none"
  },
  selected: {
    backgroundColor: "rgb(41,76,52)",
  }

});

export default styles;
