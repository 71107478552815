import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_VOTING_ESCROW_DETAILS_BEGIN,
  FETCH_VOTING_ESCROW_DETAILS_SUCCESS,
  FETCH_VOTING_ESCROW_DETAILS_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import { votingEscrowABI, erc20ABI, tokens, contracts } from '../../configure';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';
import BigNumber from 'bignumber.js';
const WASABI_PER_BLOCK = new BigNumber(54687500000000000);
const BLOCKS_PER_YEAR = new BigNumber(2368850);
export function fetchVotingEscrowDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_VOTING_ESCROW_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;
      const { wasabiPrice } = price.priceData;
      const votinEscrowContract = new web3.eth.Contract(
        votingEscrowABI,
        contracts.votingEscrow.address
      );
      const multicall = new MultiCall(web3, contracts.multicall.address);
      const wasabiContract = new web3.eth.Contract(erc20ABI, tokens.wasabi.address);

      let calls = [
        { result: wasabiContract.methods.balanceOf(address) },
        { result: votinEscrowContract.methods.amountOf(address) },
        { result: votinEscrowContract.methods.balanceOf(address) },
        { result: votinEscrowContract.methods.endOf(address) },
        { result: votinEscrowContract.methods.startOf(address) },
        { result: votinEscrowContract.methods.totalLockedWASABI() },
        { result: votinEscrowContract.methods.totalSupply() },
        { result: wasabiContract.methods.allowance(address, contracts.votingEscrow.address) },
        { result: wasabiContract.methods.totalSupply() },
        { result: votinEscrowContract.methods.pendingWasabi(address) },
      ];

      for (var i = 0; i < contracts.votingEscrow.rewardTokens.length; i++) {
        calls.push({
          result: votinEscrowContract.methods.pendingReward(
            address,
            contracts.votingEscrow.rewardTokens[i].address
          ),
        });
      }

      multicall
        .all([calls])
        .then(([results]) => {
          let output = {};
          const userWasabiBalance = new BigNumber(results[0].result);
          const userLocked = new BigNumber(results[1].result);
          const userVeWasabi = new BigNumber(results[2].result);
          const totalLocked = new BigNumber(results[5].result);
          const totalSupply = new BigNumber(results[6].result);
          const allowance = new BigNumber(results[7].result);
          const totalWasabiSupply = new BigNumber(results[8].result);
          const pendingWasabi = new BigNumber(results[9].result);
          const rewardTokenPerYear = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR);
          const apy = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR).div(totalSupply);
          const shareOfPool = userVeWasabi.div(totalSupply);
          output = {
            userWasabiBalance: convertAmountFromRawNumber(userWasabiBalance),
            userLocked: convertAmountFromRawNumber(userLocked),
            userVeWasabi: convertAmountFromRawNumber(userVeWasabi),
            userLockedEnd: results[3].result,
            userLockedStart: results[4].result,
            totalLocked: convertAmountFromRawNumber(totalLocked),
            totalSupply: convertAmountFromRawNumber(totalSupply),
            allowance: convertAmountFromRawNumber(allowance),
            totalWasabiSupply: convertAmountFromRawNumber(totalWasabiSupply),
            pendingRewards: [],
            pendingWasabi: convertAmountFromRawNumber(pendingWasabi),
            tokenPrice: wasabiPrice,
            apy,
            shareOfPool,
            rewardTokenPerYear: convertAmountFromRawNumber(rewardTokenPerYear),
          };

          for (var i = 0; i < contracts.votingEscrow.rewardTokens.length; i++) {
            const tokenAddr = contracts.votingEscrow.rewardTokens[i].address;
            const tokenSymbol = contracts.votingEscrow.rewardTokens[i].token;
            const needVesting = contracts.votingEscrow.rewardTokens[i].needVesting;
            const pendingReward = new BigNumber(results[10 + i].result);
            output.pendingRewards.push({
              address: tokenAddr,
              rewards: convertAmountFromRawNumber(pendingReward),
              token: tokenSymbol,
              needVesting,
            });
          }

          dispatch({
            type: FETCH_VOTING_ESCROW_DETAILS_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: FETCH_VOTING_ESCROW_DETAILS_FAILURE,
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchVotingEscrowDetails() {
  const dispatch = useDispatch();

  const { votingEscrowDetails, fetchVotingEscrowDetailsPending, fetchVotingEscrowDetailsDone } =
    useSelector(state => ({
      votingEscrowDetails: state.pools.votingEscrowDetails,
      fetchVotingEscrowDetailsPending: state.pools.fetchVotingEscrowDetailsPending,
      fetchVotingEscrowDetailsDone: state.pools.fetchVotingEscrowDetailsDone,
    }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchVotingEscrowDetails(data));
    },
    [dispatch]
  );

  return {
    votingEscrowDetails,
    fetchVotingEscrowDetails: boundAction,
    fetchVotingEscrowDetailsDone,
    fetchVotingEscrowDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_VOTING_ESCROW_DETAILS_BEGIN:
      return {
        ...state,
        fetchVotingEscrowDetailsPending: true,
      };

    case FETCH_VOTING_ESCROW_DETAILS_SUCCESS:
      return {
        ...state,
        votingEscrowDetails: action.data,
        fetchVotingEscrowDetailsDone: true,
        fetchVotingEscrowDetailsPending: false,
      };

    case FETCH_VOTING_ESCROW_DETAILS_FAILURE:
      return {
        ...state,
        fetchVotingEscrowDetailsPending: false,
      };

    default:
      return state;
  }
}
