import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPools } from '../../features/pools/redux/action';
import { fetchAllVaultDetails } from '../../features/vault/redux/action';
import { fetchAllTransmuterDetail } from '../../features/collector/redux/action';
import { convertAmountFromRawNumber } from '../../features/helpers/bignumber';
import { strategiesData } from 'features/configure';
export function useTVL() {
  const dispatch = useDispatch();

  const {
    tlv,
    tvls,
    collectorTvls,
    wausdCollectorTlv,
    wabtcCollectorTlv,
    waethCollectorTlv,
    walusdCollectorTlv,
    vaultTlv,
    yearnVaultTlv,
    idleVaultTlv,
    pickleVaultTlv,
    vesperWbtcVaultTlv,
    vesperWethVaultTlv,
    liquityVaultTlv,
    poolL,
    totalLockedWasabi,
    poolTlv,
    cmc,
    cs,
  } = useSelector(state => {
    let wasabiPrice = state.price.priceData.wasabiPrice;
    let wbtcPrice = state.price.priceData.wbtcPrice;
    let wethPrice = state.price.priceData.wethPrice;
    let lpData = state.price.priceData.lpData;
    let linkPrice = state.price.priceData.linkPrice;
    let wasabiCirculatingMarketCap = state.price.priceData.wasabiCMC;
    let wasabiCirculatingSupply = state.price.priceData.wasabiCirculatingSupply;

    let lpTotalSupply = lpData && lpData.totalSupply ? lpData.totalSupply : 1;
    let reserve0 = lpData && lpData.reserve0 ? lpData.reserve0 : 0;

    // pool 0
    let pool0TLV = state.pools.context[0] ? parseFloat(state.pools.context[0].totalStaked) * 1 : 0;

    // pool 1 (LP)
    let pool1TLV =
      state.pools.context[1] && lpData
        ? parseFloat(state.pools.context[1].totalStaked / lpTotalSupply) *
          reserve0 *
          wasabiPrice *
          2
        : 0;

    // pool 2 (3CRV)
    let pool2TLV = state.pools.context[2] ? parseFloat(state.pools.context[2].totalStaked) * 1 : 0;

    // pool 3 (waBTC)
    let pool3TLV = state.pools.context[3]
      ? parseFloat(state.pools.context[3].totalStaked) * wbtcPrice
      : 0;

    // pool 4 (waLUSD)
    let pool4TLV = state.pools.context[4] ? parseFloat(state.pools.context[4].totalStaked) * 1 : 0;

    // pool 5 (waETH)
    let pool5TLV = state.pools.context[5]
      ? parseFloat(state.pools.context[5].totalStaked) * wethPrice
      : 0;

    // pool 6 (waLINK)
    let pool6TLV = state.pools.context[6]
      ? parseFloat(state.pools.context[6].totalStaked) * linkPrice
      : 0;
    // pool 7 (waLINK/LINK SLP)
    let pool7TLV = state.pools.context[7]
      ? parseFloat(state.pools.context[7].totalStaked) * linkPrice * 2
      : 0;

    let poolL = pool0TLV + pool1TLV + pool2TLV + pool3TLV + pool4TLV + pool5TLV + pool6TLV + pool7TLV;

    let wasabiLockedPoolZero = state.pools.context[0]
      ? parseFloat(state.pools.context[0].totalStaked)
      : 0;
    let wasabiLockedPoolTwo = state.pools.context[4]
      ? parseFloat(state.pools.context[4].totalStaked / lpTotalSupply) * reserve0
      : 0;

    let totalLockedWasabi = wasabiLockedPoolZero + wasabiLockedPoolTwo;

    let tvls = {};
    strategiesData.map(strategy => {
      let value = state.vault.details[strategy.tokenId][strategy.name]
        ? Number(state.vault.details[strategy.tokenId][strategy.name].totalLockedValue)
        : 0;
      if (strategy.tokenId == 'wbtc') {
        value *= wbtcPrice;
      }
      if (strategy.tokenId == 'weth') {
        value *= wethPrice;
      }
      if (strategy.tokenId == 'link') {
        value *= linkPrice;
      }
      _.setWith(tvls, `[${strategy.tokenId}][${strategy.name}]`, value, Object);
    });

    let yearnVaultTlv = state.vault.details['dai']['yearn']
      ? Number(state.vault.details['dai']['yearn'].totalLockedValue)
      : 0;
    let idleVaultTlv = state.vault.details['dai']['idle']
      ? Number(state.vault.details['dai']['idle'].totalLockedValue)
      : 0;
    let pickleVaultTlv = state.vault.details['dai']['pickle']
      ? Number(state.vault.details['dai']['pickle'].totalLockedValue)
      : 0;

    let vesperWbtcVaultTlv = state.vault.details['wbtc']['vesper']
      ? Number(state.vault.details['wbtc']['vesper'].totalLockedValue) * wbtcPrice
      : 0;
    let vesperWethVaultTlv = state.vault.details['weth']['vesper']
      ? Number(state.vault.details['weth']['vesper'].totalLockedValue) * wethPrice
      : 0;
    let liquityVaultTlv = state.vault.details['lusd']['liquity']
      ? Number(state.vault.details['lusd']['liquity'].totalLockedValue)
      : 0;

    let collectorDaiTlv = state.transmuter.details['wausd']
      ? Number(state.transmuter.details['wausd'].transmuterDaiBalance)
      : 0;
    let collectorWbtcTlv = state.transmuter.details['wabtc']
      ? Number(state.transmuter.details['wabtc'].transmuterWbtcBalance) * wbtcPrice
      : 0;
    let collectorLusdTlv = state.transmuter.details['walusd']
      ? Number(state.transmuter.details['walusd'].transmuterLusdBalance)
      : 0;
    let collectorWethTlv = state.transmuter.details['waeth']
      ? Number(state.transmuter.details['waeth'].transmuterWethBalance) * wethPrice
      : 0;
    let collectorLinkTlv = state.transmuter.details['walink']
      ? Number(state.transmuter.details['walink'].transmuterLinkBalance) * linkPrice
      : 0;
    let collectorTvls = {
      collectorDaiTlv,
      collectorWbtcTlv,
      collectorLusdTlv,
      collectorWethTlv,
      collectorLinkTlv,
    };
    let vaultL =
      yearnVaultTlv +
      idleVaultTlv +
      pickleVaultTlv +
      vesperWbtcVaultTlv +
      vesperWethVaultTlv +
      liquityVaultTlv;

    return {
      tlv:
        poolL +
        vaultL +
        collectorDaiTlv +
        collectorWbtcTlv +
        collectorLusdTlv +
        collectorWethTlv +
        collectorLinkTlv,
      tvls: tvls,
      collectorTvls: collectorTvls,
      wausdCollectorTlv: collectorDaiTlv,
      wabtcCollectorTlv: collectorWbtcTlv,
      walusdCollectorTlv: collectorLusdTlv,
      waethCollectorTlv: collectorWethTlv,
      walinkcollectorTlv: collectorLinkTlv,
      vaultTlv: vaultL,
      yearnVaultTlv: yearnVaultTlv,
      idleVaultTlv: idleVaultTlv,
      pickleVaultTlv: pickleVaultTlv,
      vesperWbtcVaultTlv: vesperWbtcVaultTlv,
      vesperWethVaultTlv: vesperWethVaultTlv,
      liquityVaultTlv: liquityVaultTlv,
      poolL: poolL,
      poolTlv: {
        0: pool0TLV,
        1: pool1TLV,
        2: pool2TLV,
        3: pool3TLV,
        4: pool4TLV,
        5: pool5TLV,
        6: pool6TLV,
        7: pool7TLV
      },
      cmc: convertAmountFromRawNumber(wasabiCirculatingMarketCap),
      cs: convertAmountFromRawNumber(wasabiCirculatingSupply),
      totalLockedWasabi: totalLockedWasabi,
    };
  });

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  const boundAction2 = useCallback(() => {
    return dispatch(fetchAllVaultDetails());
  }, [dispatch]);

  const boundAction3 = useCallback(() => {
    return dispatch(fetchAllTransmuterDetail());
  }, [dispatch]);

  return {
    fetchP: boundAction,
    fetchV: boundAction2,
    fetchT: boundAction3,
    tlv: tlv,
    tvls: tvls,
    collectorTvls: collectorTvls,
    yearnVaultTlv: yearnVaultTlv,
    idleVaultTlv: idleVaultTlv,
    pickleVaultTlv: pickleVaultTlv,
    vesperWbtcVaultTlv: vesperWbtcVaultTlv,
    vesperWethVaultTlv: vesperWethVaultTlv,
    liquityVaultTlv: liquityVaultTlv,
    poolTlv: poolTlv,
    cmc: cmc,
    cs: cs,
    totalLockedWasabi: totalLockedWasabi,
    wausdCollectorTlv: wausdCollectorTlv,
    wabtcCollectorTlv: wabtcCollectorTlv,
    walusdCollectorTlv: walusdCollectorTlv,
    waethCollectorTlv: waethCollectorTlv,
    vaultTlv: vaultTlv,
    poolL: poolL,
  };
}
