import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  TRANSMUTER_DETAIL_FETCH_BEGIN,
  TRANSMUTER_DETAIL_FETCH_SUCCESS,
  TRANSMUTER_DETAIL_FETCH_FAILURE,
} from './constants';
import { MultiCall } from 'eth-multicall';
import {
  erc20ABI,
  alUSDABI,
  transmuterABI,
  alchmistABI,
  tokens,
  contracts,
  wethABI,
} from 'features/configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

export function fetchAllTransmuterDetail() {
  return dispatch => {
    return Object.keys(contracts.transmuter).map(tokenId => {
      dispatch(fetchTransmuterDetail(tokenId));
    });
  };
}

export function fetchTransmuterDetail(tokenId) {
  return (dispatch, getState) => {
    dispatch({
      type: TRANSMUTER_DETAIL_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { address, web3 } = home;

      const decimals = tokens[tokenId].decimals;

      const multicall = new MultiCall(web3, contracts.multicall.address);
      const transmuterAddress = contracts.transmuter[tokenId].address;
      const transmuterContract = new web3.eth.Contract(transmuterABI, transmuterAddress);

      if (tokenId === 'wabtc') {
        const wbtcContract = new web3.eth.Contract(erc20ABI, tokens.wbtc.address);
        const wabtcContract = new web3.eth.Contract(alUSDABI, tokens.wabtc.address);

        const alchemistVesperAddress = contracts.vault['wbtc']['vesper'].address;
        const alchemistVesperContract = new web3.eth.Contract(alchmistABI, alchemistVesperAddress);

        const calls = [
          { result: wbtcContract.methods.balanceOf(transmuterAddress) },
          { result: wabtcContract.methods.balanceOf(transmuterAddress) },
          { result: wabtcContract.methods.balanceOf(address) },
          { result: wbtcContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistVesperContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterWbtcBalance = new BigNumber(results[0].result);
            const transmuterWaBtcBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);

            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const wbtcDepositedInVesperDaiVault = new BigNumber(results[5].result);

            const output = {
              transmuterWbtcBalance: convertAmountFromRawNumber(transmuterWbtcBalance, decimals),
              transmuterWaBtcBalance: convertAmountFromRawNumber(transmuterWaBtcBalance, decimals),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              wbtcDepositedInVesperDaiVault: convertAmountFromRawNumber(
                wbtcDepositedInVesperDaiVault,
                decimals
              ),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      } else if (tokenId === 'waeth') {
        //waETH
        const wethContract = new web3.eth.Contract(wethABI, tokens.weth.address);
        const waethContract = new web3.eth.Contract(alUSDABI, tokens.waeth.address);
        const alchemistVesperAddress = contracts.vault['weth']['vesper'].address;
        const alchemistVesperContract = new web3.eth.Contract(alchmistABI, alchemistVesperAddress);

        const calls = [
          { result: wethContract.methods.balanceOf(transmuterAddress) },
          { result: waethContract.methods.balanceOf(transmuterAddress) },
          { result: waethContract.methods.balanceOf(address) },
          { result: wethContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistVesperContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterWethBalance = new BigNumber(results[0].result);
            const transmuterWaEthBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);
            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const wethDepositedInVesperDaiVault = new BigNumber(results[5].result);

            const output = {
              transmuterWethBalance: convertAmountFromRawNumber(transmuterWethBalance, decimals),
              transmuterWaEthBalance: convertAmountFromRawNumber(transmuterWaEthBalance, decimals),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              wethDepositedInVesperDaiVault: convertAmountFromRawNumber(
                wethDepositedInVesperDaiVault,
                decimals
              ),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      } else if (tokenId === 'walink') {
        //waETH
        const linkContract = new web3.eth.Contract(erc20ABI, tokens.link.address);
        const walinkContract = new web3.eth.Contract(alUSDABI, tokens.walink.address);
        const alchemistVesperAddress = contracts.vault['link']['vesper'].address;
        const alchemistVesperContract = new web3.eth.Contract(alchmistABI, alchemistVesperAddress);

        const calls = [
          { result: linkContract.methods.balanceOf(transmuterAddress) },
          { result: walinkContract.methods.balanceOf(transmuterAddress) },
          { result: walinkContract.methods.balanceOf(address) },
          { result: linkContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistVesperContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterLinkBalance = new BigNumber(results[0].result);
            const transmuterWaLinkBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);
            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const linkDepositedInVesperDaiVault = new BigNumber(results[5].result);

            const output = {
              transmuterLinkBalance: convertAmountFromRawNumber(transmuterLinkBalance, decimals),
              transmuterWaLinkBalance: convertAmountFromRawNumber(
                transmuterWaLinkBalance,
                decimals
              ),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              linkDepositedInVesperDaiVault: convertAmountFromRawNumber(
                linkDepositedInVesperDaiVault,
                decimals
              ),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      } else if (tokenId === 'walusd') {
        const lusdContract = new web3.eth.Contract(erc20ABI, tokens.lusd.address);
        const walusdContract = new web3.eth.Contract(alUSDABI, tokens.walusd.address);

        const alchemistLiquityAddress = contracts.vault['lusd']['liquity'].address;
        const alchemistLiquityContract = new web3.eth.Contract(
          alchmistABI,
          alchemistLiquityAddress
        );

        const oldTransmuterContractAddress = "0xB208dec45eDBD1179d9e275C5D459E6282d606ea";
        const oldTransmuterContract = new web3.eth.Contract(transmuterABI, oldTransmuterContractAddress);


        const calls = [
          { result: lusdContract.methods.balanceOf(transmuterAddress) },
          { result: walusdContract.methods.balanceOf(transmuterAddress) },
          { result: walusdContract.methods.balanceOf(address) },
          { result: lusdContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistLiquityContract.methods.getVaultTotalDeposited(4) },
          { result: oldTransmuterContract.methods.userInfo(address) },
          { result: lusdContract.methods.balanceOf(oldTransmuterContractAddress) },
          { result: walusdContract.methods.balanceOf(oldTransmuterContractAddress) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterLusdBalance = new BigNumber(results[0].result);
            const transmuterWaLusdBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];
            const oldUserInfo = results[6];
            const oldTransmuterLusdBalance = new BigNumber(results[7].result);
            const oldTransmuterWalusdBalance = new BigNumber(results[8].result);


            let stakedAlUsdBalance = new BigNumber(0);
            let oldStakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);
            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            if(oldUserInfo.result){
              oldStakedAlUsdBalance = new BigNumber(oldUserInfo.result[0]);
            }

            const lusdDepositedInLiquityLusdVault = new BigNumber(results[5].result);

            const output = {
              transmuterLusdBalance: parseFloat(convertAmountFromRawNumber(transmuterLusdBalance, decimals)) + parseFloat(convertAmountFromRawNumber(oldTransmuterLusdBalance, decimals)),
              transmuterWaLusdBalance: parseFloat(convertAmountFromRawNumber(
                transmuterWaLusdBalance,
                decimals
              )) + parseFloat(convertAmountFromRawNumber(
                oldTransmuterWalusdBalance,
                decimals
              )),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              lusdDepositedInLiquityLusdVault: convertAmountFromRawNumber(
                lusdDepositedInLiquityLusdVault,
                decimals
              ),
              oldStakedAlUsdBalance: convertAmountFromRawNumber(oldStakedAlUsdBalance, decimals),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      } else {
        //waUSD
        const daiContract = new web3.eth.Contract(erc20ABI, tokens.dai.address);
        const wausdContract = new web3.eth.Contract(alUSDABI, tokens.wausd.address);

        const alchemistYearnAddress = contracts.vault['dai']['yearn'].address;
        const alchemistIdleAddress = contracts.vault['dai']['idle'].address;
        const alchemistPickleAddress = contracts.vault['dai']['pickle'].address;

        const alchemistYearnContract = new web3.eth.Contract(alchmistABI, alchemistYearnAddress);
        const alchemistIdleContract = new web3.eth.Contract(alchmistABI, alchemistIdleAddress);
        const alchemistPickleContract = new web3.eth.Contract(alchmistABI, alchemistPickleAddress);

        const calls = [
          { result: daiContract.methods.balanceOf(transmuterAddress) },
          { result: wausdContract.methods.balanceOf(transmuterAddress) },
          { result: wausdContract.methods.balanceOf(address) },
          { result: daiContract.methods.balanceOf(address) },
          { result: transmuterContract.methods.userInfo(address) },
          { result: alchemistYearnContract.methods.getVaultTotalDeposited(1) },
          { result: alchemistIdleContract.methods.getVaultTotalDeposited(0) },
          { result: alchemistPickleContract.methods.getVaultTotalDeposited(0) },
        ];

        multicall
          .all([calls])
          .then(([results]) => {
            const transmuterDaiBalance = new BigNumber(results[0].result);
            const transmuterAlUsdBalance = new BigNumber(results[1].result);
            const alUsdBalance = new BigNumber(results[2].result);
            const daiBalance = new BigNumber(results[3].result);
            const userInfo = results[4];

            let stakedAlUsdBalance = new BigNumber(0);
            let tokensInBucket = new BigNumber(0);
            let pendingdivs = new BigNumber(0);

            if (userInfo.result) {
              stakedAlUsdBalance = new BigNumber(userInfo.result[0]);
              tokensInBucket = new BigNumber(userInfo.result[2]);
              pendingdivs = new BigNumber(userInfo.result[1]);
            }

            const daiDepositedInYvDaiVault = new BigNumber(results[5].result);
            const daiDepositedInIdleDaiVault = new BigNumber(results[6].result);
            const daiDepositedInPickleDaiVault = new BigNumber(results[7].result);

            const output = {
              transmuterDaiBalance: convertAmountFromRawNumber(transmuterDaiBalance, decimals),
              transmuterAlUsdBalance: convertAmountFromRawNumber(transmuterAlUsdBalance, decimals),
              alUsdBalance: convertAmountFromRawNumber(alUsdBalance, decimals),
              daiBalance: convertAmountFromRawNumber(daiBalance, decimals),
              stakedAlUsdBalance: convertAmountFromRawNumber(stakedAlUsdBalance, decimals),
              transmutableDaiBalance: convertAmountFromRawNumber(
                tokensInBucket.plus(pendingdivs),
                decimals
              ),
              daiDepositedInYvDaiVault: convertAmountFromRawNumber(
                daiDepositedInYvDaiVault,
                decimals
              ),
              daiDepositedInIdleDaiVault: convertAmountFromRawNumber(
                daiDepositedInIdleDaiVault,
                decimals
              ),
              daiDepositedInPickleDaiVault: convertAmountFromRawNumber(
                daiDepositedInPickleDaiVault,
                decimals
              ),
            };

            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_SUCCESS,
              data: output,
              tokenId: tokenId,
            });
            resolve();
          })
          .catch(error => {
            dispatch({
              type: TRANSMUTER_DETAIL_FETCH_FAILURE,
            });
            return reject(error.message || error);
          });
      }
    });

    return promise;
  };
}

export function useFetchTransmuterDetail() {
  const dispatch = useDispatch();

  const { details, fetchTransmuterDetailPending, fetchTransmuterDetailDone } = useSelector(
    state => ({
      details: state.transmuter.details,
      fetchTransmuterDetailDone: state.transmuter.fetchTransmuterDetailDone,
      fetchTransmuterDetailPending: state.transmuter.fetchTransmuterDetailPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchTransmuterDetail(data));
    },
    [dispatch]
  );

  const boundAction1 = useCallback(() => {
    return dispatch(fetchAllTransmuterDetail());
  }, [dispatch]);

  return {
    details,
    fetchTransmuterDetail: boundAction,
    fetchAllTransmuterDetail: boundAction1,
    fetchTransmuterDetailDone,
    fetchTransmuterDetailPending,
  };
}

export function reducer(state, action) {
  let { details } = state;
  switch (action.type) {
    case TRANSMUTER_DETAIL_FETCH_BEGIN:
      return {
        ...state,
        fetchTransmuterDetailPending: true,
      };

    case TRANSMUTER_DETAIL_FETCH_SUCCESS:
      details[action.tokenId] = action.data;
      return {
        ...state,
        details,
        fetchTransmuterDetailDone: true,
        fetchTransmuterDetailPending: false,
      };

    case TRANSMUTER_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        fetchTransmuterDetailPending: false,
      };

    default:
      return state;
  }
}
