

export const fee = [
  {
    level: 0,
    range: [0, 50],
    strategyWithdrawFee: 0.002,
    minterFee: 0.002,
    farmWithdrawFee: 0.002,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 1,
    range: [50, 200],
    strategyWithdrawFee: 0.0018,
    minterFee: 0.0018,
    farmWithdrawFee: 0.0018,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 2,
    range: [200, 500],
    strategyWithdrawFee: 0.0016,
    minterFee: 0.0016,
    farmWithdrawFee: 0.0016,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 3,
    range: [500, 1000],
    strategyWithdrawFee: 0.0014,
    minterFee: 0.0014,
    farmWithdrawFee: 0.0014,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 4,
    range: [1000, 2000],
    strategyWithdrawFee: 0.0013,
    minterFee: 0.0013,
    farmWithdrawFee: 0.0013,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 5,
    range: [2000, 3500],
    strategyWithdrawFee: 0.001,
    minterFee: 0.001,
    farmWithdrawFee: 0.001,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 6,
    range: [3500, 6000],
    strategyWithdrawFee: 0.0007,
    minterFee: 0.0007,
    farmWithdrawFee: 0.0007,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 7,
    range: [6000, 9000],
    strategyWithdrawFee: 0.0005,
    minterFee: 0.0005,
    farmWithdrawFee: 0.0005,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 8,
    range: [9000, 11000],
    strategyWithdrawFee: 0.0003,
    minterFee: 0.0003,
    farmWithdrawFee: 0.0003,
    farmEarlyWithdrawFee: 0.01,
  },
  {
    level: 9,
    range: [11000, 1e18],
    strategyWithdrawFee: 0.0002,
    minterFee: 0.0002,
    farmWithdrawFee: 0.0002,
    farmEarlyWithdrawFee: 0.01,
  },

];
