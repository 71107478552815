import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_AIRDROPS_BEGIN,
  FETCH_AIRDROPS_SUCCESS,
  FETCH_AIRDROPS_FAILURE,
} from './constants';
import { apiUrl } from "features/configure"
export function fetchAirdrops({ address }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_AIRDROPS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      axios.get(`${apiUrl}/airdrops?address=` + address).then(
        response => {
          dispatch({
            type: FETCH_AIRDROPS_SUCCESS,
            data: response.data,
          });
          resolve(response);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_AIRDROPS_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useFetchAirdrops() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { airdropsData, airdropsPending } = useSelector(
    state => ({
      airdropsData: state.dashboard.airdropsData,
      airdropsPending: state.dashboard.airdropsPending,
    })
  );

  const boundAction = useCallback(
    (data) => dispatch(fetchAirdrops(data)),
    [dispatch],
  );

  return {
    airdropsData,
    fetchAirdrops: boundAction,
    airdropsPending
  };
}

export function reducer(state, action) {
  let { airdropsPending, airdropsData } = state;
  switch (action.type) {
    case FETCH_AIRDROPS_BEGIN:
      airdropsPending = true;
      return {
        ...state,
        airdropsPending,
      };

    case FETCH_AIRDROPS_SUCCESS:

      return {
        ...state,
        airdropsData: action.data,
        airdropsPending: false
      };

    case FETCH_AIRDROPS_FAILURE:
      // The request is failed
      airdropsPending = false;

      return {
        ...state,
        airdropsData: undefined,
        airdropsPending
      };

    default:
      return state;
  }
}
