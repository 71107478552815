import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VAULT_FETCH_CURRENT_TROVE_BEGIN,
  VAULT_FETCH_CURRENT_TROVE_SUCCESS,
  VAULT_FETCH_CURRENT_TROVE_FAILURE,
} from './constants';
import { getTroveStatus } from '../../web3';

export function fetchCurrentTrove({ web3, address}) {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_CURRENT_TROVE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {
      getTroveStatus({ web3, address})
        .then(data => {
          dispatch({
            type: VAULT_FETCH_CURRENT_TROVE_SUCCESS,
            data
          });
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: VAULT_FETCH_CURRENT_TROVE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchCurrentTrove() {
  const dispatch = useDispatch();

  const { fetchCurrentTrovePending, currentTrove } = useSelector(state => ({
    fetchCurrentTrovePending: state.vault.fetchCurrentTrovePending,
    currentTrove: state.vault.currentTrove
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchCurrentTrove(data));
    },
    [dispatch]
  );

  return {
    fetchCurrentTrove: boundAction,
    fetchCurrentTrovePending,
    currentTrove
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_CURRENT_TROVE_BEGIN:
      return {
        ...state,
        fetchCurrentTrovePending: true
      };

    case VAULT_FETCH_CURRENT_TROVE_SUCCESS:
      return {
        ...state,
        currentTrove:action.data,
        fetchCurrentTrovePending: false
      };

    case VAULT_FETCH_CURRENT_TROVE_FAILURE:
      return {
        ...state,
        fetchCurrentTrovePending:false
      };

    default:
      return state;
  }
}
