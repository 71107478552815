import { rewardVestingABI } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const withdrawEarning = async ({ web3, address, contractAddress, amount, dispatch }) => {
  const contract = new web3.eth.Contract(rewardVestingABI, contractAddress);
  const data = await _withdrawEarning({ web3, contract, address, amount, dispatch });
  return data;
};

export const _withdrawEarning = async ({ web3, contract, address, amount, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .withdrawEarning(amount)
      .send({ from: address })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
