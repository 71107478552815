import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from './constants';
import { pools } from '../../configure/pools';
import { apiUrl } from "features/configure"
export function fetchDashboard() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DASHBOARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      axios.get(`${apiUrl}/stats/dashboard`).then(
        response => {
          dispatch({
            type: FETCH_DASHBOARD_SUCCESS,
            data: response.data.crossChain,
          });
          resolve(response);
        },
      ).catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        error => {
          dispatch({
            type: FETCH_DASHBOARD_FAILURE,
          });
          reject(error.message || error);
        }
      )
    });
    return promise;
  }
}

export function useDashboard() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();
  const { dashboardData, dashboardPending } = useSelector(
    state => ({
      dashboardData: state.dashboard.dashboardData,
      dashboardPending: state.dashboard.dashboardPending,
    })
  );

  const boundAction = useCallback(
    () => dispatch(fetchDashboard()),
    [dispatch],
  );

  return {
    dashboardData,
    fetchDashboard: boundAction,
    dashboardPending
  };
}

export function reducer(state, action) {
  let { dashboardPending, dashboardData } = state;
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      dashboardPending = true;
      return {
        ...state,
        dashboardPending,
      };

    case FETCH_DASHBOARD_SUCCESS:

      return {
        ...state,
        dashboardData: action.data,
        dashboardPending: false
      };

    case FETCH_DASHBOARD_FAILURE:
      // The request is failed
      dashboardPending = false;

      return {
        ...state,
        dashboardData: undefined,
        dashboardPending
      };

    default:
      return state;
  }
}
