import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles(theme => ({
  selectedToggle: {
    padding: '5px 10px',
    minWidth: 100,
    borderRadius: 12,
    '& .MuiToggleButton-label': {
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.text.green,
      '& .MuiToggleButton-label': {
        color: 'black',
      },
      '&:hover': {
        backgroundColor: theme.palette.text.green,
        '& .MuiButton-label': {
          color: theme.palette.text.green,
        },
      },
    },
  },
}));

export default function CustomToggle({ value, onChange, data }) {
  const classes = useStyles();

  return (
    <ToggleButtonGroup value={value} exclusive onChange={onChange} style={{ textAlign: 'right' }}>
      {data.map((button, index) => (
        <ToggleButton key={index} value={button.value} classes={{ root: classes.selectedToggle }}>
          {button.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

CustomToggle.defaultProps = {};
