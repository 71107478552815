import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { POOLS_FETCH_BEGIN, POOLS_FETCH_SUCCESS, POOLS_FETCH_FAILURE } from './constants';
import { MultiCall } from 'eth-multicall';
import { erc20ABI, stakingPoolsV4ABI, tokens, contracts } from '../../configure';
import BigNumber from 'bignumber.js';
import { convertAmountFromRawNumber } from '../../helpers/bignumber';

const BLOCKS_PER_YEAR = new BigNumber(2368850);
const WASABI_PER_BLOCK = new BigNumber(850000000000000000);
const WITHDRAW_LOCK = 86400 * 3;
const poolsWeighting = {
  0: 0.0353,
  1: 0.8235,
  2: 0.0117,
  3: 0.0353,
  4: 0.0353,
  5: 0.0353,
  6: 0.0118,
  7: 0.0118,
};

export function fetchPools() {
  return (dispatch, getState) => {
    dispatch({
      type: POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home, price } = getState();
      const { address, web3 } = home;
      const { wasabiPrice, wethPrice, wbtcPrice, reserve0, lpTotalSupply, lpData, linkPrice } =
        price.priceData;
      // let wasabiPrice = new BigNumber(price.priceData.wasabiPrice);
      // let wethPrice = new BigNumber(price.priceData.ethPrice);
      // let wbtcPrice = new BigNumber(price.priceData.wbtcPrice);
      // let reserve0 = new BigNumber(price.priceData.reserve0);

      const multicall = new MultiCall(web3, contracts.multicall.address);

      const stakingPoolsContract = new web3.eth.Contract(
        stakingPoolsV4ABI,
        contracts.stakingPools.address
      );

      const wasabiContract = new web3.eth.Contract(erc20ABI, tokens.wasabi.address);
      const waUSDContract = new web3.eth.Contract(erc20ABI, tokens.wausd.address);
      const linkContract = new web3.eth.Contract(erc20ABI, tokens.link.address);
      const wvvtContract = new web3.eth.Contract(erc20ABI, tokens.wvvt.address);
      const walinkContract = new web3.eth.Contract(erc20ABI, tokens.walink.address);
      const wabtcContract = new web3.eth.Contract(erc20ABI, tokens.wabtc.address);
      const walusdContract = new web3.eth.Contract(erc20ABI, tokens.walusd.address);
      const waethContract = new web3.eth.Contract(erc20ABI, tokens.waeth.address);

      const wasabiwethLpContract = new web3.eth.Contract(erc20ABI, contracts.wasabiWETHLp.address);
      const waUSD3CRVContract = new web3.eth.Contract(erc20ABI, contracts.waUSD3CRV.address);
      const linkwalinkLpContract = new web3.eth.Contract(erc20ABI, contracts.linkwalinkLp.address);

      const wethContract = new web3.eth.Contract(erc20ABI, tokens.weth.address);

      //0 wausd
      //1 slp
      //3 3crv
      //2 wabtc
      //4 walusd
      //5 waeth
      const calls = [
        { result: stakingPoolsContract.methods.poolInfo(0) }, //0
        { result: stakingPoolsContract.methods.poolInfo(1) }, //1
        { result: stakingPoolsContract.methods.poolInfo(2) }, //2
        { result: stakingPoolsContract.methods.poolInfo(3) }, //3
        { result: stakingPoolsContract.methods.poolInfo(4) }, //4
        { result: stakingPoolsContract.methods.poolInfo(5) }, //5

        { result: stakingPoolsContract.methods.getUserInfo(address, 0) }, //6 userDeposited pool0
        { result: stakingPoolsContract.methods.getUserInfo(address, 1) }, //7 userDeposited pool1
        { result: stakingPoolsContract.methods.getUserInfo(address, 2) }, //8 userDeposited pool2
        { result: stakingPoolsContract.methods.getUserInfo(address, 3) }, //9 userDeposited pool3
        { result: stakingPoolsContract.methods.getUserInfo(address, 4) }, //10 userDeposited pool4
        { result: stakingPoolsContract.methods.getUserInfo(address, 5) }, //11 userDeposited pool5

        { result: waUSDContract.methods.balanceOf(address) }, //12 balance of staking target pool0
        { result: wasabiwethLpContract.methods.balanceOf(address) }, //13 balance of staking target pool1
        { result: waUSD3CRVContract.methods.balanceOf(address) }, //14 balance of staking target pool2
        { result: wabtcContract.methods.balanceOf(address) }, //15 balance of staking target pool3
        { result: walusdContract.methods.balanceOf(address) }, //16 balance of staking target pool4
        { result: waethContract.methods.balanceOf(address) }, //17 balance of staking target pool5

        { result: stakingPoolsContract.methods.pendingReward(0, address) }, //18 getStakeTotalUnclaimed pool0
        { result: stakingPoolsContract.methods.pendingReward(1, address) }, //19 getStakeTotalUnclaimed pool1
        { result: stakingPoolsContract.methods.pendingReward(2, address) }, //20 getStakeTotalUnclaimed pool2
        { result: stakingPoolsContract.methods.pendingReward(3, address) }, //21 getStakeTotalUnclaimed pool3
        { result: stakingPoolsContract.methods.pendingReward(4, address) }, //22 getStakeTotalUnclaimed pool4
        { result: stakingPoolsContract.methods.pendingReward(5, address) }, //23 getStakeTotalUnclaimed pool5

        { result: wethContract.methods.balanceOf(contracts.wasabiWETHLp.address) }, //24 get wasabiWETHLp weth balance
        { result: wasabiwethLpContract.methods.totalSupply() }, //25 get wasabiWETHLp total supply

        { result: stakingPoolsContract.methods.getDepositedAt(address, 0) }, //26 getDepositedAt pool0
        { result: stakingPoolsContract.methods.getDepositedAt(address, 1) }, //27 getDepositedAt pool1
        { result: stakingPoolsContract.methods.getDepositedAt(address, 2) }, //28 getDepositedAt pool2
        { result: stakingPoolsContract.methods.getDepositedAt(address, 3) }, //29 getDepositedAt pool0
        { result: stakingPoolsContract.methods.getDepositedAt(address, 4) }, //30 getDepositedAt pool1
        { result: stakingPoolsContract.methods.getDepositedAt(address, 5) }, //31 getDepositedAt pool2

        { result: stakingPoolsContract.methods.poolInfo(6) }, //32
        { result: stakingPoolsContract.methods.getUserInfo(address, 6) }, //33 userDeposited pool6
        { result: walinkContract.methods.balanceOf(address) }, //34 balance of staking target pool6
        { result: stakingPoolsContract.methods.pendingReward(6, address) }, //35 getStakeTotalUnclaimed pool6
        { result: stakingPoolsContract.methods.getDepositedAt(address, 6) }, //36 getDepositedAt pool6

        { result: stakingPoolsContract.methods.poolInfo(7) }, //37
        { result: stakingPoolsContract.methods.getUserInfo(address, 7) }, //38 userDeposited pool7
        { result: linkwalinkLpContract.methods.balanceOf(address) }, //39 balance of staking target pool7
        { result: stakingPoolsContract.methods.pendingReward(7, address) }, //40 getStakeTotalUnclaimed pool7
        { result: stakingPoolsContract.methods.getDepositedAt(address, 7) }, //41 getDepositedAt pool7

        { result: linkContract.methods.balanceOf(contracts.linkwalinkLp.address) }, //42 get linkwalinkLpContract link balance
        { result: linkwalinkLpContract.methods.totalSupply() }, //43 get linkwalinkLpContract total supply
      ];

      multicall
        .all([calls])
        .then(([results]) => {
          const pool0Staking = new BigNumber(results[0].result[5]);
          const pool1Staking = new BigNumber(results[1].result[5]);
          const pool2Staking = new BigNumber(results[2].result[5]);
          const pool3Staking = new BigNumber(results[3].result[5]);
          const pool4Staking = new BigNumber(results[4].result[5]);
          const pool5Staking = new BigNumber(results[5].result[5]);
          const pool6Staking = new BigNumber(results[32].result[5]);
          const pool7Staking = new BigNumber(results[37].result[5]);

          const pool0WorkingSupply = new BigNumber(results[0].result[4]);
          const pool1WorkingSupply = new BigNumber(results[1].result[4]);
          const pool2WorkingSupply = new BigNumber(results[2].result[4]);
          const pool3WorkingSupply = new BigNumber(results[3].result[4]);
          const pool4WorkingSupply = new BigNumber(results[4].result[4]);
          const pool5WorkingSupply = new BigNumber(results[5].result[4]);
          const pool6WorkingSupply = new BigNumber(results[32].result[4]);
          const pool7WorkingSupply = new BigNumber(results[37].result[4]);

          const userPool0Staking = new BigNumber(results[6].result[0]);
          const userPool1Staking = new BigNumber(results[7].result[0]);
          const userPool2Staking = new BigNumber(results[8].result[0]);
          const userPool3Staking = new BigNumber(results[9].result[0]);
          const userPool4Staking = new BigNumber(results[10].result[0]);
          const userPool5Staking = new BigNumber(results[11].result[0]);
          const userPool6Staking = new BigNumber(results[33].result[0]);
          const userPool7Staking = new BigNumber(results[38].result[0]);

          const userPool0WorkingAmount = new BigNumber(results[6].result[1]);
          const userPool1WorkingAmount = new BigNumber(results[7].result[1]);
          const userPool2WorkingAmount = new BigNumber(results[8].result[1]);
          const userPool3WorkingAmount = new BigNumber(results[9].result[1]);
          const userPool4WorkingAmount = new BigNumber(results[10].result[1]);
          const userPool5WorkingAmount = new BigNumber(results[11].result[1]);
          const userPool6WorkingAmount = new BigNumber(results[33].result[1]);
          const userPool7WorkingAmount = new BigNumber(results[38].result[1]);

          const userPool0Boost = userPool0WorkingAmount.div(userPool0Staking * 0.4);
          const userPool1Boost = userPool1WorkingAmount.div(userPool1Staking * 0.4);
          const userPool2Boost = userPool2WorkingAmount.div(userPool2Staking * 0.4);
          const userPool3Boost = userPool3WorkingAmount.div(userPool3Staking * 0.4);
          const userPool4Boost = userPool4WorkingAmount.div(userPool4Staking * 0.4);
          const userPool5Boost = userPool5WorkingAmount.div(userPool5Staking * 0.4);
          const userPool6Boost = userPool6WorkingAmount.div(userPool6Staking * 0.4);
          const userPool7Boost = userPool7WorkingAmount.div(userPool7Staking * 0.4);

          const userPool0Balance = new BigNumber(results[12].result);
          const userPool1Balance = new BigNumber(results[13].result);
          const userPool2Balance = new BigNumber(results[14].result);
          const userPool3Balance = new BigNumber(results[15].result);
          const userPool4Balance = new BigNumber(results[16].result);
          const userPool5Balance = new BigNumber(results[17].result);
          const userPool6Balance = new BigNumber(results[34].result);
          const userPool7Balance = new BigNumber(results[39].result);

          const userPool0Unclaimed = new BigNumber(results[18].result);
          const userPool1Unclaimed = new BigNumber(results[19].result);
          const userPool2Unclaimed = new BigNumber(results[20].result);
          const userPool3Unclaimed = new BigNumber(results[21].result);
          const userPool4Unclaimed = new BigNumber(results[22].result);
          const userPool5Unclaimed = new BigNumber(results[23].result);
          const userPool6Unclaimed = new BigNumber(results[35].result);
          const userPool7Unclaimed = new BigNumber(results[40].result);

          const wethBalance = new BigNumber(results[24].result);
          const lpTotalSupply = new BigNumber(results[25].result);
          const linkBalance = new BigNumber(results[42].result);
          const linklpTotalSupply = new BigNumber(results[43].result);

          const userPool0Unlock = parseInt(results[26].result) + WITHDRAW_LOCK;
          const userPool1Unlock = parseInt(results[27].result) + WITHDRAW_LOCK;
          const userPool2Unlock = parseInt(results[28].result) + WITHDRAW_LOCK;
          const userPool3Unlock = parseInt(results[29].result) + WITHDRAW_LOCK;
          const userPool4Unlock = parseInt(results[30].result) + WITHDRAW_LOCK;
          const userPool5Unlock = parseInt(results[31].result) + WITHDRAW_LOCK;
          const userPool6Unlock = parseInt(results[36].result) + WITHDRAW_LOCK;
          const userPool7Unlock = parseInt(results[41].result) + WITHDRAW_LOCK;

          const pool0APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[0]))
            .times(wasabiPrice)
            .div(pool0Staking);
          const pool1APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[1]))
            .times(wasabiPrice)
            .div(wethBalance.times(pool1Staking).div(lpTotalSupply).times(2).times(wethPrice));
          const pool2APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[2]))
            .times(wasabiPrice)
            .div(pool2Staking);
          const pool3APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[3]))
            .times(wasabiPrice)
            .div(pool3Staking.times(wbtcPrice).times(1e10));
          const pool4APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[4]))
            .times(wasabiPrice)
            .div(pool4Staking);
          const pool5APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[5]))
            .times(wasabiPrice)
            .div(pool5Staking.times(wethPrice));
          const pool6APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[6]))
            .times(wasabiPrice)
            .div(pool6Staking.times(linkPrice));
          const pool7APY = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[7]))
            .times(wasabiPrice)
            .div(linkBalance.times(pool7Staking).div(linklpTotalSupply).times(2).times(linkPrice));

          const pool0DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[0]))
            .times(wasabiPrice)
            .times(userPool0WorkingAmount)
            .div(pool0WorkingSupply)
            .div(365);
          const pool1DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[1]))
            .times(wasabiPrice)
            .times(userPool1WorkingAmount)
            .div(pool1WorkingSupply)
            .div(365);
          const pool2DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[2]))
            .times(wasabiPrice)
            .times(userPool2WorkingAmount)
            .div(pool2WorkingSupply)
            .div(365);
          const pool3DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[3]))
            .times(wasabiPrice)
            .times(userPool3WorkingAmount)
            .div(pool3WorkingSupply)
            .div(365);
          const pool4DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[4]))
            .times(wasabiPrice)
            .times(userPool4WorkingAmount)
            .div(pool4WorkingSupply)
            .div(365);
          const pool5DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[5]))
            .times(wasabiPrice)
            .times(userPool5WorkingAmount)
            .div(pool5WorkingSupply)
            .div(365);
          const pool6DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[6]))
            .times(wasabiPrice)
            .times(userPool6WorkingAmount)
            .div(pool6WorkingSupply)
            .div(365);
          const pool7DailyEarn = WASABI_PER_BLOCK.times(BLOCKS_PER_YEAR)
            .times(new BigNumber(poolsWeighting[7]))
            .times(wasabiPrice)
            .times(userPool7WorkingAmount)
            .div(pool7WorkingSupply)
            .div(365);

          const pool0ShareOfPool = userPool0WorkingAmount.div(pool0WorkingSupply);
          const pool1ShareOfPool = userPool1WorkingAmount.div(pool1WorkingSupply);
          const pool2ShareOfPool = userPool2WorkingAmount.div(pool2WorkingSupply);
          const pool3ShareOfPool = userPool3WorkingAmount.div(pool3WorkingSupply);
          const pool4ShareOfPool = userPool4WorkingAmount.div(pool4WorkingSupply);
          const pool5ShareOfPool = userPool5WorkingAmount.div(pool5WorkingSupply);
          const pool6ShareOfPool = userPool6WorkingAmount.div(pool6WorkingSupply);
          const pool7ShareOfPool = userPool7WorkingAmount.div(pool7WorkingSupply);

          const lpPrice = lpData ? (reserve0 * wasabiPrice * 2 * 1e18) / lpTotalSupply : 0;

          const output = {
            0: {
              apy: pool0APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool0Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool0Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool0Balance),
              totalStaked: convertAmountFromRawNumber(pool0Staking),
              yourStaked: convertAmountFromRawNumber(userPool0Staking),
              dailyEarn: convertAmountFromRawNumber(pool0DailyEarn),
              tokenPrice: 1,
              boost: isFinite(userPool0Boost) ? userPool0Boost : 1,
              earlyWithdrawUnlockDate: userPool0Unlock,
              shareOfPool: pool0ShareOfPool,
            },
            1: {
              apy: pool1APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool1Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool1Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool1Balance),
              totalStaked: convertAmountFromRawNumber(pool1Staking),
              yourStaked: convertAmountFromRawNumber(userPool1Staking),
              dailyEarn: convertAmountFromRawNumber(pool1DailyEarn),
              tokenPrice: lpPrice,
              boost: isFinite(userPool1Boost) ? userPool1Boost : 1,
              earlyWithdrawUnlockDate: userPool1Unlock,
              shareOfPool: pool1ShareOfPool,
            },
            2: {
              apy: pool2APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool2Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool2Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool2Balance),
              totalStaked: convertAmountFromRawNumber(pool2Staking),
              yourStaked: convertAmountFromRawNumber(userPool2Staking),
              dailyEarn: convertAmountFromRawNumber(pool2DailyEarn),
              tokenPrice: 1,
              boost: isFinite(userPool2Boost) ? userPool2Boost : 1,
              earlyWithdrawUnlockDate: userPool2Unlock,
              shareOfPool: pool2ShareOfPool,
            },
            3: {
              apy: pool3APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool3Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool3Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool3Balance, 8),
              totalStaked: convertAmountFromRawNumber(pool3Staking, 8),
              yourStaked: convertAmountFromRawNumber(userPool3Staking, 8),
              dailyEarn: convertAmountFromRawNumber(pool3DailyEarn),
              tokenPrice: wbtcPrice,
              boost: isFinite(userPool3Boost) ? userPool3Boost : 1,
              earlyWithdrawUnlockDate: userPool3Unlock,
              shareOfPool: pool3ShareOfPool,
            },
            4: {
              apy: pool4APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool4Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool4Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool4Balance),
              totalStaked: convertAmountFromRawNumber(pool4Staking),
              yourStaked: convertAmountFromRawNumber(userPool4Staking),
              dailyEarn: convertAmountFromRawNumber(pool4DailyEarn),
              tokenPrice: 1,
              boost: isFinite(userPool4Boost) ? userPool4Boost : 1,
              earlyWithdrawUnlockDate: userPool4Unlock,
              shareOfPool: pool4ShareOfPool,
            },
            5: {
              apy: pool5APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool5Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool5Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool5Balance),
              totalStaked: convertAmountFromRawNumber(pool5Staking),
              yourStaked: convertAmountFromRawNumber(userPool5Staking),
              dailyEarn: convertAmountFromRawNumber(pool5DailyEarn),
              tokenPrice: wethPrice,
              boost: isFinite(userPool5Boost) ? userPool5Boost : 1,
              earlyWithdrawUnlockDate: userPool5Unlock,
              shareOfPool: pool5ShareOfPool,
            },
            6: {
              apy: pool6APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool6Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool6Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool6Balance),
              totalStaked: convertAmountFromRawNumber(pool6Staking),
              yourStaked: convertAmountFromRawNumber(userPool6Staking),
              dailyEarn: convertAmountFromRawNumber(pool6DailyEarn),
              tokenPrice: linkPrice,
              boost: isFinite(userPool6Boost) ? userPool6Boost : 1,
              earlyWithdrawUnlockDate: userPool6Unlock,
              shareOfPool: pool6ShareOfPool,
            },
            7: {
              apy: pool7APY.toString(),
              unclaimed: convertAmountFromRawNumber(userPool7Unclaimed),
              unclaimedValue: convertAmountFromRawNumber(userPool7Unclaimed * wasabiPrice),
              balance: convertAmountFromRawNumber(userPool7Balance),
              totalStaked: convertAmountFromRawNumber(pool7Staking),
              yourStaked: convertAmountFromRawNumber(userPool7Staking),
              dailyEarn: convertAmountFromRawNumber(pool7DailyEarn),
              tokenPrice: linkPrice * 2,
              boost: isFinite(userPool7Boost) ? userPool7Boost : 1,
              earlyWithdrawUnlockDate: userPool7Unlock,
              shareOfPool: pool7ShareOfPool,
            },
          };
          dispatch({
            type: POOLS_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch(error => {
          dispatch({
            type: POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPools() {
  const dispatch = useDispatch();

  const { context, fetchPoolsPending, fetchPoolsDone } = useSelector(
    state => ({
      context: state.pools.context,
      fetchPoolsPending: state.pools.fetchPoolsPending,
      fetchPoolsDone: state.pools.fetchPoolsDone,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPools(data));
    },
    [dispatch]
  );

  return {
    context,
    fetchPools: boundAction,
    fetchPoolsDone,
    fetchPoolsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolsPending: true,
      };

    case POOLS_FETCH_SUCCESS:
      return {
        ...state,
        context: action.data,
        fetchPoolsDone: true,
        fetchPoolsPending: false,
      };

    case POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolsPending: false,
      };

    default:
      return state;
  }
}
