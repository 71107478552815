import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    borderRadius: 20,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  tabs: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,

  },
  tab: {
    padding: 15,
    fontSize: "1.1rem",
    textTransform: 'capitalize',
    border: `0.5px solid ${theme.palette.text.primary}`,
    '& .MuiTab-wrapper': {
      fontWeight: 600,
      color: theme.palette.text.primary,
    },

  },
  card: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    borderRadius: 5,
    marginTop: 30
  },
}));

export default function CustomTabs({ tabs, initIndex = 0, tabIndex, setTabIndex }) {
  const [value, setValue] = useState(parseInt(initIndex));
  const handleChange = (event, newValue) => {
    setTabIndex ? setTabIndex(newValue) : setValue(newValue);
  };
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <AppBar position="static" className={classes.tabs}>
        <Tabs value={typeof tabIndex === 'number' ? tabIndex : value} onChange={handleChange}
          indicatorColor="primary" variant="fullWidth">
          {tabs.map((tab, index) => <Tab key={tab.label} className={classes.tab} label={tab.label} {...a11yProps(index)} />)}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => {
        return (<TabPanel key={index} value={typeof tabIndex === 'number' ? tabIndex : value} index={index}>
          {tab.content}
        </TabPanel>);
      })}

    </div>
  );
}
